import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { FaXTwitter, FaLinkedin, FaSquareInstagram, FaChalkboardUser } from "react-icons/fa6";
import { FaFacebookSquare, FaCalendarAlt } from "react-icons/fa";
import { MdOutlineContactPhone } from "react-icons/md";
import { BiCategory, BiSolidBookReader } from "react-icons/bi";
import { MdSegment } from "react-icons/md";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import {
  DateRangePicker, createStaticRanges, defaultStaticRanges
} from 'react-date-range';
import { Alert, Badge, Button, Modal, ModalBody, ModalHeader, } from 'reactstrap'
const Dashboard = () => {
  const dispatch = useDispatch();
  const [modalUserType, setModalUserType] = useState(false);
  const [withDateUserType, setWithDateUserType] = useState(false);
  const [rangesUserType, setRangesUserType] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "rollup"
    }
  ]);
  console.log(moment(rangesUserType?.[0]?.endDate).format("DD-MM-YYYY"), "rangesUserType");

  const staticRanges = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: "This Year",
      range: () => ({
        startDate: moment()
          .startOf("year")
          .toDate(),
        endDate: moment()
          .endOf("day")
          .toDate()
      })
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: moment()
          .subtract(1, "years")
          .startOf("year")
          .toDate(),
        endDate: moment()
          .subtract(1, "years")
          .endOf("year")
          .toDate()
      })
    }
  ]);
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const {
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getDashboardCount(auth?.token, withDateUserType ? moment(rangesUserType?.[0]?.startDate).format("YYYY-MM-DDTHH:mm:ss.sss") : "", withDateUserType ? moment(rangesUserType?.[0]?.endDate).format("YYYY-MM-DDTHH:mm:ss.sss") : ""));
  }, [auth.token, withDateUserType, rangesUserType]);
  const { dashboardCount } = useSelector((state) => state.dashboardReducer);

  const getSum = (arr) => {
    return arr?.reduce((prev, curr) => (prev += parseInt(curr.amount)), 0);
  };

  console.log(dashboardCount);

  return (
    <Wrapper>
      <div className="py-4">
        <div className="d-flex justify-content-between">
          <div class="page-header pb-3">
            <h4 class="page-title">Dashboard</h4>
          </div>
          <h5 className="text-white">
            Select date : <span onClick={() => (setModalUserType(!modalUserType), setWithDateUserType(true))} className='ds-table-card-header-icon'>
              <FaCalendarAlt color="white" size={25} className="cursor-pointer" />
            </span>
          </h5>
        </div>


        <div class="row pb-3">
          <div className="col-md-9">
            <div className="row">
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100 ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black ">
                          <b>Active Consultant</b>
                          {/* {dashboardCount?.consultantCount} */}
                        </h5>

                        <h5 class="text-info ">
                          {dashboardCount?.consultantCount?.rows?.length}
                        </h5>
                      </div>
                      <div className="d-flex justify-content-end">
                        <MdOutlineContactPhone color="green" size={30} />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black ">
                          <b>Category</b>
                          {/* {dashboardCount?.consultantCount} */}
                        </h5>

                        <h5 class="text-info ">
                          2
                        </h5>
                      </div>
                      <div className="d-flex justify-content-end">
                        <BiCategory color="green" size={30} />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100 ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black ">
                          <b>Segment</b>
                          {/* {dashboardCount?.consultantCount} */}
                        </h5>

                        <h5 class="text-info ">
                          {dashboardCount?.segmentcount
                          }
                        </h5>
                      </div>
                      <div className="d-flex justify-content-end">
                        <MdSegment color="green" size={30} />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100 ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black ">
                          <b>Expertise</b>
                          {/* {dashboardCount?.consultantCount} */}
                        </h5>

                        <h5 class="text-info ">
                          {dashboardCount?.expertisecount
                          }
                        </h5>
                      </div>
                      <div className="d-flex justify-content-end">
                        <MdOutlineContactPhone color="green" size={30} />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100 ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black ">
                          <b>Courses</b>
                          {/* {dashboardCount?.consultantCount} */}
                        </h5>

                        <h5 class="text-info ">
                          {dashboardCount?.coursecount}
                        </h5>
                      </div>
                      <div className="d-flex justify-content-end">
                        <BiSolidBookReader color="green" size={30} />
                      </div>
                    </div>
                    {/* <div class="progress progress-sm">
                      <div
                        class="progress-bar bg-info w-75"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <p class="text-muted mb-0 py-3 fs-5">Monthly</p>
                      <p class="text-muted mb-0 py-3 fs-5">75%</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100 ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black ">
                          <b>Expert Videos</b>
                          {/* {dashboardCount?.consultantCount} */}
                        </h5>

                        <h5 class="text-info ">
                          {dashboardCount?.expertvideoscount
                          }
                        </h5>
                      </div>
                      <div className="d-flex justify-content-end">
                        <FaChalkboardUser color="green" size={30} />
                      </div>
                    </div>
                    {/* <div class="progress progress-sm">
                      <div
                        class="progress-bar bg-info w-75"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <p class="text-muted mb-0 py-3 fs-5">Monthly</p>
                      <p class="text-muted mb-0 py-3 fs-5">75%</p>
                    </div> */}
                  </div>
                </div>
              </div>

              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black pb-2">
                          <b>Audio Consultancy </b>
                        </h5>
                      </div>
                      <h3 class="text-danger fw-bold">
                        {dashboardCount?.bookedAudioAppointment}
                      </h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black pb-2">
                          <b>Video Consultancy </b>
                        </h5>
                      </div>
                      <h3 class="text-danger fw-bold">
                        {dashboardCount?.bookedVideoAppointment}
                      </h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black pb-2">
                          <b>Written Consultancy </b>
                        </h5>
                      </div>
                      <h3 class="text-danger fw-bold">
                        {dashboardCount?.writtenconsultancycount}
                      </h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black pb-2">
                          <b>Meetings</b>
                        </h5>
                      </div>
                      <h3 class="text-danger fw-bold">
                        {dashboardCount?.meetingcount}
                      </h3>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-4 col-sm-6 col-md-6 col-lg-4 mt-2">
                <div class="card">
                  <div class="card-body w-100">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5 className="text-black pb-2">
                          <b>Conference Meetings</b>
                        </h5>
                      </div>
                      <h3 class="text-danger fw-bold">{dashboardCount?.userCount}</h3>

                    </div>

                  </div>
                </div>
              </div>



            </div>

          </div>
          <div className="col-md-3">
            <div class="col-6 col-sm-12 col-md-6 col-lg-12 mt-2">
              <div class="card">
                <div class="card-body w-100">
                  <div class=" justify-content-between">
                    <div >

                      <h4 className="text-black text-center font-bold">App Installed</h4>

                      <h5 className="text-danger text-center">12123</h5>
                    </div>

                  </div>

                  <div className="row d-flex justify-content-space-between align-items-center">
                    <div className="col-md-3">
                      <h6 className="text-black"><FaXTwitter size={20} /></h6>
                    </div>

                    <div className="col-md-9 text-end">
                      <h6 className="text-black">3.40k Followers</h6>
                    </div>

                  </div>
                  <div className="border-bottom bg-gray my-2">

                  </div>
                  <div className="row d-flex justify-content-space-between align-items-center">
                    <div className="col-md-3">
                      <h6 className="text-black"><FaFacebookSquare size={22} color="blue" /></h6>
                    </div>

                    <div className="col-md-9 text-end">
                      <h6 className="text-black">28.6k Followers</h6>
                    </div>

                  </div>
                  <div className="border-bottom bg-gray my-2">

                  </div>
                  <div className="row d-flex justify-content-space-between align-items-center">
                    <div className="col-md-3">
                      <h6 className="text-black"><FaLinkedin size={24} color="blue" /></h6>
                    </div>

                    <div className="col-md-9 text-end">
                      <h6 className="text-black">11.1k Followers</h6>
                    </div>

                  </div>
                  <div className="border-bottom bg-gray my-2">

                  </div>
                  <div className="row d-flex justify-content-space-between align-items-center">
                    <div className="col-md-3">
                      <h6 className="text-black"><FaSquareInstagram size={24} color="red" /></h6>
                    </div>

                    <div className="col-md-9 text-end">
                      <h6 className="text-black">28.6k Followers</h6>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* <div class="col-12 col-sm-6 col-md-6 mt-2 mt-3">
            <div class="card">
              <div class="card-body w-100">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 className="text-black pb-2">
                      <b>Video Call Booked</b>
                    </h5>
                  </div>
                  <h3 class="text-secondary fw-bold">
                    {dashboardCount?.bookedVideoAppointment}
                  </h3>
                </div>
                <div class="progress progress-sm">
                  <div
                    class="progress-bar bg-secondary w-75"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <p class="text-muted mb-0 py-3 fs-5">Monthly</p>
                  <p class="text-muted mb-0 py-3 fs-5">80%</p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div class="col-12 col-sm-6 col-md-3 mt-3">
            <div class="card">
              <div class="card-body w-100">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 className="text-black pb-2">
                      <b>Total Revenue</b>
                    </h5>
                  </div>
                  <h3 class="text-secondary fw-bold">
                    {getSum(dashboardCount?.revenue)}
                  </h3>
                </div>
                <div class="progress progress-sm">
                  <div
                    class="progress-bar bg-secondary w-75"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <p class="text-muted mb-0 py-3 fs-5">Monthly</p>
                  <p class="text-muted mb-0 py-3 fs-5">80%</p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div class="col-12 col-sm-6 col-md-3 mt-3">
            <div class="card">
              <div class="card-body w-100">
                <div class="d-flex justify-content-between">
                  <div>
                    <h5 className="text-black pb-2">
                      <b>Total Withdrawal</b>
                    </h5>
                  </div>
                  <h3 class="text-secondary fw-bold">
                    {getSum(dashboardCount?.withdrawal)}
                  </h3>
                </div>
                <div class="progress progress-sm">
                  <div
                    class="progress-bar bg-secondary w-75"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div class="d-flex justify-content-between mt-2">
                  <p class="text-muted mb-0 py-3 fs-5">Monthly</p>
                  <p class="text-muted mb-0 py-3 fs-5">80%</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Modal isOpen={modalUserType} toggle={() => setModalUserType(!modalUserType)} size={`lg`}>
        <ModalHeader toggle={() => setModalUserType(!modalUserType)}>
          Select Range
        </ModalHeader>
        <ModalBody>
          <div>
            <DateRangePicker
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              rangeColors={['green']}
              ranges={rangesUserType}
              onChange={rangesUserType => setRangesUserType([rangesUserType.rollup])}
              staticRanges={staticRanges}
              inputRanges={[]}
            /></div>
          <Button onClick={() => setModalUserType(false)} block>Submit</Button>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
          .b,
          .strong,
          b,
          strong {
            font - weight: 600 !important;
  }
          h3 {
            font - size: 1.1625rem;
  }
          h5 {
            font - size: 1.5rem !important;
          line-height: 1.4;
  }
          .fw-bold {
            font - weight: 600 !important;
  }
          .progress.progress-sm {
            height: 8px;
  }
          .bg-success {
            background - color: #35cd3a !important;
  }
          .text-success {
            color: #35cd3a !important;
  }
          .bg-danger {
            background - color: #f3545d !important;
  }
          .text-danger {
            color: #f3545d !important;
  }
          .bg-secondary {
            background - color: #716aca !important;
  }
          .text-secondary {
            color: #716aca !important;
  }

          .card-body w-100{
            width: fit-content;
          height: 150px;
  }

          @media (min-width: 750px) and (max-width:1440px) {
            h5{
            font - size: 20px !important;
    }
  }

          @media screen and (max-width:1070px) {
            h5{
            font - size: 15px !important;
     }
          p{
            font - size: .975rem !important;
    } 
  }



          `;
