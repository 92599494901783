import React, { useEffect } from "react";
import { useState } from "react";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const EditUser = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    pincode: "",
    mobile: "",
    city: "",
    user_type: "",
    password: "",
    email: "",
    state: "",
    remark: "",
    gender: "",
    thumbnail: "",
  });

  const [errors, setErrors] = useState({});
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    const form = new FormData();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData?.email?.trim())) {
      toast.error("Please enter a valid email address.");
      return;
    }

    for (const key in formData) {
      form.append(key, formData[key]);
    }

    axios
      .put(`/api/admin/v1/update-user/${id}`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then(({ data }) => {
        toast.success(data.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setErrors(err.response.data);
      });
  };

  useEffect(() => {
    axios(`/api/admin/v1/get-user-byid/${id}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        console.log(res, "res");
        setFormData((prev) => ({
          ...prev,
          name: res?.data?.user?.name,
          email: res?.data?.user?.email,
          mobile: res?.data?.user?.mobile,
          pincode: res?.data?.user?.pincode,
          city: res?.data?.user?.city,
          state: res?.data?.user?.state,
          gender: res?.data?.user?.gender,
          user_type: res?.data?.user?.user_type,
          password: res?.data?.user?.plain_password,
          thumbnail: res?.data?.user?.thumbnail,
        }));
      })
      .catch((err) => console.log(err));
  }, [auth]);
  useEffect(() => {
    if (formData?.pincode?.length >= 6) {
      axios(`https://api.postalpincode.in/pincode/${formData?.pincode}`)
        .then((response) => {
          if (response?.data[0]?.Status === "Success") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              city: response?.data[0]?.PostOffice[0]?.Block,
              state: response?.data[0]?.PostOffice[0]?.State,
            }));
          }
        })
        .catch((error) => console.log(error));
    }
  }, [formData?.pincode]);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Update User</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Name<span className="text-danger">*</span>
                </Label>
                <Input
                  value={formData?.name}
                  onChange={handleChange}
                  name="name"
                  placeholder="name"
                  invalid={errors?.name}
                />
              </FormGroup>
              <p className="text-danger">{errors?.name}</p>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Email<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="email"
                  name="email"
                  invalid={errors?.email}
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <p className="text-danger">{errors?.email}</p>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Mobile<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="mobile"
                  name="mobile"
                  value={formData.mobile}
                  invalid={errors?.mobile}
                  onChange={(e) =>
                    e.target.value.length < 11 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                />
              </FormGroup>
              <p className="text-danger">{errors?.mobile}</p>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Password<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  invalid={errors?.password}
                />
              </FormGroup>
              <p className="text-danger">{errors?.password}</p>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Gender</Label>
                <Input
                  value={formData.gender}
                  onChange={handleChange}
                  name="gender"
                  type="select"
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Input>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>User Type</Label>
                <Input
                  value={formData.user_type}
                  onChange={handleChange}
                  name="user_type"
                  type="select"
                >
                  <option value="">Select</option>
                  <option value="1">User</option>
                  <option value="2">Agent</option>
                </Input>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Pincode</Label>
                <Input
                  placeholder="PinCode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={(e) =>
                    e.target.value.length < 11 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                />
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>City</Label>
                <Input
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>State</Label>
                <Input
                  placeholder="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-4">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
              <Button className="ms-3" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
