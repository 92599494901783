import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormGroup, Input, Label, Table } from 'reactstrap'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify'
const Faq = () => {
    const { AuthReducer: { auth, isAuthenticated } } = useSelector(state => state)
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [editItem, setEditItem] = useState({ question: "", answer: "" })

    const toggle = () => setModal(!modal);

    const [faqs, setFaqs] = useState([])
    const getFaqs = () => {
        axios('/api/admin/v1/get-faqs', {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        })
            .then((({ data }) => setFaqs(data?.faqs)))
            .catch(err => console.log(err))
    }
    useEffect(() => {
        if (isAuthenticated) {
            getFaqs()
        }
    }, [])
    const handleSubmit = () => {
        axios[isEditing ? "put" : 'post'](`/api/admin/v1/${isEditing ? "update-faq" : "add-faq"}`, editItem, {
            headers: {
                Authorization: `Bearer ${auth?.token}`
            }
        })
            .then((({ data }) => {
                toast.success(data?.message)
                toggle()
                getFaqs()
            })).catch(err => console.log(err))
    }
    const handleDelete = (id)=>{
        axios.delete(`/api/admin/v1/delete-faq/${id}`,{
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }).then((({data})=>{ 
            toast.success(data?.message)
            getFaqs()
         }))
         .catch(err=>{
            console.log(err);
         })
    }
    return (
        <div className='bg-white px-3 py-5 rounded-2  my-4' >
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-4 d-flex align-items-center justify-content-between">
                        <h5 className='mb-0'>Faqs</h5>

                        <Button color='primary' onClick={() => {
                            setIsEditing(false)
                            setEditItem({ question: "", answer: "" })
                            toggle()
                        }} >
                            Add new
                        </Button>
                    </div>
                </div>
                <div className="col-md-12">
                    <Table
                        hover
                        responsive
                    >
                        <thead>
                            <tr>
                                <th>
                                    SL. No.
                                </th>
                                <th>
                                    Question
                                </th>
                                <th>
                                    Answer
                                </th>

                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                faqs?.map?.((item, index) => {
                                    return <tr key={item.id} >
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {item.question}
                                        </td>
                                        <td>
                                            {item.answer}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className='text-warning  ' onClick={() => {
                                                    setEditItem(item)
                                                    setIsEditing(true)
                                                    toggle()
                                                }} role='button' >
                                                    <AiOutlineEdit size={25} />
                                                </span>

                                                <span className='text-danger  ms-3'  onClick={()=>handleDelete(item.id)} role='button' >
                                                    <AiOutlineDelete size={25} />
                                                </span>

                                            </div>
                                        </td>
                                    </tr>
                                })



                            }
                        </tbody>

                    </Table>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{ isEditing?"Edit":"Add" }  Question</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>
                            Question
                        </Label>
                        <Input value={editItem?.question} onChange={(e) => setEditItem(prev => ({ ...prev, question: e.target.value }))} />
                    </FormGroup>

                    <FormGroup>
                        <Label>
                            Answer
                        </Label>
                        <Input value={editItem?.answer} onChange={(e) => setEditItem(prev => ({ ...prev, answer: e.target.value }))} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Faq