import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import CKEditor from "react-ckeditor-component";
import { MdAdd } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import {
  getCourseTypes,
  getCourseCategories,
} from "../../redux/actions/CourseAction";
import {
  getSubCategories,
  getCategories,
} from "../../redux/actions/indexActions";

const EditCourse = () => {
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [text, setText] = useState("");
  const [hindiText, setHindiText] = useState("");
  const [course_tag, setCourse_tag] = useState([]);
  const [outcome, setOutCome] = useState([]);
  const [requirement, setRequirement] = useState([]);
  const [courseCategory, setCourseCategory] = useState([]);
  const [courseCategoryOption, setCourseCategoryOption] = useState([]);
  const [courseSubCategory, setCourseSubCategory] = useState([]);
  const [courseSubCategoryOption, setCourseSubCategoryOption] = useState([]);
  const [courseSubSubCategory, setCourseSubSubCategory] = useState([]);
  const [courseSubSubCategoryOption, setCourseSubSubCategoryOption] = useState(
    []
  );
  const [subCategory, setSubCategory] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [subSubCategoryOption, setSubSubCategoryOption] = useState([]);
  const [formData, setFormData] = useState({
    courseCategory: [],
    courseSubCategory: [],
    courseSubSubCategory: [],
    course_type: "",
    course_category_id: "",
    course_sub_category_id: "",
    courseSubCategoryId: "",
    courseSubSubCategoryId: "",
    title: "",
    hindi_title: "",
    url: "",
    hindi_url: "",
    duration: "",
    duration_hindi: "",
    total_pages: "",
    total_pages_hindi: "",
    thumbnail: "",
    thumbnail_hindi: "",
    course_level: "",
    subCategoryId: [],
    subSubCategoryId: [],
    subSubCategory: [],
    course_tag: "",
    course_by: "",
    certificate_from: "",
    assessment_time: "",
    pass_mark: "",
    total_questions: "",
    no_of_attempts: "",
    short_description: "",
    hindi_short_description: "",
    description: "",
    hindi_description: "",
    requirement: "",
    outcome: "",
    type: "Free",
    amount: 0,
    discount: 0,
    priority: "",
    nsqf_level: "",
    Qp: "",
    language: "",
    rating: "",
    neat_id: "",
    download_certificate: 1,
    training_assessment: 1,
    status: 1,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
    indexReducer: { subCategories },
    CourseReducer: { coursetype },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getCourseTypes(auth.token));
  }, [auth.token]);

  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "thumbnail" && name === "thumbnail_hindi") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePaymentTypeChange = (e) => {
    setFormData({
      ...formData,
      type: e.target.value,
    });
  };

  const handleText = (e) => {
    const newDescription = e.editor.getData();
    setText(newDescription);
  };

  const handleHindiText = (e) => {
    const newHindiDescription = e.editor.getData();
    setHindiText(newHindiDescription);
  };

  const handleRequirement = (e) => {
    e.preventDefault();
    setRequirement((prev) => [...prev, formData.requirement]);
    document.getElementById("requirement").value = "";
    setFormData({
      ...formData,
      requirement: "",
    });
  };

  const handleTermAndConditions = (e) => {
    e.preventDefault();
    setOutCome((prev) => [...prev, formData.outcome]);
    document.getElementById("outcome").value = "";
    setFormData({
      ...formData,
      outcome: "",
    });
  };

  const handleTag = (e) => {
    e.preventDefault();
    setCourse_tag((prev) => [...prev, formData.course_tag]);
    setFormData({
      ...formData,
      course_tag: "",
    });
    document.getElementById("tag").value = "";
  };

  useEffect(() => {
    if (formData?.course_type) {
      axios
        .get(`/api/mobile/v1/coure-category-type/${formData?.course_type}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((res) => {
          // setCourseCategory([])
          setCourseCategoryOption(
            res.data.data.map((item) => ({ name: item.name, id: item.id }))
          );
        })
        .catch((err) => console.error(err));
    } else {
      setCourseCategory([]);
      setCourseCategoryOption([]);
    }
  }, [formData.course_type]);

  const handleCourseCategoryChange = (val) => {
    console.log(val, "val");
    if (val.length) {
      axios
        .get(
          `/api/mobile/v1/coure-sub-category-list?id=${val
            .map((it) => it.id)
            .join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then(({ data }) => {
          setCourseSubCategory((prev) =>
            prev?.filter((item) => val.find((it) => it.id === item.id))
          );
          setCourseSubCategoryOption(
            data.data.map((item) => {
              return {
                name: item.name,
                id: item.id,
                category_id: item.category_id,
              };
            })
          );
        })
        .catch((err) => console.log(err));
    } else {
      setCourseSubCategory([]);
      setCourseSubCategoryOption([]);
    }
  };

  const handleCourseSubCategoryChange = (val) => {
    if (val.length) {
      axios
        .get(
          `/api/mobile/v1/coure-sub-sub-category-list?category_id=${val
            .map((item) => item.category_id)
            .join(",")}&sub_category_id=${val.map((it) => it.id).join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then(({ data }) =>
          setCourseSubSubCategoryOption(
            data.data.map((item) => {
              return {
                name: item.name,
                id: item.id,
                category_id: item.category_id,
                sub_category_id: item.sub_category_id,
              };
            })
          )
        )
        .catch((err) => console.log(err));
    } else {
      setCourseSubSubCategory([]);
      setCourseSubSubCategoryOption([]);
    }
  };

  const handleSubCategoryChange = (val) => {
    if (val.length) {
      axios
        .get(
          `/api/admin/v1/get-industry-by-multi-sector/${val
            .map((item) => item.id)
            .join(",")}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
        .then(({ data }) => {
          setSubSubCategory((prev) =>
            prev.filter((item) =>
              val.find((it) => item.subCategoryId === it.id)
            )
          );
          setSubSubCategoryOption(
            data.industries.map((item) => {
              return {
                name: item.name_english,
                id: item.id,
                sub_category_id: item.subCategoryId,
              };
            })
          );
        })
        .catch((err) => console.log(err));
    } else {
      setSubSubCategory([]);
      setSubSubCategoryOption([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/admin/v1/course-edit/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        const { Courses } = response.data;

        setCourseCategory(
          Courses?.course_category_ids?.map((item) => ({
            name: item?.course_categorie?.name,
            id: item?.course_category_id,
          })) || []
        );

        setCourseSubCategory(
          Courses?.course_sub_category_ids?.map((sc) => ({
            name: sc?.course_sub_categorie?.name,
            id: sc?.id,
            category_id: sc?.course_category,
          })) || []
        );

        setCourseSubSubCategory(
          Courses?.course_sub_sub_category_ids?.map((sc) => ({
            name: sc?.course_sub_sub_categorie?.name,
            id: sc?.course_sub_sub_category_id,
            category_id: sc?.course_category_id,
            sub_category_id: sc?.course_sub_category_id,
          })) || []
        );

        setSubCategory(
          Courses?.course_sector_industry_groups?.map((sc) => ({
            name_english: sc?.subCategory?.name_english,
            id: sc?.course_sector_id,
          })) || []
        );

        setSubSubCategory(
          Courses?.course_sector_industry_groups?.map((sc) => ({
            name: sc.subSubCategory?.name_english,
            id: sc?.course_industry_id,
            sub_category_id: sc?.course_sector_id,
          })) || []
        );

        setText(Courses?.description || "");
        setHindiText(Courses?.hindi_description || "");
        setCourse_tag(
          Courses?.course_tag ? JSON.parse(Courses.course_tag) : []
        );
        setOutCome(Courses?.outcome ? JSON.parse(Courses.outcome) : []);
        setRequirement(
          Courses?.requirement ? JSON.parse(Courses.requirement) : []
        );

        setFormData({
          ...formData,
          course_type: Courses?.course_type,
          title: Courses?.title,
          hindi_title: Courses?.hindi_title,
          url: Courses?.url,
          hindi_url: Courses?.hindi_url,
          duration: Courses?.duration,
          duration_hindi: Courses?.duration_hindi,
          total_pages: Courses?.total_pages,
          total_pages_hindi: Courses?.total_pages_hindi,
          thumbnail: Courses?.thumbnail || "",
          thumbnail_hindi: Courses?.thumbnail_hindi || "",
          course_level: Courses?.course_level,
          course_by: Courses?.course_by,
          short_description: Courses?.short_description,
          hindi_short_description: Courses?.hindi_short_description,
          assessment_time: Courses?.assessment_time,
          certificate_from: Courses?.certificate_from,
          type: Courses?.type,
          amount: Courses?.amount,
          discount: Courses?.discount,
          no_of_attempts: Courses?.no_of_attempts,
          total_questions: Courses?.total_questions,
          pass_mark: Courses?.pass_mark,
          nsqf_level: Courses?.nsqf_level,
          Qp: Courses?.Qp,
          priority: Courses?.priority,
          rating: Courses?.rating,
          language: Courses?.language,
          neat_id: Courses?.neat_id,
          download_certificate: Courses?.download_certificate,
          training_assessment: Courses?.training_assessment,
          status: !!Courses?.status,
        });
      } catch (error) {
        toast.error(error.response?.data || "An error occurred");
        console.error(error, "errorlog");
      }
    };

    fetchData();
  }, [auth]); // Ensure formData is included if it's used within the effect

  const handleSubmit = () => {
    console.log(courseCategory, "courseCategory");
    console.log(courseSubCategory, "courseSubCategory");
    console.log(courseSubSubCategory, "courseSubSubCategory");
    console.log(subSubCategory, "subSubCategory");
    const form = new FormData();
    form.append("title", formData.title);
    form.append("hindi_title", formData.hindi_title);
    form.append("duration", formData.duration);
    form.append("duration_hindi", formData.duration_hindi);
    form.append("url", formData.url);
    form.append("hindi_url", formData.hindi_url);
    form.append("total_pages", formData.total_pages);
    form.append("total_pages_hindi", formData.total_pages_hindi);
    form.append("thumbnail", formData.thumbnail);
    form.append("thumbnail_hindi", formData.thumbnail_hindi);
    form.append("short_description", formData.short_description);
    form.append("hindi_short_description", formData.hindi_short_description);
    form.append("course_level", formData?.course_level);
    form.append("course_type", formData?.course_type);
    courseCategory
      ? courseCategory.map((s, i) => {
          form.append(`courseCategory[${i}][id]`, s.id);
          form.append(`courseCategory[${i}][name]`, s.name);
        })
      : form.append("courseCategory", []);
    courseSubCategory
      ? courseSubCategory.map((s, i) => {
          form.append(`courseSubCategory[${i}][id]`, s.id);
          form.append(`courseSubCategory[${i}][name]`, s.name);
          form.append(`courseSubCategory[${i}][category_id]`, s.category_id);
        })
      : form.append("courseSubCategory", []);
    courseSubSubCategory
      ? courseSubSubCategory.map((s, i) => {
          form.append(`courseSubSubCategory[${i}][id]`, s.id);
          form.append(`courseSubSubCategory[${i}][name]`, s.name);
          form.append(`courseSubSubCategory[${i}][category_id]`, s.category_id);
          form.append(
            `courseSubSubCategory[${i}][sub_category_id]`,
            s.sub_category_id
          );
        })
      : form.append("courseSubSubCategory", []);
    subSubCategory
      ? subSubCategory.map((s, i) => {
          form.append(`sectorindustry[${i}][id]`, s.id);
          form.append(`sectorindustry[${i}][name]`, s.name);
          form.append(
            `sectorindustry[${i}][sub_category_id]`,
            s.sub_category_id
          );
        })
      : form.append("sectorindustry", []);
    form.append("course_by", formData.course_by);
    form.append("certificate_from", formData.certificate_from);
    form.append("assessment_time", formData.assessment_time);
    form.append("no_of_attempts", formData.no_of_attempts);
    form.append("pass_mark", formData.pass_mark);
    form.append("total_questions", formData.total_questions);
    form.append("description", text);
    form.append("hindi_description", hindiText);
    form.append("type", formData.type);
    form.append("amount", formData.amount);
    form.append("discount", formData.discount);
    form.append("neat_id", formData.neat_id);
    form.append("nsqf_level", formData?.nsqf_level);
    form.append("priority", formData?.priority);
    form.append("Qp", formData?.Qp);
    form.append("language", formData.language);
    form.append("rating", formData.rating);
    form.append("status", formData.status ? "1" : "0");
    form.append(
      "download_certificate",
      formData.download_certificate ? "1" : "0"
    );
    form.append(
      "training_assessment",
      formData.training_assessment ? "1" : "0"
    );
    form.append("course_tag", JSON.stringify(course_tag));
    form.append("outcome", JSON.stringify(outcome));
    form.append("requirement", JSON.stringify(requirement));

    axios
      .put(`/api/admin/v1/course/${id}`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setErrors(err.response.data.errors);
      });
  };

  const handleRemoveRequ = (e, i) => {
    e.preventDefault();
    setRequirement(requirement.filter((arr, row) => row !== i));
  };

  const handleRemoveTerm = (e, i) => {
    e.preventDefault();
    setOutCome(outcome.filter((arr, row) => row !== i));
  };

  const handleRemoveTag = (e, i) => {
    e.preventDefault();
    setCourse_tag(course_tag.filter((arr, row) => row !== i));
  };

  console.log(formData, "sfr");
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Update Course</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Course Type <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.course_type}
                type="select"
                name="course_type"
                invalid={errors?.course_type}
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {coursetype?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.course_type}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{errors?.course_type}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course Category</Label>
              <Multiselect
                className="text-black"
                options={courseCategoryOption}
                displayValue="name"
                selectedValues={courseCategory}
                onSelect={(val) => {
                  setCourseCategory([...val]);
                  handleCourseCategoryChange(val);
                }}
                onRemove={(val) => {
                  setCourseCategory([...val]);
                  handleCourseCategoryChange(val);
                }}
                labelledBy="Select"
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course Sub Category</Label>
              <Multiselect
                className="text-black"
                options={courseSubCategoryOption}
                displayValue="name"
                selectedValues={courseSubCategory}
                onSelect={(val) => {
                  setCourseSubCategory([...val]);
                  handleCourseSubCategoryChange(val);
                }}
                onRemove={(val) => {
                  setCourseSubCategory([...val]);
                  handleCourseSubCategoryChange(val);
                }}
                labelledBy="Select"
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course Sub Sub Category</Label>
              <Multiselect
                className="text-black"
                options={courseSubSubCategoryOption}
                selectedValues={courseSubSubCategory}
                displayValue="name"
                onSelect={(val) => {
                  setCourseSubSubCategory(val);
                }}
                onRemove={(val) => setCourseSubSubCategory([...val])}
                labelledBy="Select"
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title English<span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.title}
                name="title"
                invalid={errors?.title}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.title}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Title Hindi</Label>
              <Input
                value={formData?.hindi_title}
                name="hindi_title"
                invalid={errors?.hindi_title}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Url <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.url}
                name="url"
                invalid={errors?.url}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.url}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Url Hindi</Label>
              <Input
                value={formData?.hindi_url}
                name="hindi_url"
                invalid={errors?.hindi_url}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Duration <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.duration}
                name="duration"
                placeholder="duration in minutes only"
                invalid={errors?.duration}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
              <span className="d-block text-danger">{errors?.duration}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Duration Hindi</Label>
              <Input
                value={formData?.duration_hindi}
                name="duration_hindi"
                placeholder="duration in minutes only"
                invalid={errors?.duration_hindi}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Total Video</Label>
              <Input
                value={formData?.total_pages}
                name="total_pages"
                invalid={errors?.total_pages}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Total Video Hindi</Label>
              <Input
                value={formData?.total_pages_hindi}
                name="total_pages_hindi"
                invalid={errors?.total_pages_hindi}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Upload Thumbnail <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                type="file"
                name="thumbnail"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    thumbnail: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">{errors?.thumbnail}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Upload Thumbnail Hindi</Label>
              <Input
                type="file"
                name="thumbnail_hindi"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    thumbnail_hindi: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Course Level <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.course_level}
                type="select"
                name="course_level"
                onChange={inputHandler}
              >
                <option value="">Select Course Level</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advance">Advance</option>
              </Input>
              <span className="d-block text-danger">
                {errors?.course_level}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Select Segment</Label>
              <Multiselect
                className="text-black"
                options={subCategories}
                selectedValues={subCategory}
                displayValue="name_english"
                onSelect={(val) => {
                  setSubCategory([...val]);
                  handleSubCategoryChange(val);
                }}
                onRemove={(val) => {
                  setSubCategory([...val]);
                  handleSubCategoryChange(val);
                }}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Select Industry</Label>
              <Multiselect
                className="text-black"
                options={subSubCategoryOption}
                displayValue="name"
                selectedValues={subSubCategory}
                onSelect={(val) => setSubSubCategory([...val])}
                onRemove={(val) => setSubSubCategory([...val])}
              />
              <span className="d-block text-danger">
                {errors?.subSubCategoryId}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course Tag</Label>
              <div className="d-flex">
                <Input
                  id="tag"
                  type="text"
                  name="course_tag"
                  className="me-3"
                  invalid={errors?.course_tag}
                  value={formData.course_tag}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      course_tag: e.target.value,
                    })
                  }
                />
                <a
                  size="sm"
                  className="btn btn-secondary cursor-pointer text-white text-center"
                  onClick={handleTag}
                >
                  <MdAdd />
                </a>
              </div>

              {/* <FormFeedback className="offset-5">{!!state?.errors?.course_tag ? state?.errors?.course_tag[0] : ''}</FormFeedback> */}
            </FormGroup>
            {course_tag?.length ? (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Sno.</th>
                        <th>Course Tag</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {course_tag.map((trm, i) => (
                        <tr key={Math.random()}>
                          <td>{i + 1}</td>
                          <td>{trm}</td>
                          <td>
                            <a
                              className="cursor-pointer"
                              onClick={(e) => handleRemoveTag(e, i)}
                            >
                              <AiOutlineDelete />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Course by <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.course_by}
                name="course_by"
                invalid={errors?.course_by}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.course_by}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Certificate From</Label>
              <Input
                value={formData?.certificate_from}
                name="certificate_from"
                invalid={errors?.certificate_from}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Assessment Time</Label>
              <Input
                value={formData?.assessment_time}
                name="assessment_time"
                invalid={errors?.assessment_time}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Passing Marks</Label>
              <Input
                value={formData?.pass_mark}
                name="pass_mark"
                invalid={errors?.pass_mark}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Total Questions</Label>
              <Input
                value={formData?.total_questions}
                name="total_questions"
                invalid={errors?.total_questions}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>No Of Attempts</Label>
              <Input
                value={formData?.no_of_attempts}
                name="no_of_attempts"
                invalid={errors?.no_of_attempts}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Short Description <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                name="short_description"
                value={formData?.short_description}
                type="textarea"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {errors?.short_description}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Description Hindi</Label>
              <Input
                name="hindi_short_description"
                value={formData?.hindi_short_description}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-11 offset-md-1">
            <FormGroup>
              <Label>
                Description <span className="text-danger fs-4">*</span>
              </Label>
              <CKEditor
                activeClass="col-md-10 pr-0"
                content={text}
                events={{
                  change: handleText,
                }}
              />
              <span className="d-block text-danger">{errors?.description}</span>
              {/* <Input
                value={formData?.description}
                name="description"
                type="textarea"
                onChange={inputHandler}
              /> */}
            </FormGroup>
          </div>
          <div className="col-md-11 offset-md-1">
            <FormGroup>
              <Label>Description Hindi</Label>
              <CKEditor
                activeClass="col-md-10 pr-0"
                content={hindiText}
                events={{
                  change: handleHindiText,
                }}
              />
              {/* <Input
                value={formData?.hindi_description}
                name="hindi_description"
                type="textarea"
                onChange={inputHandler}
              /> */}
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Requirement</Label>
              <div className="d-flex">
                <Input
                  id="requirement"
                  type="text"
                  name="requirement"
                  className="me-3"
                  invalid={errors?.requirement}
                  value={formData?.requirement}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      requirement: e.target.value,
                    })
                  }
                />
                <a
                  size="sm"
                  className="btn btn-secondary cursor-pointer text-white text-center"
                  onClick={handleRequirement}
                >
                  <MdAdd />
                </a>
              </div>
              {/* <FormFeedback className="offset-5">{errors?.requirement ? errors?.requirement[0] : ''}</FormFeedback> */}
            </FormGroup>
            {requirement?.length ? (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Sno.</th>
                        <th>Requirement</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requirement.map((trm, i) => (
                        <tr key={Math.random()}>
                          <td>{i + 1}</td>
                          <td>{trm}</td>
                          <td>
                            <a
                              className="cursor-pointer"
                              onClick={(e) => handleRemoveRequ(e, i)}
                            >
                              <AiOutlineDelete />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>OutCome</Label>
              <div className="d-flex">
                <Input
                  id="outcome"
                  type="text"
                  name="outcome"
                  className="me-3"
                  invalid={errors?.outcome}
                  value={formData.outcome}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      outcome: e.target.value,
                    })
                  }
                />
                <a
                  size="sm"
                  className="btn btn-secondary cursor-pointer text-white text-center"
                  onClick={handleTermAndConditions}
                >
                  <MdAdd />
                </a>
              </div>
              {/* <FormFeedback className="offset-5">{errors?.outcome ? errors?.outcome[0] : ''}</FormFeedback> */}
            </FormGroup>
            {outcome?.length ? (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Sno.</th>
                        <th>Term & Condition</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outcome.map((trm, i) => (
                        <tr key={Math.random()}>
                          <td>{i + 1}</td>
                          <td>{trm}</td>
                          <td>
                            <a
                              className="cursor-pointer"
                              onClick={(e) => handleRemoveTerm(e, i)}
                            >
                              <AiOutlineDelete />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label style={{ marginRight: 15 }}>Type</Label>
              <Input
                type="radio"
                id="type-free"
                name="type"
                value="Free"
                onChange={handlePaymentTypeChange}
                checked={formData.type === "Free"}
              />
              <Label
                className="custom-control-label"
                style={{ marginInline: 10 }}
              >
                Free
              </Label>
              <Input
                type="radio"
                id="type-paid"
                name="type"
                value="Paid"
                onChange={handlePaymentTypeChange}
                checked={formData.type === "Paid"}
              />
              <Label
                className="custom-control-label"
                style={{ marginInline: 10 }}
              >
                Paid
              </Label>
            </FormGroup>
          </div>
          {formData.type === "Paid" ? (
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Amount</Label>
                <Input
                  value={formData?.amount}
                  name="amount"
                  invalid={errors?.amount}
                  onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
                />
              </FormGroup>
            </div>
          ) : null}
          {formData.type === "Paid" ? (
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Discount</Label>
                <Input
                  value={formData?.discount}
                  name="discount"
                  invalid={errors?.discount}
                  onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
                />
              </FormGroup>
            </div>
          ) : null}
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Priority</Label>
              <Input
                value={formData?.priority}
                name="priority"
                invalid={errors?.priority}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Nsqf Level</Label>
              <Input
                value={formData?.nsqf_level}
                name="nsqf_level"
                invalid={errors?.nsqf_level}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Qp</Label>
              <Input
                value={formData?.Qp}
                name="Qp"
                invalid={errors?.Qp}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Language <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.language}
                type="select"
                name="language"
                onChange={inputHandler}
              >
                <option value="">Select Language</option>
                <option value="Hindi">Hindi</option>
                <option value="English">English</option>
              </Input>
              <span className="d-block text-danger">{errors?.language}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Rating (1 to 4)</Label>
              <Input
                value={formData?.rating}
                name="rating"
                invalid={errors?.rating}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Neat Id</Label>
              <Input
                value={formData?.neat_id}
                name="neat_id"
                invalid={errors?.neat_id}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1" style={{ marginBlock: 15 }}>
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.download_certificate}
                onChange={() =>
                  setFormData({
                    ...formData,
                    download_certificate: !formData.download_certificate,
                  })
                }
                name="download_certificate"
              />
              <Label check>Download Certificate</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1" style={{ marginBlock: 15 }}>
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.training_assessment}
                onChange={() =>
                  setFormData({
                    ...formData,
                    training_assessment: !formData.training_assessment,
                  })
                }
                name="training_assessment"
              />
              <Label check>Training Assessment</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1" style={{ marginBlock: 15 }}>
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={() =>
                  setFormData({ ...formData, status: !formData.status })
                }
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourse;
