import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { getCategories ,getServiceCategories } from "../../redux/actions/indexActions";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";
const ServicesCategories = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { categories, loading, categoriesCount },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getServiceCategories(auth?.token, paginate, page, search));
  }, [auth, paginate, page, search]);

  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Category")) {
      axios
        .delete(`/api/admin/v1/service-category/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getServiceCategories(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Service Categories</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "CATEGORY" &&
                item?.permission?.permission === "create"
            ) && (
              <Link to="/add-service-category">
                <Button
                  className="bg-white text-black"
                  style={{ color: "primary" }}
                  size="sm"
                >
                  <span className="me-2">
                    <MdAdd size={22} />
                  </span>
                  Add Category
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-3 mb-2 ">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Description</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType === "CATEGORY" &&
                        (item?.permission?.permission === "edit" ||
                          item?.permission?.permission === "delete")
                    ) && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {categories?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + page * 10}</th>
                        <td>{item?.name}</td>
                        <td>{item?.description}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "CATEGORY" &&
                                item?.permission?.permission === "edit"
                            ) && (
                              <Link to={`/service-category/${item?.id}`}>
                                <AiOutlineEdit
                                  size={22}
                                  className="text-warning me-3"
                                />
                              </Link>
                            )}
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "CATEGORY" &&
                                item?.permission?.permission === "delete"
                            ) && (
                              <AiOutlineDelete
                                size={22}
                                className="text-danger"
                                onClick={() => handleDel(item.id)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {categoriesCount}
            </div>
          </div>

          <Pagination
            pageCount={categoriesCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesCategories;
