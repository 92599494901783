const initialState = {
  products: [],
  productCount: 0,
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCT_PENDING":
      return { ...state, loading: true };

    case "GET_PRODUCT_FULFILLED":
      return {
        ...state,
        loading: false,
        products: action.payload?.data,
        productCount: action?.payload?.total,
      };
      return {
        ...state,
        loading: false,
        courseSubSubCategories: action.payload.courseSubSubCategoriesCount,
        courseSubSubCategoriesCount: action.courseSubSubCategoriesCount,
      };
    case "GET_PRODUCT_FAILED":
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};

export default ProductReducer;
