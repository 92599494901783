import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";
import { FiChevronDown } from "react-icons/fi";
import { Input } from "reactstrap";
import Pagination from "../Components/Paginate";
import { getEdpUser } from "../redux/actions/UserAction";

const Userlist = () => {
    const dispatch = useDispatch();
    const {
        AuthReducer: { auth },
        UserReducer: { edpUser, loading, userCount },
        PermissionReducer: { allowedPermissions },
    } = useSelector((state) => state);
    const [page, setPage] = useState(1);
    const [advanceSearch, setAdvanceSearch] = useState(false);
    const [paginate, setPaginate] = useState(10);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("desc");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});
    console.log("edp users -> ", edpUser)
    useEffect(() => {
        dispatch(getEdpUser(auth?.token, paginate, page, search, sort, startDate, endDate));
    }, [auth, paginate, page, search, sort, startDate, endDate]);
    const toggle = () => setIsOpen((prev) => !prev);
    return (
        <div>
            <div className="bg-white px-3 py-4 rounded-2  my-4" style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
                <div className="row">
                    <div className="col-md-6 text-black">
                        <strong> EDP  Users : </strong>
                    </div>
                    <div className="c-card-top-inputs c-card-top-search col-md-5">
                        <div className="input-group">
                            <Input size={"sm"} value={search} className="c-form-control-serch form-control-sm form-control" placeholder="Search By Name" onChange={({ target: { value } }) => setSearch(value)} />
                            <div class="input-group-append">
                                <button title="Advance Search" onClick={() => {
                                    advanceSearch ? setAdvanceSearch(false) : setAdvanceSearch(true);
                                }} id="c_searchBlock_toggle" class="btn btn-secondary btn-sm c-card-top-block-icon" type="button">
                                    <FiChevronDown />
                                </button>
                            </div>
                        </div>
                        <div className={advanceSearch ? "c-card-top-block" : "d-none"} id="c_searchBlock">
                            <div className="c-card-top-block-sec">

                                <div className="c-card-top-block-item">
                                    <label>Start Date</label>
                                    <Input type="date" placeholder="Start Date" onChange={e => setStartDate(e.target.value)} value={startDate} />
                                </div>
                                <div className="c-card-top-block-item">
                                    <label>End Date</label>
                                    <Input type="date" placeholder="End Date" onChange={e => setEndDate(e.target.value)} value={endDate} />
                                </div>
                                <div className="c-card-top-block-item">
                                    <Input size={"sm"} type="select" name="sort" onChange={e => setSort(e.target.value)} value={sort}>
                                        <option value="asc">Ascending</option>
                                        <option value="desc">Descending</option>
                                    </Input>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center mx-auto">
                            <Spinner color="primary" />
                        </div>
                    ) : (
                        <div className="col-md-12">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>SL. No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Gateway Type</th>
                                        <th>Product Info</th>
                                        <th>Oder Id</th>
                                        <th>Payment Id</th>
                                        <th>Joining Date</th>
                                        <th>Expiry Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {edpUser?.map?.((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item?.user?.name}</td>
                                                <td>{item?.user?.email}</td>
                                                <td>{item?.user?.mobile}</td>
                                                <td>{item?.gateway_type}</td>
                                                <td>{item?.product_info}</td>
                                                <td>{item?.order_id}</td>
                                                <td>{item?.order_id}</td>
                                                <td>{moment(item?.joining_date).format("DD-MM-YY : HH:mm")}</td>
                                                <td>{moment(item?.expire_date).format("DD-MM-YY : HH:mm")}</td>
                                                <td>{item?.amount}</td>
                                                <td>{item?.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <select id="paginate" onChange={(e) => setPaginate(e.target.value)} value={paginate} className="paging custom-select custom-select-page" >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                        </select>
                        <div className="mx-3 py-2 text-black"> Total Results : {userCount}</div>
                    </div>
                    <Pagination
                        pageCount={userCount / paginate}
                        onChange={(e) => setPage(e.selected + 1)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Userlist;
