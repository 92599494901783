import React, { memo } from 'react'
import ReactPaginate from 'react-paginate';
import styled from 'styled-components'
const Pagination = memo(({ onChange, pageCount = 8,pageRange=3 }) => {
    return (
        <MyPaginate>
            <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={onChange}
                pageRangeDisplayed={pageRange}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
            />
        </MyPaginate>
    )
})

export default Pagination


const MyPaginate = styled.div`
ul{
    display: flex;
    background-color: white;
    align-items: center;
    box-shadow:4px 4px 18px rgba(0,0,0,0.15);
    width: fit-content;
    padding: 0;
    li.selected{
        background-color: #0D6EFD;
        color: #ffffff;
    }
    li{
        padding:6px 16px;
        list-style: none;
        border-right:3px solid #f6f6f6 ;
        color: #000000;
    }
    a{
        text-decoration: none;
        color: #000000;
    }
}

ul>li:last-child{
    border-right: none;
}
`