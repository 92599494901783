import React from "react";
import { useState, useEffect } from "react";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import { getSubCategories } from "../../redux/actions/indexActions";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const associateOpt = [
  {
    label: "Professional",
    value: 2,
    sector: [],
    industries: [],
    sectorOpt: [],
    industriesOpt: [],
  },
  {
    label: " Industry",
    value: 1,
    sector: [],
    industries: [],
    sectorOpt: [],
    industriesOpt: [],
  },
  {
    label: "Service",
    value: 3,
    sector: [],
    industries: [],
    sectorOpt: [],
    industriesOpt: [],
  },
];

const ConsultantAdd = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    pinCode: "",
    mobile: "",
    city: "",
    contactPerson: "",
    email: "",
    state: "",
    phone: "",
    source: "",
    website: "",
    designation: "",
    experience: "",
    remark: "",
    gender: "",
    audioFee: 300,
    videoFee: 600,
    paymentType: 0,
    userType:1
  });
  const [associate, setAssociate] = useState([]);
  const [errors, setErrors] = useState({});
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    let data = [];
    for (let index = 0; index < associate.length; index++) {
      if (associate[index].value === 3) {
        data.push(...associate[index].sector);
      } else {
        data.push(...associate[index].industries);
      }
    }
    data = data.map((it) =>
      it.categoryId !== 3
        ? { ...it, subSubCategoryId: it.value }
        : { ...it, subCategoryId: it.value }
    );
    axios
      .post(
        "/api/admin/v1/add-consultant",
        {
          ...formData,
          type: associate.map((it) => it.value).join(","),
          categories: data,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then(({ data }) => {
        toast.success(data.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setErrors(err?.response?.data);
      });
  };

  const getIndutries = (idss, index) => {
    const prev = [...associate];
    axios
      .get(`/api/admin/v1/get-industry-by-multi-sector/${idss}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then(({ data }) => {
        prev[index].industriesOpt = data?.industries.map((item) => ({
          label: item.name_english,
          value: item.id,
          subCategoryId: item.subCategoryId,
        }));
        setAssociate(prev);
      })
      .catch((err) => console.log(err));
  };
  const handleMultiSectorChange = (val, id, pos) => {
    const prev = [...associate];
    prev[pos].sector = val.map((it) => ({ ...it, categoryId: id }));
    setAssociate(prev);
    if (prev[pos].sector.length) {
      getIndutries(val.map((it) => it.value).join(","), pos);
    } else {
      prev[pos].industries = [];
      prev[pos].industriesOpt = [];
    }
  };
  const handleMultiIndustryChange = (val, id, pos) => {
    const prev = [...associate];
    prev[pos].industries = val.map((it) => ({ ...it, categoryId: id }));
    setAssociate(prev);
  };

  const handleAssociateChagne = (val) => {
    axios
      .get(
        `/api/admin/v1/get-multi-sectors/${val
          .map((it) => it.value)
          .join(",")}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then(({ data }) =>
        setAssociate((prev) =>
          prev.map((it) => ({
            ...it,
            sectorOpt: data?.sectors
              ?.filter((item) => it.value === item.category_type_id)
              .map((i) => ({ label: i.name_english, value: i.id })),
          }))
        )
      )
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Consultant</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  {" "}
                  Associate With us As<span className="text-danger">
                    *
                  </span>{" "}
                </Label>
                <MultiSelect
                  options={associateOpt}
                  value={associate}
                  onChange={(val) => {
                    setAssociate(val);
                    handleAssociateChagne(val);
                  }}
                  labelledBy="Select"
                  invalid={Boolean(errors?.type)}
                />
              </FormGroup>
              <p className="text-danger">{errors?.type}</p>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Name<span className="text-danger"> *</span>
                </Label>
                <Input
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                  invalid={errors?.name}
                />
              </FormGroup>
              <p className="text-danger">{errors?.name}</p>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Gender</Label>
                <Input
                  value={formData.gender}
                  onChange={handleChange}
                  name="gender"
                  type="select"
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Input>
              </FormGroup>
            </div>

            {associate.map((item, index) => {
              return (
                <div className="bg-light py-3 my-3 rounded-3" key={item.value}>
                  <h5 className="offset-md-1 mb-4"> {item.label} </h5>
                  <div className="row">
                    {Boolean(associate.length) && (
                      <div className="col-md-4 offset-md-1">
                        <FormGroup>
                          <Label>Choose Sector</Label>
                          <MultiSelect
                            value={item.sector}
                            options={item.sectorOpt}
                            className="w-100"
                            onChange={(val) =>
                              handleMultiSectorChange(val, item.value, index)
                            }
                          />
                        </FormGroup>
                      </div>
                    )}

                    {Boolean(associate.length) && item.value !== 3 && (
                      <div className="col-md-4 offset-md-1">
                        {console.log(item.industriesOpt)}
                        <FormGroup>
                          <Label>Choose Industry</Label>
                          <MultiSelect
                            options={item.industriesOpt}
                            value={item.industries}
                            onChange={(val) =>
                              handleMultiIndustryChange(val, item.value, index)
                            }
                          />
                        </FormGroup>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label> Audio Fee </Label>
                <Input
                  name="audioFee"
                  value={formData.audioFee}
                  onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                  invalid={errors.audioFee}
                />
                <p className="text-danger">{errors?.audioFee}</p>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label> Video Fee </Label>
                <Input
                  name="videoFee"
                  value={formData.videoFee}
                  onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                  invalid={errors.videoFee}
                />
                <p className="text-danger">{errors?.videoFee}</p>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Address</Label>
                <Input
                  placeholder="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  type="textarea"
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Pincode<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="PinCode"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={(e) =>
                    e.target.value.length < 11 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                  invalid={errors.pinCode}
                />
                <p className="text-danger">{errors?.pinCode}</p>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Mobile<span className="text-danger"> *</span>
                </Label>
                <Input
                  placeholder="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={(e) =>
                    e.target.value.length < 11 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                  invalid={errors?.mobile}
                />
              </FormGroup>
              <p className="text-danger">{errors?.mobile}</p>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Email<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  invalid={errors.email}
                />
                <p className="text-danger">{errors?.email}</p>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>City</Label>
                <Input
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>State</Label>
                <Input
                  placeholder="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Contact Person</Label>
                <Input
                  placeholder="Contact Person"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Phone</Label>
                <Input
                  placeholder="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) =>
                    e.target.value.length < 11 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Experience<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  invalid={errors.experience}
                />
                <p className="text-danger">{errors?.experience}</p>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Website</Label>
                <Input
                  placeholder="Website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Designation<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Designation"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  invalid={errors.designation}
                />
                <p className="text-danger">{errors?.designation}</p>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Source</Label>
                <Input
                  placeholder="Source"
                  type="select"
                  name="source"
                  value={formData.source}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="IID Reference">IID Reference</option>
                  <option value="Google">Google</option>
                  <option value="Exhibition">Exhibition</option>
                  <option value="Facebook">youbtube</option>
                  <option value="walking">walking</option>
                  <option value="Event">Event</option>
                  <option value="other">other</option>
                </Input>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Payment Type</Label>
                <Input
                  placeholder="paymentType"
                  type="select"
                  name="paymentType"
                  value={formData.paymentType}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value={0}>Free</option>
                  <option value={1}>Paid</option>
                </Input>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>User Type</Label>
                <Input
                  placeholder="userType"
                  type="select"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value={1}>External</option>
                  <option value={0}>Internal</option>
                </Input>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Remarks</Label>
                <Input
                  type="textarea"
                  placeholder="Remarks"
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-4">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
              <Button className="ms-3" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultantAdd;
