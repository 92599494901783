import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { Spinner } from "reactstrap";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { CSVLink } from 'react-csv'
import { BiSolidFileExport } from "react-icons/bi";
import { getEnquiries } from "../../redux/actions/indexActions";

const EnquiriesList = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { enquiries, loading, enquiriesCount },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [count, setCount] = useState(0);

  useEffect(() => {
    dispatch(getEnquiries(auth?.token, paginate, page, search, sort));
  }, [auth, paginate, page, search, sort]);

  const csvHead = [[ 'name', 'email', 'mobile', 'remarks']];
  const csvData = enquiries.map((cv) => [
    cv?.name,
    cv?.email,
    cv?.mobile,
    cv?.remarks,
  ]);
  const exportData = csvHead.concat(csvData);
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Enquires List</h5>
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-3 mb-2 ">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name, Email or Mobile"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          
          <div className="col-md-2 ">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType ===
              "COURSEORDER" &&
              item?.permission?.permission === "export"
          ) && (
              <div className="col-md-1">
                <CSVLink
                  filename={
                    "Enquiries.csv"
                  }
                  className="text-black ms-3"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Remarks</th>
                  </tr>
                </thead>

                <tbody>
                  {(enquiries ? enquiries : [])?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.name}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>{item?.remarks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
          <Pagination
            onChange={(e) => setPage(e.selected + 1)}
            pageCount={enquiriesCount / paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default EnquiriesList;
