import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getCourses } from "../../redux/actions/CourseAction";

const AddCourseQuiz = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    courseId: "",
    name: "",
    quiz_time: "",
    pass_mark: "",
    number_of_attempts: "",
    quiz_course_duration: "",
    quiz_course_start: "",
    quiz_course_end: "",
    is_demo: 0,
    status: 1,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
    CourseReducer: { allCourses }
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getCourses(auth.token))
  }, [auth.token])

  const inputHandler = (e) => {
    console.log(e.target, 'target');
    const { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    }
    if (name === "is_demo") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    form.append('courseId', formData.courseId)
    form.append('name', formData.name)
    form.append('quiz_time', formData.quiz_time)
    form.append('pass_mark', formData.pass_mark)
    form.append('number_of_attempts', formData.number_of_attempts)
    form.append('quiz_course_duration', formData.quiz_course_duration)
    form.append('quiz_course_start', formData.quiz_course_start)
    form.append('quiz_course_end', formData.quiz_course_end)
    form.append("is_demo", formData.is_demo)
    form.append("status", formData.status)
    axios
      .post("/api/admin/v1/add-course-quiz", form, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data);
      });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Course Quiz</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.courseId}
                type="select"
                name="courseId"
                invalid={errors?.courseId}
                onChange={inputHandler}
              >
                <option value="">Select Course</option>
                {allCourses?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.title}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{errors?.courseId}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Name <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.name}
                name="name"
                invalid={errors?.name}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.name}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Quiz Time <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.quiz_time}
                name="quiz_time"
                invalid={errors?.quiz_time}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.quiz_time}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Passing Mark <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.pass_mark}
                name="pass_mark"
                maxLength={3}
                invalid={errors?.pass_mark}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.pass_mark}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Number Of Attempts <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.number_of_attempts}
                name="number_of_attempts"
                maxLength={2}
                invalid={errors?.number_of_attempts}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.number_of_attempts}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Quiz Course Start Date</Label>
              <Input
                type="date"
                value={formData?.quiz_course_start}
                name="quiz_course_start"
                invalid={errors?.quiz_course_start}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Quiz Course End Date</Label>
              <Input
                type="date"
                value={formData?.quiz_course_end}
                name="quiz_course_end"
                invalid={errors?.quiz_course_end}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Quiz Course Duration</Label>
              <Input
                value={formData?.quiz_course_duration}
                name="quiz_course_duration"
                invalid={errors?.quiz_course_duration}
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.is_demo}
                onChange={inputHandler}
                name="is_demo"
              />
              <Label check>Is Demo</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseQuiz;
