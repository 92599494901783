import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getCategories } from "../../redux/actions/indexActions";
import { getCourseType } from "../../redux/actions/CourseAction";
const UpdateCourseType = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        course_type: "",
        description: "",
        status: 1,
    });
    const navigate = useNavigate();
    const {
        AuthReducer: { auth },
    } = useSelector((state) => state);

    const [errors, setErrors] = useState({});

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        if (id) {
            axios(`/api/admin/v1/course-type-update/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
                .then((res) => {
                    setFormData((prev) => {
                        return {
                            ...prev,
                            course_type: res.data?.CourseTypes?.course_type,
                            description: res.data?.CourseTypes?.description,
                            status: res.data?.CourseTypes?.status,
                        };
                    });
                })

                .catch((err) => console.log(err));
        }
    }, [id, auth]);

    const handleSubmit = () => {
        const form = new FormData()
        for (const key in formData) {
            form.append([key], formData[key])
        }
        axios.put(`/api/admin/v1/course-type/${id}`, form, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`
            }
        })
            .then((res) => {
                toast.success(res.data?.message)
                navigate(-1)
            }
            ).catch((err) => {
                toast.error(err.response.data);
                setErrors(err.response.data);
            });
    }

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h4 className="text-white">Edit Course Type</h4>
                </div>
            </div>
            <div className='bg-white px-3 py-4 rounded-2  my-4' >
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Course Type <span className="text-danger fs-4">*</span> </Label>
                            <Input value={formData?.course_type} name="course_type" invalid={errors?.course_type} onChange={inputHandler} />
                            <span className="d-block text-danger">
                                {" "}
                                {errors?.course_type}{" "}
                            </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Full Description</Label>
                            <Input value={formData?.description} name="description" type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                onChange={inputHandler}
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color='primary' onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className='ms-3' onClick={() => navigate(-1)}  >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCourseType;
