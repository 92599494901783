import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { Table, Spinner } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { Input } from "reactstrap";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getSubCategories,
} from "../../redux/actions/indexActions";
const Index = () => {
  const [loading, setLoading] = useState(true);
  const [queries, setQueries] = useState([]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("DESC");
  const [paginate, setPaginate] = useState(10);
  const [taxQueriesCount, setTaxQueriesCount] = useState(0);
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subSubSubCategoryFilter, setSubSubCategoryFilter] = useState("");
  const [subCategoriess, setSubCategories] = useState([]);

  const {
    AuthReducer: { auth },
    indexReducer: { categories },
    PermissionReducer: { allowedPermissions }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const getQueries = (
    limit,
    page,
    search,
    sort,
    categoryFilter = "",
    subCategoryFilter = "",
  ) => {
    axios(
      `/api/admin/v1/get-tax-queries?limit=${limit || 10}&page=${page || 0
      }&search=${search}&sort=${sort}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then(({ data }) => {
        setLoading(false);
        setQueries(data?.queries);
        setTaxQueriesCount(data?.taxQueryCount);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getQueries(
      paginate,
      page,
      search,
      sort,
      categoryFilter,
      subCategoryFilter,
      subSubSubCategoryFilter
    );
  }, [
    paginate,
    page,
    search,
    sort,
    categoryFilter,
    subCategoryFilter,
    subSubSubCategoryFilter,
  ]);
  useEffect(() => {
    if (categoryFilter) {
      // setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${categoryFilter}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [categoryFilter]);

  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);
  const handleDelete = (id) => {
    axios
      .put(
        `/api/admin/v1/delete-tax-query/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then(({ data }) => {
        toast.success(data?.message);
        getQueries();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const csvHead = [
    ["name", "mobile", "category", "sector", "question title", "question"],
  ];
  const csvData = queries.map((cv) => [
    cv?.user?.name,
    cv?.mobile,
    cv?.["category-type"]?.type,
    cv?.subCategory?.name_english,
    cv?.questionTitle,
    cv?.question,
  ]);
  const exportData = csvHead.concat(csvData);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className=" d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Written Consultancy</h5>
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-3 mb-2">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Title"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={categoryFilter}
              type="select"
              name="categoryId"
              onChange={({ target }) => setCategoryFilter(target.value)}
            >
              <option value="">Select Category</option>
              {categories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.type}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subCategoryFilter}
              type="select"
              name="subCategoryId"
              onChange={({ target }) => setSubCategoryFilter(target.value)}
            >
              <option value="">Select Segment</option>
              {subCategoriess?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>

          <div className="col-md-2 ">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="ASC">Ascending</option>
              <option value="DESC">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType ===
              "WRITTENCONSULTANCY" &&
              item?.permission?.permission === "export"
          ) && (
              <div className="col-md-1 mb-2">
                <CSVLink
                  filename={"written-consultancy.csv"}
                  className="text-black ms-3"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Category</th>
                    <th>Segment</th>
                    <th>Question Title</th>
                    <th>Question</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType ===
                        "WRITTENCONSULTANCY" &&
                        item?.permission?.permission === "delete"
                    ) && (
                        <th>Action</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {queries?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + page * 10}</th>
                        <td>{item?.user?.name}</td>

                        <td>{item?.mobile}</td>

                        <td>{item?.["category-type"]?.type}</td>

                        <td>{item?.subCategory?.name_english}</td>
                        <td>{item?.questionTitle}</td>
                        <td>{item?.question}</td>
                        {allowedPermissions?.some?.(
                          (item) =>
                            item?.permission?.moduleType ===
                            "WRITTENCONSULTANCY" &&
                            item?.permission?.permission === "delete"
                        ) && (
                            <td>
                              <AiOutlineDelete
                                onClick={() => handleDelete(item.id)}
                                className="text-danger"
                                role="button"
                                size={25}
                              />
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
          <Pagination
            pageCount={taxQueriesCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
