import axios from "axios";

export const getConsultantList = (token, limit, page, search= "", categorys, subCategory, subSubCategory,
sort,startDate,endDate,statusType) => async (dispatch) => {
  try {
    
    dispatch({ type: "GET_CONSULTANT_LIST" });
    const response = await axios(`/api/admin/v1/get-consultants?limit=${limit||10}&page=${page||0}&search=${search}&categorys=${categorys}&subCategorys=${subCategory}&subSubCategorys=${subSubCategory}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&statusType=${statusType}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "GET_CONSULTANT_LIST_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({
      type: "GET_CONSULTANT_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCategoryType = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_CATEGORY_TYPE_LIST" });
    const response = await axios("/api/admin/v1/get-category-type", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_CATEGORY_TYPE_LIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CATEGORY_TYPE_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};
