const initialState = {
  loading: false,
  error: {},
  courseTypes: [],
  courseTypesCount: "",
  courseCategories: [],
  courseCategoriesCount: "",
  coursetype: [],
  courseSubCategories: [],
  courseSubCategoriesCount: "",
  courseSubSubCategories: [],
  courseSubSubCategoriesCount: "",
  courseSectionModule: [],
  courseSectionModuleList: [],
  courseModule: [],
  courseOrder: [],
  courseCount: 0,
  courseModuleCount: 0,
  courseModuleSectionCount:0,
  courses: [],
  coursesCount: "",
  courseQuizes: [],
  courseQuizCount: "",
  allCourses: [],
};

const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COURSETYPE_PENDING":
    case "GET_COURSECATEGORY_PENDING":
    case "GET_COURSE_TYPE_LIST_PENDING":
    case "GET_COURSE_SUB_CATEGORY_PENDING":
    case "GET_COURSE_SUB_SUB_CATEGORY_PENDING":
    case "GET_COURSE_SECTION_MODULE_PENDING":
    case "GET_COURSE_SECTION_MODULE_LIST_PENDING":
    case "GET_COURSE_MODULE_PENDING":
    case "GET_COURSE_ORDER_PENDING":
    case "GET_COURSE_LIST_PENDING":
    case "GET_COURSE_PENDING":
    case "GET_COURSE_QUIZ_LIST_PENDING":
    case "GET_COURSE_QUIZ_QUESTION_PENDING":
      return { ...state, loading: true };

    case "GET_COURSETYPE_FULFILLED":
      return {
        ...state,
        loading: false,
        courseTypes: action.payload,
        courseTypesCount: action.courseTypesCount,
      };

    case "GET_COURSECATEGORY_FULFILLED":
      return {
        ...state,
        loading: false,
        courseCategories: action.payload,
        courseCategoriesCount: action.courseCategoriesCount,
      };
    case "GET_COURSE_TYPE_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        coursetype: action.payload?.data,
      };
    case "GET_COURSE_SUB_CATEGORY_FULFILLED":
      return {
        ...state,
        loading: false,
        courseSubCategories: action.payload,
        courseSubCategoriesCount: action.courseSubCategoriesCount,
      };
    case "GET_COURSE_SUB_SUB_CATEGORY_FULFILLED":
      return {
        ...state,
        loading: false,
        courseSubSubCategories: action.payload,
        courseSubSubCategoriesCount: action.courseSubSubCategoriesCount,
      };

    case "GET_COURSE_SECTION_MODULE_FULFILLED":
      return {
        ...state,
        loading: false,
        courseSectionModule: action.payload?.data,
        courseModuleSectionCount: action.courseModuleSectionCount,
      };
    case "GET_COURSE_SECTION_MODULE_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        courseSectionModuleList: action.payload?.data,
      };
    case "GET_COURSE_MODULE_FULFILLED":
      return {
        ...state,
        loading: false,
        courseModule: action.payload?.data,
        courseModuleCount: action.payload?.count,
      };
    case "GET_COURSE_ORDER_FULFILLED":
      return {
        ...state,
        loading: false,
        courseOrder: action.payload?.data,
        courseCount: action.payload?.count?.count,
      };
    case "GET_COURSE_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        courses: action.payload,
        coursesCount: action.coursesCount,
      };
    case "GET_COURSE_QUIZ_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        courseQuizes: action.payload,
        courseQuizCount: action.courseQuizCount,
      };
    case "GET_COURSE_FULFILLED":
      return {
        ...state,
        loading: false,
        allCourses: action.payload,
      };
    case "GET_COURSE_QUIZ_QUESTION_FULFILLED":
      return {
        ...state,
        loading: false,
        CourseQuizQuestions: action.payload,
        CourseQuizQuestionsCount: action.CourseQuizQuestionsCount,
      };
    case "GET_COURSETYPE_FAILED":
    case "GET_COURSECATEGORY_FAILED":
    case "GET_COURSE_TYPE_LIST_FAILED":
    case "GET_COURSE_SUB_CATEGORY_FAILED":
    case "GET_COURSE_SUB_SUB_CATEGORY_FAILED":
    case "GET_COURSE_SECTION_MODULE_FAILED":
    case "GET_COURSE_SECTION_MODULE_LIST_FAILED":
    case "GET_COURSE_MODULE_FAILED":
    case "GET_COURSE_ORDER_FAILED":
    case "GET_COURSE_LIST_REJECTED":
    case "GET_COURSE_REJECTED":
    case "GET_COURSE_QUIZ_LIST_REJECTED":
    case "GET_COURSE_QUIZ_QUESTION_REJECTED":
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};

export default CourseReducer;
