import React, { useState, useEffect } from 'react'
import { Input, FormGroup, Label, Button } from 'reactstrap'
import { phoneValid } from '../utils'
import { getRoles } from '../redux/actions/indexActions'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify"
const CreateAdmin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { AuthReducer: { auth }, indexReducer: { roles } } = useSelector(state => state)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        role_id: "",
        password: "",
        confirmPassword: ""
    })

    const inputHandler = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = () => {
        axios.post("/api/admin/v1/admin/create", formData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`
            }
        })
            .then((res) => {
                toast.success(`Admin Created Successfully`)
                setFormData({
                    name: "",
                    mobile: "",
                    email: "",
                    role_id: "",
                    password: "",
                    confirmPassword: ""
                })
            }
            )
            .catch((err) => setErrors(err.response.data))
    }

    useEffect(() => {
        dispatch(getRoles(auth?.token))
    }, [auth])

    return (
        <div className='bg-white px-3 py-5 rounded-2  my-4' >
            <div className="row gy-4 ">
                <div className="col-md-8 offset-md-1">

                    <h5>Create Admin User</h5>
                </div>
                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Name
                        </Label>
                        <Input value={formData.name} invalid={Boolean(errors?.name)} onChange={inputHandler} name="name" />
                        <p className='text-danger' >
                            {errors?.name}
                        </p>
                    </FormGroup>
                </div>

                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Email
                        </Label>
                        <Input value={formData.email} invalid={Boolean(errors?.email)} onChange={inputHandler} name="email" />
                        <p className='text-danger' >
                            {errors?.email}
                        </p>
                    </FormGroup>
                </div>

                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Mobile
                        </Label>
                        <Input value={formData.mobile} invalid={Boolean(errors?.mobile)} onChange={(e) => phoneValid(e.target.value) && inputHandler(e)} name="mobile" />
                        <p className='text-danger' >
                            {errors?.mobile}
                        </p>
                    </FormGroup>
                </div>


                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Select Role
                        </Label>
                        <Input type='select' invalid={Boolean(errors?.role)} value={formData.role_id} onChange={inputHandler} name="role_id" >
                            <option value="">Select</option>
                            {
                                roles?.map?.((item) => {
                                    return <option value={item?.id} key={item.id} >{item?.name}</option>
                                })
                            }
                        </Input>
                        <p className='text-danger' >
                            {errors?.role}
                        </p>
                    </FormGroup>
                </div>

                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Password
                        </Label>
                        <Input type='password' invalid={Boolean(errors?.password)} value={formData.password} onChange={inputHandler} name="password" />
                        <p className='text-danger' >
                            {errors?.password}
                        </p>
                    </FormGroup>
                </div>

                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Confirm Password
                        </Label>
                        <Input type='password' invalid={Boolean(errors?.confirmPassword)} value={formData.confirmPassword} onChange={inputHandler} name="confirmPassword" />
                        <p className='text-danger' >
                            {errors?.confirmPassword}
                        </p>
                    </FormGroup>
                </div>
                <div className="col-md-4 offset-md-4">
                    <Button color='primary' onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button className='ms-3' onClick={()=>navigate(-1)} >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CreateAdmin