import React, { useEffect, useState } from 'react'
import { Table, Input, Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupedPermissions, getPermissionByRole } from '../../redux/actions/PermissionAction'
import styled from 'styled-components'
import axios from 'axios'
import { getRoles } from '../../redux/actions/indexActions'
import { toast } from 'react-toastify'
const AssignPermissions = () => {
    const dispatch = useDispatch()
    const { PermissionReducer: { groupedPermission, rolesPermission }, AuthReducer: { auth }, indexReducer: { roles } } = useSelector(state => state)
    const [modules, setModules] = useState([])
    const [allCheck, setAllCheck] = useState([{}])
    const [roleId, setRoleId] = useState("")
    useEffect(() => {
        dispatch(getGroupedPermissions(auth.token))
        dispatch(getRoles(auth.token))
    }, [auth])



    const removeDupes = (arr = [], base = "id") => {
        const exist = {}
        const diff = []
        for (let i = 0; i < arr.length; i++) {
            if (exist[arr[i][base]]) {
                exist[arr[i][base]] += 1
            }
            else {
                exist[arr[i][base]] = 1
                diff.push(arr[i])
            }
        }
        return diff
    }

    const groupItems = (arr = []) => {
        const grouped = []
        const exist = {}
        arr.forEach((item, index) => {
            if (!exist[item.moduleId]) {
                exist[item.moduleId] = 1
                grouped.push({
                    id: index + 1,
                    module: item.moduleId,
                    permissions: arr.filter((it) => it.moduleId === item.moduleId).map((item) => {
                        return { id: item.id, permission: item.permissionId }
                    })
                })
            }
        })
        return grouped
    }

    useEffect(() => {
        if (roleId) {
            dispatch(getPermissionByRole(roleId, auth?.token))
        }
    }, [roleId])

    useEffect(() => {
        setModules(rolesPermission?.map?.((item) => {
            return { id: item.permissionId, module: item.moduleId, roleId: item.roleId }
        }))
        const checked = []
        groupItems(rolesPermission).forEach((item) => {
            groupedPermission.forEach((it) => it?.permissions?.length === item?.permissions?.length && checked.push({ module: item.module }))
        })
        setAllCheck(checked)
    }, [rolesPermission, groupedPermission])






    const handleCheck = (e, id, index = '') => {
        const { name, checked } = e.target
        if (name === "all") {
            allCheck.some((item) => item.module === index) ? setAllCheck(allCheck.filter((item) => item.module !== index)) : setAllCheck([...allCheck, { module: index }])
            if (checked) {
                setModules(prev => removeDupes([...prev, ...id?.map?.((item) => { return { module: index, id: item?.id } })]))
            } else {
                setModules((prev) => prev?.filter((item) => item.module !== index))
            }
        } else {
            if (modules?.some(item => item.id === id)) {
                setModules(prev => prev?.filter((item) => item.id !== id))
                setAllCheck(allCheck.filter((item) => item.module !== index))
            } else {
                setModules(prev => [...prev, { module: index, id }])
                if (([...modules, { module: index, id }]?.filter((it) => it.module === index)?.length === groupedPermission?.find((it) => it.index === index)?.permissions?.length)) {
                    setAllCheck([...allCheck, { module: index, }])
                }
            }
        }
    }

    const handleSubmit = () => {
        const data = modules.map((item) => {
            return { ...item, roleId: parseInt(roleId) }
        })
        axios.post("/api/admin/v1/assign-permission/", {
            'permissions': data
        }, {
            headers: {
                Authorization: `Bearer ${auth?.token}`
            }
        })
            .then((res) => {
                toast.success(res.data?.message)
            })
            .catch(err => toast.error(err.response.data?.message))
    }
    return (
        <MyWrapper>
            <div className='bg-white px-3 py-4 rounded-2  my-4'>
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="form-group mb-4">
                            <Input type='select' value={roleId} onChange={(e) => setRoleId(e.target.value)} >
                                <option value="">Select</option>
                                {
                                    roles?.map?.((item) => {
                                        return <option value={item?.id} key={item.id} > {item?.name} </option>
                                    })
                                }
                            </Input>
                        </div>
                    </div>

                    {roleId && <div className="col-md-12">
                        <div className="bg-primary text-white p-3 rounded-3 mb-3">
                            <h6 className='mb-0' >Module & Activities</h6>
                        </div>
                    </div>}

                    {
                        roleId &&
                        <div className="col-md-12">
                            <Table
                                hover
                                responsive
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            Module
                                        </th>
                                        <th>
                                            All
                                        </th>
                                        <th>
                                            View
                                        </th>
                                        <th>
                                            Add
                                        </th>
                                        <th>
                                            Update
                                        </th>
                                        <th>
                                            Delete
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        groupedPermission.map((item) => {
                                            return <tr key={item.index} >
                                                <td>  {item?.module} </td>
                                                <td> <Input type='checkbox' checked={allCheck.some((it) => it.module === item.index)
                                                } name='all' onChange={(e) => handleCheck(e, item?.permissions, item?.index)} /></td>
                                                {
                                                    item?.permissions?.map?.((it) => {
                                                        return <td key={it.id} > <Input type='checkbox' checked={modules?.some?.((item) => item.id === it?.id)} onChange={(e) => handleCheck(e, it?.id, item?.index)} /> </td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <Button color='primary' onClick={handleSubmit} >Submit</Button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    }
                </div>
            </div>
        </MyWrapper>
    )
}

export default AssignPermissions


const MyWrapper = styled.div`
    
    tr th{
        font-size:.875rem;
        font-weight: 600;
    }
`