import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import { getCategoryType } from "../../redux/actions/ConsultantAction";
import { getCourseSectionModuleList, getCourseTypes } from '../../redux/actions/CourseAction';
import { toBeEmpty, toBeRequired } from "@testing-library/jest-dom/dist/matchers";

const AddCourseSectionModule = () => {
  const [formData, setFormData] = useState({
    courseId: "",
    name: "",
    short_description: "",
    priority: 0,
    pw_s1: 0,
    pw_s2: 0,
    pw_s3: 0,
    status: 1,
  });

  const {
    consultantReducer: { categorytype },

  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const { CourseReducer: { coursetype, courseSectionModuleList },
  } = useSelector((state) => state);

  const [errors, SetErrors] = useState({});


  useEffect(() => {
    dispatch(getCourseSectionModuleList(auth.token));
  }, [auth.token]);

  const inputHandler = (e) => {

    const { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      if (key === "coursecategory") {
        form.append("coursecategory", formData[key]);
      }
      form.append([key], formData[key]);
    }
    // form.append('course_type',formData.coursetype)
    // form.append('name',formData.name)
    // form.append('hindi_name',formData.hindi_name)
    // form.append('short_description',formData.short_description)
    // form.append('image',formData.image)
    // form.append("status",formData.status)
    axios
      .post(`/api/admin/v1/add-course-module-section`, form, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        SetErrors(err.response.data);
      });
  };

  console.log(errors, 'erors');

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Course Module Section</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.courseId}
                type="select"
                name="courseId"
                invalid={Boolean(errors?.courseId)}
                toBeRequired={true}
                onChange={inputHandler}
              >
                <option>Select</option>
                {courseSectionModuleList?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.title}
                    </option>
                  );

                })}
              </Input>
              <span className="d-block text-danger">{errors?.courseId}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label> Name <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.name}
                name="name"
                type="text"
                invalid={errors?.name}
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.name}{" "}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Priority (must be integer)</Label>
              <Input
                value={formData?.priority}
                name="priority"
                type="integer"
                invalid={errors?.priority}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
              <span className="d-block text-danger">
                {errors?.priority}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Pw S1 (must be integer)</Label>
              <Input
                value={formData?.pw_s1}
                name="pw_s1"
                type="integer"
                invalid={errors?.pw_s1}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.pw_s1}{" "}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Pw S2 (must be integer)</Label>
              <Input
                value={formData?.pw_s2}
                name="pw_s2"
                type="integer"
                invalid={errors?.pw_s2}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.pw_s2}{" "}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Pw S3 (must be integer)</Label>
              <Input
                value={formData?.pw_s3}
                name="pw_s3"
                type="integer"
                invalid={errors?.pw_s3}
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.pw_s3}{" "}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Description</Label>
              <Input
                name="short_description"
                value={formData?.short_description}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseSectionModule;
