import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import axios from "axios";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { Table } from "reactstrap";
import useDebounce from "../../hooks/useDebounce"
const Index = () => {
    const {
        AuthReducer: { auth },
    } = useSelector((state) => state);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [usersTopup, setUsersTopup] = useState({ count: 0, users: [] })
    const [filter,setFilter] = useState("")
    const debounced = useDebounce(search)
    useEffect(() => {
        axios(`/api/admin/v1/get-user-topups?limit=${10}&page=${page}&search=${debounced}&filter=${filter}`, {
            headers: {

                Authorization: `Bearer ${auth.token}`
            }
        })
            .then((({ data }) => {
                setUsersTopup({ count: data.count, users: data.getUsers })
            })).catch(err => console.error(err))
    }, [page, auth.token, debounced,filter])
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-3 offset-md-9">
                    <Input
                        value={search}
                        placeholder="Search By Name"
                        onChange={({ target: { value } }) => setSearch(value)}
                    />
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-4 d-flex align-items-center justify-content-between">
                            <h5 className="mb-0"> User TopUps </h5>
                            <div className="col-md-2">
                                <Input type='select' value={filter} onChange={(({target})=>setFilter(target.value))} >                              <option value="">Select</option>
                                <option value="success">Success</option>
                                <option value="pending">Pending</option>
                                <option value="failed">Failed</option>
                                </Input>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>SL. No.</th>
                                    <th>User</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Mobile</th>
                                    <td> Payment ID </td>
                                    <td>Order Id</td>
                                </tr>
                            </thead>
                            <tbody>
                                {usersTopup.users.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{index + 1 + page * 10}</th>
                                            <td>{item.user?.name}</td>

                                            <td>
                                                {item.amount}
                                            </td>
                                            <td>{item.paymentStatus}</td>
                                            <td>
                                                {item.user?.mobile}
                                            </td>
                                            <td>
                                                {
                                                    item.paymentId
                                                }
                                            </td>
                                            <td>
                                                {item.orderId}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Pagination
                    pageCount={usersTopup.count / 10}
                    onChange={(e) => setPage(e.selected)}
                />
            </div>
        </div>
    )
}

export default Index