import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles } from '../../redux/actions/indexActions'
import { Table } from 'reactstrap'
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { MdAdd } from "react-icons/md"
import { Spinner } from "reactstrap"
import { toast } from "react-toastify"
import axios from 'axios'
const Roles = () => {
    const dispatch = useDispatch()
    const { indexReducer: { roles, loading }, AuthReducer: { auth }, PermissionReducer: { allowedPermissions } } = useSelector(state => state)
    useEffect(() => {
        dispatch(getRoles(auth?.token))
    }, [auth?.token])
    const handleDel = (id) => {
        if (window.confirm("Are You Sure You Want To Delete This Role")) {
            axios.delete(`/api/admin/v1/role/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`
                }
            })
                .then((res) => {
                    toast.success(res.data.message)
                    dispatch(getRoles(auth?.token))
                }
                ).catch((err) => toast.error("Somthing Went Wrong"))
        }
    }
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className='mb-0 text-white'>Roles</h5>
                        {
                            allowedPermissions?.some?.((item) => item?.permission?.moduleType === "ROLE" && item?.permission?.permission === "create") &&
                            <Link to="/create-role" >
                                <Button className='bg-white text-black' color='primary' size='sm' >
                                    <span className='me-2' >
                                        <MdAdd size={22} />
                                    </span>
                                    Add Roles
                                </Button>
                            </Link>
                        }

                    </div>
                </div>
            </div>
            <div className='bg-white px-3 py-5 rounded-2  my-4'  >
                <div className="row">
                    <div className="col-md-12">
                        <Table
                            hover
                            responsive
                        >
                            <thead>
                                <tr>
                                    <th>
                                        SL. No.
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? <tr>
                                        <td>
                                            <Spinner />
                                        </td>
                                    </tr> :
                                        roles?.map?.((item, index) => {
                                            return <tr key={item.id}>

                                                <th scope="row">
                                                    {index + 1}
                                                </th>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item?.status ? "Active" : "Inactive"}
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        {/* {
                                                            allowedPermissions?.some?.((item) => item?.permission?.moduleType === "ROLE" && item?.permission?.permission === "edit") &&
                                                            <Link to={`/update-sub-sub-category/${item?.id}`}>
                                                                <AiOutlineEdit size={22} className='text-warning me-3' />
                                                            </Link>
                                                        } */}

                                                        {
                                                            allowedPermissions?.some?.((item) => item?.permission?.moduleType === "ROLE" && item?.permission?.permission === "delete") &&
                                                            <AiOutlineDelete size={22} className='text-danger' onClick={() => handleDel(item.id)} />
                                                        }
                                                    </div>

                                                </td>
                                            </tr>
                                        })



                                }
                            </tbody>

                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roles