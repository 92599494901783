import React, { useEffect, } from 'react'
import { Table, Input, Spinner, Button } from 'reactstrap'
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react';
import { MdAdd } from "react-icons/md"
import { toast } from 'react-toastify';
import Pagination from "../../Components/Paginate";
import { logDOM } from '@testing-library/react'

const AdminUsers = () => {
    const [adminUsers, setAdminUsers] = useState([])
    const [adminUsersCount, setAdminUsersCount] = useState({})
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [paginate, setPaginate] = useState(10);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("desc");
    const { AuthReducer: { auth }, PermissionReducer: { allowedPermissions } } = useSelector(state => state)
    const dispatch = useDispatch();
    const getAdminUsers = () => {
        axios(`/api/admin/v1/get-admin-users?limit=${paginate}&page=${page}&search=${search}&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }).then(({ data }) => {
            setAdminUsers(data.users)
            setAdminUsersCount(data.adminCount?.count)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (auth.token) {
            getAdminUsers(auth?.token, paginate, page, search, sort)
        }
    }, [auth.token, paginate, page, search, sort])

    const handleDel = (id) => {
        if (window.confirm("Are You Sure You Want To Delete This Admin User")) {
            axios
                .delete(`/api/admin/v1/admin/delete/${id}`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                })
                .then((res) => {
                    toast.success(res.data.message);
                    dispatch(getAdminUsers());
                })
                .catch((err) => toast.error("Somthing Went Wrong"));
        }
    };

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className='mb-0 text-white'>Admin Users</h5>
                        {
                            allowedPermissions?.some?.((item) => item?.permission?.moduleType === "ADMINUSERS" && item?.permission?.permission === "create") &&
                            <Link to="/create-user" >
                                <Button className='bg-white text-black' style={{ color: "primary" }} size='sm' >
                                    <span className='me-2' >
                                        <MdAdd size={22} />
                                    </span>
                                    Add Admin User
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
            </div>

            <div className='bg-white px-3 py-4 rounded-2  my-4' style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}  >
                <div className="row">
                    <div className="col-md-3 mb-2 ">
                        <Input
                            size={"sm"}
                            value={search}
                            placeholder="Search By Name"
                            onChange={({ target: { value } }) => setSearch(value)}
                        />
                    </div>
                    <div className="col-md-2 ">
                        <Input
                            type="select"
                            size={"sm"}
                            value={sort}
                            onChange={({ target }) => setSort(target.value)}
                        >
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </Input>
                    </div>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center mx-auto">
                            <Spinner color="primary" />
                        </div>
                    ) : (
                        <div className="col-md-12">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            SL. No.
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Mobile
                                        </th>
                                        <th>
                                            Status
                                        </th>
                                        <th>
                                            Role
                                        </th>
                                        {allowedPermissions?.some?.(
                                            (item) =>
                                                item?.permission?.moduleType === "ADMINUSERS" &&
                                                (item?.permission?.permission === "edit" ||
                                                    item?.permission?.permission === "delete")
                                        ) && (
                                                <th>
                                                    Action
                                                </th>
                                            )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminUsers.map((item, index) => {
                                        return <tr key={item.id}>

                                            <th scope="row">
                                                {index + 1}
                                            </th>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.mobile}
                                            </td>
                                            <td>
                                                {item?.status ? "Active" : "Inactive"}
                                            </td>
                                            <td>
                                                {item.role?.name}
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {allowedPermissions?.some?.(
                                                        (item) =>
                                                            item?.permission?.moduleType === "ADMINUSERS" &&
                                                            item?.permission?.permission === "edit"
                                                    ) && (
                                                            <Link to={`/admin-users/edit/${item?.id}`}>
                                                                <AiOutlineEdit size={22} className='text-warning me-3' />
                                                            </Link>
                                                        )}
                                                    {allowedPermissions?.some?.(
                                                        (item) =>
                                                            item?.permission?.moduleType === "ADMINUSERS" &&
                                                            item?.permission?.permission === "delete"
                                                    ) && (
                                                            <AiOutlineDelete size={22} className='text-danger' onClick={() => handleDel(item.id)} />
                                                        )}
                                                </div>

                                            </td>
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <select
                            id="paginate"
                            onChange={(e) => setPaginate(e.target.value)}
                            value={paginate}
                            className="paging custom-select custom-select-page"
                        >
                            <option value="10" selected>
                                10
                            </option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                    <Pagination
                        pageCount={adminUsersCount / paginate}
                        onChange={(e) => setPage(e.selected)}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminUsers