import React from 'react'

const MeetingReview = () => {
  return (
    <div className='meeting-review-wrapper d-flex justify-content-center align-items-center'  >
      <div className="card">
        <div className="card-body">
          Thanks For Joining
        </div>
      </div>
    </div>
  )
}

export default MeetingReview