import React, { Fragment, useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const ImportQuestion = (props) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAdd, setNewAdd] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState("");
  const handleChange = (e) => {
    setFields(e.target.files[0]);
  };

  const handleClose = () => {
    setModal(false);
    setNewAdd(false);
    setErrors({});
    setFields("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // window.alert(`${window.location.pathname?.split("/").find((item) => parseInt(item))}`);
    setLoading(true);
    console.log(fields, "field");
    const data = new FormData();
    data.append("Quiz", fields);
    data.append(
      "quiz_id",
      `${window.location.pathname?.split("/").find((item) => parseInt(item))}`
    );
    axios
      .post("/api/admin/v1/import-questions", data)
      .then((res) => {
        setLoading(false);
        window.location.reload();
        toast.success(res.data.message);
        setModal(newAdd);
        setNewAdd(false);
        setErrors({});
        setFields("");
        props.addCategory(res.data.category);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          setErrors(err.response.data.errors);
        } else {
          setLoading(false);
          toast.error(err.response.data.message);
        }
      });
  };

  return (
    <Fragment>
      <CardBody>
        <ButtonGroup
          size="sm"
          className="ms-2 pb-4"
          onClick={() => setModal(true)}
        >
          <Button className="btn btn-sm btn-secondary">
            <FaPlus className="btn-i-icon" style={{ marginRight: 5 }} />
            Import Questions
          </Button>
        </ButtonGroup>
      </CardBody>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>
          Import Questions
          <a
            href="/Quiz.xlsx"
            style={{ marginLeft: "170px", color: "blue" }}
            download="SampleSheet.xlsx" // Add download attribute
          >
            Sample Sheet
          </a>
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="file"
                id="file"
                name="file"
                label="Choose File"
                onChange={handleChange}
                invalid={!!errors?.file}
              />
              <FormFeedback>{!!errors.file ? errors.file[0] : ""}</FormFeedback>
            </FormGroup>
            <div className="col-md-4 offset-md-5">
              <Button type="submit" color="primary">
                Save
              </Button>
              <Button className="ms-3" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ImportQuestion;
