import React, { useState, useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { getExpertVideos } from "../../redux/actions/indexActions";
import {
  getCategories,
  getSubCategories,
} from "../../redux/actions/indexActions";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Input } from "reactstrap";
import { AiFillEye } from "react-icons/ai";
import Pagination from "../../Components/Paginate";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";
import { Spinner } from "reactstrap";
const ExpertVideos = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { expertVideos, expertCount, categories, loading },

    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [paginate, setPaginate] = useState(10);
  const [url, setUrl] = useState(false);
  const [modal, setModal] = useState(false);
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subSubSubCategoryFilter, setSubSubCategoryFilter] = useState("");
  const [subCategoriess, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [positions, setPositions] = useState({});
  useEffect(() => {
    dispatch(
      getExpertVideos(
        auth?.token,
        paginate,
        page,
        search,
        sort,
        categoryFilter,
        subCategoryFilter,
        subSubSubCategoryFilter
      )
    );
  }, [
    auth,
    paginate,
    page,
    search,
    sort,
    categoryFilter,
    subCategoryFilter,
    subSubSubCategoryFilter,
  ]);
  useEffect(() => {
    if (categoryFilter) {
      // setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${categoryFilter}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [categoryFilter]);

  useEffect(() => {
    if (subCategoryFilter) {
      axios(`/api/web/v1/get-industry-type/${subCategoryFilter}`)
        .then(({ data }) => {
          setSubSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubSubCategories([]);
    }
  }, [subCategoryFilter]);
  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Expert Video")) {
      axios
        .delete(`/api/admin/v1/expert-videos-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getExpertVideos(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  const handleVideo = (e, url) => {
    e.preventDefault();
    setUrl(url);
    setModal(true);
  };
  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);
  useEffect(() => {
    if (expertVideos) {
      const initialPositions = {};
      expertVideos.forEach(expertVideo => {
        initialPositions[expertVideo.id] = expertVideo.position;
      });
      setPositions(initialPositions);
    }
  }, [expertVideos]);
  const handlePositionCourses = useCallback((id) => {
    console.log(positions);
    axios
      .put(
        `/api/admin/v1/update-expertvideo-position/${id}`,
        { position: parseInt(positions[id]) },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message);
        dispatch(getExpertVideos(
          auth?.token,
          paginate,
          page,
          search,
          sort,
          categoryFilter,
          subCategoryFilter,
          subSubSubCategoryFilter
        ));
      })
      .catch((err) => toast.error(err.response.data?.message));
  }, [auth, dispatch, paginate,
    page,
    search,
    sort,
    categoryFilter,
    subCategoryFilter,
    subSubSubCategoryFilter,positions]);
  const handlePositionChange = (id, value) => {
    setPositions({
      ...positions,
      [id]: value
    });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Expert Videos List</h5>
            {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "EXPERTVIDEOS" &&
              item?.permission?.permission === "create") && (
                <Link to="/add-expertvideos">
                  <Button className="bg-white" color="primary" size="sm">
                    <span className="me-2">
                      <MdAdd size={22} />
                    </span>
                    Add Expert Videos
                  </Button>
                </Link>
              )}
          </div>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-3 mb-2">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={categoryFilter}
              type="select"
              name="categoryId"
              onChange={({ target }) => setCategoryFilter(target.value)}
            >
              <option value="">Select Category</option>
              {categories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.type}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subCategoryFilter}
              type="select"
              name="subCategoryId"
              onChange={({ target }) => setSubCategoryFilter(target.value)}
            >
              <option value="">Select Segment</option>
              {subCategoriess?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subSubSubCategoryFilter}
              type="select"
              name="subSubCategoryId"
              onChange={({ target }) => setSubSubCategoryFilter(target.value)}
            >
              <option value="">Select Expertise</option>
              {subSubCategories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>

          <div className="col-md-2 ">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center mx-auto">
            <Spinner color="primary" />
          </div>
        ) : (
          <div className="col-md-12">
            <Table hover responsive>
              <thead>
                <tr>
                  <th>SL. No.</th>
                  <th>Title</th>
                  <th>url</th>
                  <th>Position</th>
                  <th>Consultant Name</th>
                  {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "EXPERTVIDEOS" &&
                    (item?.permission?.permission === "edit" ||
                      item?.permission?.permission === "delete")
                  ) && (
                      <th>Action</th>
                    )}
                </tr>
              </thead>
              <tbody>
                {expertVideos?.map?.((item, index) => {
                  return (
                    <tr key={item.id}>
                      <th scope="row">{index + 1 + page * 10}</th>
                      <td>{item?.title}</td>

                      <td>
                        {item.url ? (
                          <a
                            role="button"
                            className=""
                            onClick={(e) => handleVideo(e, item.url)}
                          >
                            <AiFillEye />
                          </a>
                        ) : null}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={positions[item.id]}
                          onChange={({ target: { value } }) => handlePositionChange(item.id, value)}
                          onBlur={() => handlePositionCourses(item.id)}
                        />
                      </td>
                      <td>{item?.consultant?.name}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {allowedPermissions?.some?.(
                            (item) =>
                              item?.permission?.moduleType === "EXPERTVIDEOS" &&
                              item?.permission?.permission === "edit"
                          ) && (
                              <Link to={`/expert-videos-update/${item?.id}`}>
                                <AiOutlineEdit
                                  size={22}
                                  className="text-warning me-3"
                                />
                              </Link>
                            )}
                          {allowedPermissions?.some?.(
                            (item) =>
                              item?.permission?.moduleType === "EXPERTVIDEOS" &&
                              item?.permission?.permission === "delete"
                          ) && (
                              <AiOutlineDelete
                                size={22}
                                className="text-danger"
                                onClick={() => handleDel(item.id)}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
          <ModalBody className="p-0">
            <ReactPlayer url={url} playing width="100%" controls={true} />
          </ModalBody>
        </Modal>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {expertCount}
            </div>
          </div>
          <Pagination
            pageCount={expertCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpertVideos;
