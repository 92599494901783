import React from "react";
import Card from "../../Shared/Card";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../Meeting.css"
import { useSelector } from "react-redux"
export const Participant = ({
  curentIndex,
  currentParticipant,
  hideVideo,
  videoRef,
  showAvatar,
  currentUser,
}) => {
  const { isAuthenticated } = useSelector(state => state.AuthReducer)
  if (!currentParticipant) return <></>;
  return (
    <div className={`participant ${hideVideo ? "hide" : ""}`}>
      <Card>
        <video
          ref={videoRef}
          className="video"
          id={`participantVideo${curentIndex}`}
          autoPlay
          playsInline
        ></video>
        {!currentParticipant.audio && (
          <FontAwesomeIcon
            className="muted"
            icon={faMicrophoneSlash}
            title="Muted"
          />
        )}
        {showAvatar && (
          <div
            style={{ background: currentParticipant.avatarColor }}
            className="avatar"
          >
            {currentParticipant.name[0]}
          </div>
        )}
        <div className="name">
          {currentParticipant.name}
          {/* {currentUser ? "(You)" : ""}
          {isAuthenticated && "(Host)"} */}
        </div>
      </Card>
    </div>
  );
}