import React, { useState, useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Spinner, Input } from "reactstrap";
import { getAllCourses } from "../../../src/redux/actions/CourseAction";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { MdAdd } from "react-icons/md";
import Pagination from "../../Components/Paginate";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";
const CourseList = () => {
  const dispatch = useDispatch();
  const { AuthReducer: { auth },
    CourseReducer: { courses, coursesCount },
    indexReducer: { categories, loading },
    PermissionReducer: { allowedPermissions } } = useSelector(state => state)
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState("desc");
  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState("");
  const [positions, setPositions] = useState({});
  useEffect(() => {
    dispatch(getAllCourses(auth?.token, paginate, page, search, sort));
  }, [auth, paginate, page, search, sort]);

  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Course")) {
      axios
        .delete(`/api/admin/v1/course-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getAllCourses(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  const handleVideo = (e, url) => {
    e.preventDefault();
    setUrl(url);
    setModal(true);
  };
  useEffect(() => {
    if (courses) {
      const initialPositions = {};
      courses.forEach(course => {
        initialPositions[course.id] = course.position;
      });
      setPositions(initialPositions);
    }
  }, [courses]);
  const handlePositionCourses = useCallback((id) => {
    axios
      .put(
        `/api/admin/v1/update-course-position/${id}`,
        { position: parseInt(positions[id]) },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message);
        dispatch(getAllCourses(auth?.token, paginate, page, search, sort));
      })
      .catch((err) => toast.error(err.response.data?.message));
  }, [auth, positions, dispatch, paginate, page, search, sort]);
  const handlePositionChange = (id, value) => {
    setPositions({
      ...positions,
      [id]: value
    });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className='mb-0 text-white'>Courses</h5>
            {
              allowedPermissions?.some?.((item) => item?.permission?.moduleType === "COURSELIST" && item?.permission?.permission === "create") && (
                <Link to="/add-course" >
                  <Button className='bg-white text-black' style={{ color: "primary" }} size='sm' >
                    <span className='me-2' >
                      <MdAdd size={22} />
                    </span>
                    Add Course
                  </Button>
                </Link>
              )}

          </div>
        </div>
      </div>
      <div className='bg-white px-3 py-4 rounded-2  my-4' style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}  >
        <div className="row">
          <div className="col-md-3 mb-2 ">
            <Input size={"sm"} value={search} placeholder='Search By Course Title' onChange={(({ target: { value } }) => setSearch(value))} />
          </div>
          <div className="col-md-2 offset-md-7">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>

          <div className="col-md-12">
            <Table
              hover
              responsive
            >
              <thead>
                <tr>
                  <th>
                    SL. No.
                  </th>
                  <th>
                    Title
                  </th>
                  <th>
                    Thumbnail
                  </th>
                  <th>
                    Hindi Thumbnail
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Position
                  </th>
                  <th>
                    Url
                  </th>
                  <th>
                    Hindi Url
                  </th>
                  {
                    allowedPermissions?.some?.((item) => item?.permission?.moduleType === "COURSELIST" && (item?.permission?.permission === "edit" || item?.permission?.permission === "delete")) &&
                    (<th>
                      Action
                    </th>)
                  }
                </tr>
              </thead>
              {
                loading ? <Spinner /> : <tbody>
                  {
                    courses?.map?.((item, index) => {
                      return <tr key={item.id}>

                        <th scope="row">
                          {(index + 1) + (page * 10)}
                        </th>
                        <td>
                          {item?.title} <br />{item?.hindi_title}
                        </td>
                        <td>
                          {item?.thumbnail ? (
                            <img
                              src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com${item?.thumbnail?.startsWith("/") ? item?.thumbnail : `/${item?.thumbnail}`}`}
                              width={
                                100
                              }
                            />
                          ) : (
                            "Null"
                          )}
                        </td>
                        <td>
                          {item?.thumbnail_hindi ? (
                            <img
                              src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com${item?.thumbnail_hindi?.startsWith("/") ? item?.thumbnail_hindi : `/${item?.thumbnail_hindi}`}`}
                              width={
                                100
                              }
                            />
                          ) : (
                            "Null"
                          )}
                        </td>
                        <td>
                          <span
                            className={`badge ${item?.type ===
                              "Free"
                              ? "badge-success"
                              : "badge-danger"
                              }`}
                          >
                            {item?.type ===
                              "Free"
                              ? "Free"
                              : "Paid"}
                          </span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={positions[item.id]}
                            onChange={({ target: { value } }) => handlePositionChange(item.id, value)}
                            onBlur={() => handlePositionCourses(item.id)}
                          />
                        </td>
                        <td>
                          {item.url ? (
                            <div className="custom-btn-abso">
                              <a
                                role="button"
                                className=""
                                onClick={e =>
                                  handleVideo(
                                    e,
                                    item.url
                                  )
                                }
                              >
                                View
                                Video
                              </a>
                            </div>
                          ) : null}
                        </td>
                        <td>
                          {item.hindi_url ? (
                            <div className="custom-btn-abso">
                              <a
                                role="button"
                                className=""
                                onClick={e =>
                                  handleVideo(
                                    e,
                                    item.hindi_url
                                  )
                                }
                              >
                                View
                                Video
                              </a>
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {
                              allowedPermissions?.some?.((item) => item?.permission?.moduleType === "COURSELIST" && item?.permission?.permission === "edit") &&
                              (<Link to={`/course-edit/${item?.id}`}>
                                <AiOutlineEdit size={22} className='text-warning me-3' />
                              </Link>)
                            }
                            {
                              allowedPermissions?.some?.((item) => item?.permission?.moduleType === "COURSELIST" && item?.permission?.permission === "delete") &&
                              <AiOutlineDelete size={22} className='text-danger' onClick={() => handleDel(item.id)} />
                            }
                          </div>

                        </td>
                      </tr>
                    })
                  }


                </tbody>
              }

            </Table>
          </div>
        </div>
        <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
          <ModalBody className="p-0">
            <ReactPlayer
              url={url}
              playing
              width="100%"
              controls={true}
            />
          </ModalBody>
        </Modal>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) =>
                setPaginate(
                  e.target.value
                )
              }
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">
                20
              </option>
              <option value="50">
                50
              </option>
              <option value="100">
                100
              </option>
              <option value="200">
                200
              </option>
              <option value="500">
                500
              </option>
            </select>
          </div>
          <Pagination pageCount={coursesCount / paginate} onChange={(e) => setPage(e.selected)} />
        </div>
      </div>
    </div>
  );
};

export default CourseList;
