import React, { useEffect, useState } from 'react'
import { Table, Modal, ModalBody, Input, FormGroup, Label, ModalHeader, ModalFooter } from 'reactstrap'
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { MdAdd } from "react-icons/md"
import { Spinner } from "reactstrap"
import { toast } from "react-toastify"
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getPermissions } from '../../redux/actions/PermissionAction'
import Pagination from '../../Components/Paginate'
const Index = () => {
  const { AuthReducer: { auth }, PermissionReducer: { permissions, permissionsCount, loading, allowedPermissions } } = useSelector(state => state)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState({
    moduleType: "",
    permissionType: ""
  })
  const [update, setUpdate] = useState(0)
  const [errors, setErrors] = useState({})
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(10);
  const toggle = () => {
    setErrors({})
    setUpdate(0)
    setIsOpen(prev => !prev)
  }
  useEffect(() => {
    dispatch(getPermissions(auth.token, paginate, search, page, sort))
  }, [auth.token, paginate, search, page, sort])

  const inputHanlder = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }
  const handleSubmit = () => {
    axios.post("/api/admin/v1/add-permissions", formData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`
      }
    })
      .then((res) => {
        toast.success(res.data?.message)
        toggle()
        setFormData({
          moduleType: "",
          permissionType: ""
        })
        dispatch(getPermissions(auth.token))
      }).catch((err) => {
        toast.error(err.response.data?.message)
        setErrors(err.response.data)
      })
  }

  const handleUpdate = () => {
    axios.put(`/api/admin/v1/update-permissions/${update}`, formData, {
      headers: {
        Authorization: `Bearer ${auth.token}`
      }
    })
      .then((res) => {
        toast.success(res.data?.message)
        toggle()
        setFormData({
          moduleType: "",
          permissionType: ""
        })
        setUpdate(0)
        dispatch(getPermissions(auth.token))
      }).catch((err) => {
        toast.error(err.response.data?.message)
        setErrors(err.response.data)
      })
  }

  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Permission")) {
      axios
        .delete(`/api/admin/v1/permission-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getPermissions(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  return (
    <div>
      <div className='row my-3'>
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className='mb-0 text-white'>Permissions</h5>


            {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "PERMISSIONS" && item?.permission?.permission === "create") &&
              <Button className='bg-white text-black' color='primary' size='sm' onClick={() => {
                toggle()
                setFormData({
                  moduleType: '',
                  permissionType: ""
                })
              }} >
                <span className='me-2' >
                  <MdAdd size={22} />
                </span>
                Add Permission
              </Button>}


          </div>
        </div>
      </div>
      <div className='bg-white px-3 py-4 rounded-2  my-4' >
        <div className="row">
          <div className="col-md-3 mb-2">
            <Input size={'sm'}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          <div className="col-md-2 offset-md-7 mb-2">
            <Input type="select" size={"sm"} value={sort} onChange={(({ target }) => setSort(target.value))} >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          <div className="col-md-12">
            <Table
              hover
              responsive
            >
              <thead>
                <tr>
                  <th>
                    SL. No.
                  </th>
                  <th>
                    Module  Name
                  </th>
                  <th>
                    Permission
                  </th>

                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ? <tr>
                    <td>
                      <Spinner />
                    </td>
                  </tr> :
                    permissions?.map?.((item, index) => {
                      return <tr key={item.id}>

                        <th scope="row">
                          {index + 1}
                        </th>
                        <td>
                          {item?.moduleType}
                        </td>
                        <td>
                          {item?.permission}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {
                              allowedPermissions?.some?.((item) => item?.permission?.moduleType === "PERMISSIONS" && item?.permission?.permission === "edit") &&
                              <span role="button" onClick={() => {
                                setFormData({
                                  ...formData,
                                  moduleType: item?.moduleType,
                                  permissionType: item?.permission
                                })
                                toggle()
                                setUpdate(item.id)
                              }}>
                                <AiOutlineEdit size={22} className='text-warning me-3' />
                              </span>
                            }
                            {
                              allowedPermissions?.some?.((item) => item?.permission?.moduleType === "PERMISSIONS" && item?.permission?.permission === "delete") &&
                              <AiOutlineDelete size={22} className='text-danger' onClick={() => handleDel(item.id)} />
                            }
                          </div>
                        </td>
                      </tr>
                    })



                }
              </tbody>

            </Table>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">

            <select
              id="paginate"
              onChange={e =>
                setPaginate(
                  e.target.value
                )
              }
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10">
                10
              </option>
              <option value="20">
                20
              </option>
              <option value="50">
                50
              </option>
              <option value="100">
                100
              </option>
              <option value="200">
                200
              </option>
              <option value="500">
                500
              </option>
            </select>
          </div>
          <Pagination
            pageCount={permissionsCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>

        <Modal isOpen={isOpen} toggle={toggle} >
          <ModalHeader toggle={toggle} >
            <h5>{update ? "Update" : "Add"} Permission</h5>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Module Name
              </Label>
              <Input name='moduleType' invalid={Boolean(errors?.moduleType)} value={formData.moduleType} onChange={inputHanlder} />
              <span className="d-block text-danger">
                {errors?.moduleType}
              </span>
            </FormGroup>

            <FormGroup>
              <Label>
                Module Permission
              </Label>
              <Input name='permissionType' invalid={Boolean(errors?.permissionType)} onChange={inputHanlder} value={formData.permissionType} />
              <span className="d-block text-danger">
                {errors?.permissionType}
              </span>
            </FormGroup>
          </ModalBody>

          <ModalFooter className='d-flex align-items-center justify-content-start' >
            <Button className='me-3' onClick={() => update ? handleUpdate() : handleSubmit()} color='primary'>
              Submit
            </Button>
            <Button onClick={toggle} >
              Cancel
            </Button>
          </ModalFooter>


        </Modal>
      </div>
    </div>
  )
}

export default Index