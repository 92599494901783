import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { getCategories } from "../../redux/actions/indexActions";
import { getCategoryType } from "../../redux/actions/ConsultantAction";
import Multiselect from "multiselect-react-dropdown";
const UpdateSubCategory = () => {
  const { id } = useParams();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { categories },
    consultantReducer: { categorytype },
  } = useSelector((state) => state);
  const [error, setErrors] = useState({});
  const [formData, setFormData] = useState({
    consultanttype: [],
    name_english: "",
    name_hindi: "",
    categoryId: "",
    title_english: "",
    title_hindi: "",
    description_english: "",
    short_description_english: "",
    description_hindi: "",
    short_description_hindi: "",
    status: 1,
    avatar_hindi: "",
    avatar_english: "",
    featured: 0,
  });
  const navigate = useNavigate();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "avatar") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    dispatch(getCategoryType(auth.token));
  }, [auth.token]);

  useEffect(() => {
    dispatch(getCategories(auth.token, limit, page, search));
  }, [auth, limit, page, search]);

  useEffect(() => {
    if (id) {
      axios(`/api/admin/v1/sub/category/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then((res) => {
          console.log(res.data?.subCategory)
          setFormData((prev) => {
            return {
              ...prev,
              consultanttype: categorytype.filter(
                (item) => item.id == res.data?.subCategory?.category_type_id
              ),
              name_english: res.data?.subCategory?.name_english,
              name_hindi: res.data?.subCategory?.name_hindi,
              categoryId: res.data?.subCategory?.categoryId,
              title_english: res.data?.subCategory?.title_english,
              title_hindi: res.data?.subCategory?.title_hindi,
              description_english: res.data?.subCategory?.description_english,
              short_description_english:
                res.data?.subCategory?.short_description_english,
              description_hindi: res.data?.subCategory?.description_hindi,
              short_description_hindi:
                res.data?.subCategory?.short_description_hindi,

              status: res.data?.subCategory?.status,
              featured: res.data?.subCategory?.featured != null ? res.data?.subCategory?.featured :  0,
            };
          });
        })
        .catch((err) => console.log(err));
    }
  }, [id, auth, categorytype]);

  const handleSubmit = () => {
    const form = new FormData();
    const consultantType = JSON.stringify(formData.consultanttype);
    for (const key in formData) {
      if (key !== "consultanttype") {
        form.append([key], formData[key]);
      }
    }
    form.append("categoryType", consultantType);
    axios
      .put(`/api/admin/v1/sub/category/${id}`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data);
      });
  };
console.log(formData)
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Edit Segment</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-8 offset-md-1">
            <h4 className="text-start mb-5">Update Segment</h4>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Category Type</Label>
              <Multiselect
                className="text-black"
                options={categorytype}
                displayValue="type"
                selectedValues={formData.consultanttype}
                onSelect={(val) =>
                  setFormData({ ...formData, consultanttype: [...val] })
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title English<span className="text-danger"> *</span>
              </Label>
              <Input
                value={formData?.title_english}
                name="title_english"
                onChange={inputHandler}
                invalid={error?.title_english}
              />
            </FormGroup>
            <p className="text-danger">{error?.title_english}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Title Hindi</Label>
              <Input
                value={formData?.title_hindi}
                name="title_hindi"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                English Name<span className="text-danger"> *</span>
              </Label>
              <Input
                value={formData?.name_english}
                name="name_english"
                onChange={inputHandler}
                invalid={error?.name_english}
              />
            </FormGroup>
            <p className="text-danger">{error?.name_english}</p>
          </div>

          {/* <div className="col-md-4 offset-md-1">
          <FormGroup>
            <Label>Select Category</Label>
            <Input
              value={formData?.categoryId}
              type="select"
              name="categoryId"
              onChange={inputHandler}
            >
              <option value="">Select</option>
              {categories.map((item) => {
                return <option value={item.id}>{item?.name}</option>;
              })}
            </Input>
          </FormGroup>
        </div> */}

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Name</Label>
              <Input
                value={formData?.name_hindi}
                name="name_hindi"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Hindi Description</Label>
              <Input
                name="short_description_hindi"
                value={formData?.short_description_hindi}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Thumbnail Hindi</Label>
              <Input
                type="file"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    avatar_hindi: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Thumbnail English<span className="text-danger"> *</span>
              </Label>
              <Input
                type="file"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    avatar_english: e.target.files[0],
                  }))
                }
                invalid={error?.avatar_english}
              />
            </FormGroup>
            <p className="text-danger">{error.avatar_english}</p>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Short English Description<span className="text-danger"> *</span>
              </Label>
              <Input
                name="short_description_english"
                value={formData?.short_description_english}
                type="textarea"
                invalid={error.short_description_english}
                onChange={inputHandler}
              />
            </FormGroup>
            <p className="text-danger">{error.short_description_english}</p>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>English Full Description</Label>
              <Input
                value={formData?.description_english}
                name="description_english"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Full Description</Label>
              <Input
                value={formData?.description_hindi}
                name="description_hindi"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Featured</Label>
              <Input
               type="select"
                value={formData?.featured}
                name="featured"
                onChange={inputHandler}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSubCategory;
