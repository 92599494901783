import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditSeo = () => {
    const { id } = useParams();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        title: "",
        url: "",
        keywords: "",
        description: "",
        status: "",
    });
    const {
        AuthReducer: { auth }
    } = useSelector((state) => state);
    const navigate = useNavigate();

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = () => {
        const form = new FormData();
        form.append('title', formData.title);
        form.append('url', formData.url);
        form.append('keywords', formData.keywords);
        form.append('description', formData.description);
        form.append('status', formData.status);
        axios
            .put(`/api/admin/v1/update-seo/${id}`, form, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((res) => {
                toast.success(res.data?.message);
                navigate(-1);
            })
            .catch((err) => {
                toast.error(err.response.data);
                setErrors(err.response.data);
            });
    };

    useEffect(() => {
        axios(`/api/admin/v1/edit-seo/${id}`, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        })
            .then((res) => {
                setFormData({
                    title: res.data?.seo?.title,
                    url: res.data?.seo?.url,
                    keywords: res.data?.seo?.keywords,
                    description: res.data?.seo?.description,
                    status: res.data?.seo?.status,
                });
            })
            .catch((err) => console.log(err));
    }, [id, auth]);



    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h4 className="text-white">Edit Seo</h4>
                </div>
            </div>
            <div className="bg-white px-3 py-3 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Title <span className="text-danger fs-4">*</span></Label>
                            <Input
                                invalid={Boolean(errors?.title)}
                                value={formData?.title}
                                name="title"
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger"> {errors?.title} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>URL <span className="text-danger fs-4">*</span></Label>
                            <Input
                                invalid={Boolean(errors?.url)}
                                value={formData?.url}
                                name="url"
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger"> {errors?.url} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Keywords <span className="text-danger fs-4">*</span></Label>
                            <Input
                                invalid={Boolean(errors?.keywords)}
                                value={formData?.keywords}
                                name="keywords"
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger"> {errors?.keywords} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Description <span className="text-danger fs-4">*</span></Label>
                            <Input
                                type="textarea"
                                invalid={Boolean(errors?.description)}
                                value={formData?.description}
                                name="description"
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger"> {errors?.description} </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                onChange={inputHandler}
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSeo;
