import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import {
  getSubCategories,
  getCategories,
} from "../../redux/actions/indexActions";
import { toast } from "react-toastify";
const AddOffer = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    subCategoryId: "",
    subSubCategoryId: "",
    consultantId: "",
    title: "",
    status: 1,
    amount: "",
    type: "",
    thumbnail: "",
  });
  const [errors, setErrors] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const {
    AuthReducer: { auth },
    indexReducer: { categories },
    consultantReducer: { categorytype },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "thumbnail") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      if (key === "categoryId") {
        form.append("categoryTypeId", formData[key]);
      }
      form.append([key], formData[key]);
    }
    axios
      .post(`/api/admin/v1/add-offer`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data.errors);
      });
  };

  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);

  useEffect(() => {
    if (formData.categoryId) {
      axios(`/api/web/v1/get-sectorBy-type/${formData.categoryId}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [formData.categoryId]);

  useEffect(() => {
    if (formData.subCategoryId) {
      axios(`/api/web/v1/get-industry-type/${formData.subCategoryId}`)
        .then(({ data }) => {
          setSubSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubSubCategories([]);
    }
  }, [formData.subCategoryId]);
  console.log(errors?.type, "errors");
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Offer</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title<span className="text-danger"> *</span>
              </Label>
              <Input
                invalid={Boolean(errors?.title)}
                value={formData?.title}
                name="title"
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.title} </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Select Type<span className="text-danger"> *</span>
              </Label>
              <Input
                invalid={Boolean(errors?.type)}
                value={formData?.type}
                type="select"
                name="type"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                <option value="Free">Free</option>
                <option value="Paid">Paid</option>
              </Input>
              <span className="d-block text-danger"> {errors?.type} </span>
            </FormGroup>
          </div>

          {/* <div className="col-md-4 offset-md-1">
          <FormGroup>
            <Label>Amount</Label>
            <Input
              value={formData?.amount}
              invalid={Boolean(errors?.amount)}
              name="amount"
              onChange={inputHandler}
            />
            <span className="d-block text-danger">{errors?.amount}</span>
          </FormGroup>
        </div> */}

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Upload Thumbnail</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.thumbnail)}
                name="thumbnail"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">{errors?.thumbnail}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Select Category</Label>
              <Input
                value={formData?.categoryId}
                type="select"
                name="categoryId"
                onChange={inputHandler}
                invalid={Boolean(errors?.categoryId)}
              >
                <option value="">Select</option>
                {categories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.type}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{errors?.categoryId}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Select Segment</Label>
              <Input
                invalid={Boolean(errors?.subCategoryId)}
                value={formData?.subCategoryId}
                type="select"
                name="subCategoryId"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {subCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name_english}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">
                {errors?.subCategoryId}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Select Expertise</Label>
              <Input
                invalid={Boolean(errors?.subSubCategoryId)}
                value={formData?.subSubCategoryId}
                type="select"
                name="subSubCategoryId"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {subSubCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name_english}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">
                {errors?.subSubCategoryId}
              </span>
            </FormGroup>
          </div>

          {formData.type == "Paid" ? (
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Amount</Label>
                <Input name="amount" onChange={inputHandler} />
              </FormGroup>
            </div>
          ) : null}

          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOffer;
