import { toast } from "react-toastify";

const initialState = {
  roles: [],
  loading: false,
  errors: {},
  services: [],
  categories: [],
  subCategories: [],
  subSubCategories: [],
  serviceSubCategories: [],
  servicesCategories: [],
  serviceSubCategories: [],
  expertVideos: [],
  offerlists: [],
  bannerlists: [],
  seolists: [],
  memberships: [],
  lockerDocs: [],
  userServiceDetails: [],
  enquiries: [],
  subCategoryCount: 0,
  subSubCategoryCount: 0,
  serviceSubCategoriesCount: 0,
  categoriesCount: 0,
  servicesCount: 0,
  expertCount: 0,
  offerCount: 0,
  bannerCount: 0,
  enquiriesCount: 0,
  seoCount: 0
};


const indexReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROLES_PENDING":
    case "GET_SERVICES_PENDING":
    case "GET_CATEGORIES_PENDING":
    case "GET_SUBCATEGORIES_PENDING":
    case "GET_SUB_SUB_CATEGORIS_PENDING":
    case "GET_EXPERTVIDEOS_PENDING":
    case "GET_MEMBERSHIP_LIST_PENDING":
    case "GET_SERVICE_USER_DETAIL_PENDING":
    case "GET_SERVICES_CATEGORY_PENDING":
    case "GET_SERVICES_SUBCATEGORY_PENDING":
    case "GET_ENQUIRIES_PENDING":
    case "GET_LOCKER_DETAIL_PENDING":
      return { ...state, loading: true };
    case "GET_OFFERLIST_PENDING":
      return { ...state, loading: true };
    case "GET_BANNERLIST_PENDING":
      return { ...state, loading: true };
    case "GET_SEO_LIST_PENDING":
      return { ...state, loading: true };

    case "GET_ROLES_FULFILLED":
      return { ...state, loading: false, roles: action.payload };

    case "GET_CATEGORIES_FULFILLED":
      return {
        ...state,
        categories: action.payload,
        loading: false,
        categoriesCount: action.categoriesCount,
      };

    case "GET_SERVICES_FULFILLED":
      return {
        ...state,
        services: action.payload,
        loading: false,
        servicesCount: action.servicesCount,
      };
    case "GET_SERVICES_CATEGORY_FULFILLED":
      return {
        ...state,
        servicesCategories: action.payload,
        loading: false,
      };
    case "GET_SERVICES_SUBCATEGORY_FULFILLED":
      return {
        ...state,
        serviceSubCategories: action.payload,
        loading: false,
      };

    case "GET_SUBCATEGORIES_FULFILLED":
      return {
        ...state,
        subCategories: action.payload,
        loading: false,
        subCategoryCount: action.subCategoryCount,
      };

    case "GET_SUB_SUB_CATEGORIS_FULFILLED":
      return {
        ...state,
        loading: false,
        subSubCategories: action.payload,
        subSubCategoryCount: action.subSubCategorycount,
      };
    case "GET_EXPERTVIDEOS_FULFILLED":
      return {
        ...state,
        loading: false,
        expertVideos: action.payload,
        expertCount: action.expertVideosCount,
      };
    case "GET_OFFERLIST_FULFILLED":
      return {
        ...state,
        loading: false,
        offerlists: action.payload,
        offerCount: action.offerCount,

      };
    case "GET_BANNERLIST_FULFILLED":
      return {
        ...state,
        loading: false,
        bannerlists: action.payload,
        bannerCount: action.bannerCount,

      };
    case "GET_SEO_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        seolists: action.payload,
        seoCount: action.seoCount,

      };

    case "GET_MEMBERSHIP_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        memberships: action.payload,

      };

    case "GET_LOCKER_DETAIL_FULFILLED":
      return {
        ...state,
        loading: false,
        lockerDocs: action.payload,

      };
    case "GET_SERVICE_USER_DETAIL_FULFILLED":
      return {
        ...state,
        loading: false,
        userServiceDetails: action.payload,

      };
    case "GET_SERVICE_SUB_CATEGORIS_FULFILLED":
      return {
        ...state,
        loading: false,
        serviceSubCategories: action.payload,
        serviceSubCategoriesCount: action.serviceSubCategoriescount,
      };
    case "GET_ENQUIRIES_FULFILLED":
      return {
        ...state,
        loading: false,
        enquiries: action.payload,
        enquiriesCount: action.enquiriesCount,
      };

    case "GET_ROLES_FAILED":
    case "GET_CATEGORIES_FAILED":
    case "GET_SUBCATEGORIES_FAILED":
    case "GET_SUB_SUB_CATEGORIS_REJECTED":
    case "GET_SERVICE_SUB_CATEGORIS_REJECTED":
    case "GET_EXPERTVIDEOS_FAILED":
    case "GET_OFFERLIST_FAILED":
    case "GET_BANNERLIST_FAILED":
    case "GET_SEO_LIST_FAILED":
    case "GET_SERVICES_FAILED":
    case "GET_SERVICES_CATEGORY_FAILED":
    case "GET_SERVICES_SUBCATEGORY_FAILED":
    case "GET_ENQUIRIES_FAILED":
    case "GET_SERVICE_USER_DETAIL_FAILED":
    case "GET_MEMBERSHIP_LIST_REJECTED":
    case "GET_LOCKER_DETAIL_FAILED":
      toast.error(action.payload.message);
      return { ...state, loading: false, errors: action.payload };
    default:
      return state;
  }
};

export default indexReducer;
