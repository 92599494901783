import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button, FormFeedback } from "reactstrap";
import { getMemberships } from "../../redux/actions/indexActions";
import { MdAdd } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";

const AddMemberShip = () => {
  const [error, setErrors] = useState({});
  const [feature, setFeature] = useState([]);
  const [term_condition, setTerm_condition] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    amount: "",
    membership_id: "",
    description: "",
    term_condition: "",
    feature: "",
    status: 1,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
    indexReducer: { memberships }
  } = useSelector((state) => state);
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    dispatch(getMemberships(auth?.token))
  }, [auth?.token])

  const handleTermCondition = e => {
    e.preventDefault();
    setTerm_condition(prev => [...prev, formData.term_condition]);
    setFormData(
      {
        ...formData, term_condition: ''
      }
    )
    document.getElementById("term_condition").value = "";
  };

  const handleRemoveTermCondition = (e, i) => {
    e.preventDefault();
    setTerm_condition(term_condition.filter((arr, row) => row !== i));
  };

  const handleFeature = e => {
    e.preventDefault();
    setFeature(prev => [...prev, formData.feature]);
    setFormData(
      {
        ...formData, feature: ''
      }
    )
    document.getElementById("feature").value = "";
  };

  const handleRemoveFeature = (e, i) => {
    e.preventDefault();
    setFeature(feature.filter((arr, row) => row !== i));
  };

  const handleSubmit = () => {
    const form = new FormData();
    form.append("title", formData.title);
    form.append("membership_id", formData.membership_id);
    form.append("amount", formData.amount);
    form.append("description", formData.description);
    form.append("term_condition", JSON.stringify(term_condition));
    form.append("feature", JSON.stringify(feature));
    form.append("status", formData.status);
    axios
      .post(`/api/admin/v1/add-membership-scope`, form, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data);
      });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add MemberShip Scope</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title<span className="text-danger"> *</span>
              </Label>
              <Input
                value={formData?.title}
                name="title"
                invalid={error?.title}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.title}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Amount<span className="text-danger">*</span></Label>
              <Input
                value={formData?.amount}
                name="amount"
                invalid={error?.amount}
                onChange={inputHandler}
              />
              <p className="text-danger">{error?.amount}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Membership <span className="text-danger">*</span>
              </Label>
              <Input
                name="membership_id"
                value={formData?.membership_id}
                type="select"
                onChange={inputHandler}
                invalid={error?.membership_id}
              >
                <option>Select Membership</option>
                {memberships.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
              <p className="text-danger">{error?.membership_id}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Description</Label>
              <Input
                value={formData?.description}
                name="description"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Term & Conditions
              </Label>
              <div className="d-flex">
                <Input
                  id="term_condition"
                  type="text"
                  name="term_condition"
                  className="me-3"
                  invalid={error?.term_condition}
                  value={formData.term_condition}
                  onChange={e =>
                    setFormData(
                      {
                        ...formData, term_condition: e.target.value
                      }
                    )
                  }
                />
                <a
                  size="sm"
                  className="btn btn-secondary cursor-pointer text-white text-center"
                  onClick={
                    handleTermCondition
                  }
                >
                  <MdAdd />
                </a>
              </div>

            </FormGroup>
            {term_condition.length ? (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>
                          Sno.
                        </th>
                        <th>
                          Term Condition
                        </th>
                        <th>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {term_condition.map(
                        (
                          trm,
                          i
                        ) => (
                          <tr
                            key={Math.random()}
                          >
                            <td>
                              {i +
                                1}
                            </td>
                            <td>
                              {
                                trm
                              }
                            </td>
                            <td>
                              <a
                                className="cursor-pointer"
                                onClick={e =>
                                  handleRemoveTermCondition(
                                    e,
                                    i
                                  )
                                }
                              >
                                <AiOutlineDelete />
                              </a>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Feature
              </Label>
              <div className="d-flex">
                <Input
                  id="feature"
                  type="text"
                  name="feature"
                  className="me-3"
                  invalid={error?.feature}
                  value={formData.feature}
                  onChange={e =>
                    setFormData(
                      {
                        ...formData, feature: e.target.value
                      }
                    )
                  }
                />
                <a
                  size="sm"
                  className="btn btn-secondary cursor-pointer text-white text-center"
                  onClick={
                    handleFeature
                  }
                >
                  <MdAdd />
                </a>
              </div>
            </FormGroup>
            {feature.length ? (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>
                          Sno.
                        </th>
                        <th>
                          Course Tag
                        </th>
                        <th>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {feature.map(
                        (
                          trm,
                          i
                        ) => (
                          <tr
                            key={Math.random()}
                          >
                            <td>
                              {i +
                                1}
                            </td>
                            <td>
                              {
                                trm
                              }
                            </td>
                            <td>
                              <a
                                className="cursor-pointer"
                                onClick={e =>
                                  handleRemoveFeature(
                                    e,
                                    i
                                  )
                                }
                              >
                                <AiOutlineDelete />
                              </a>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMemberShip;
