import { toast } from "react-toastify";

const initialState = {
    loading: false,
    errors: {},
    memberShips: [],
    memberShipRegistration: [],
    memberShipsCount: 0,
    MemberShipRegistrationCount: 0
};

const MemberShipReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_MEMBERSHIPS_PENDING":
        case "GET_MEMBERSHIP_SCOPE_PENDING":
        case "GET_MEMBERSHIP_REPORT_PENDING":
            return { ...state, loading: false };
        case "GET_MEMBERSHIPS_FULFILLED":
        case "GET_MEMBERSHIP_SCOPE_FULFILLED":
            return {
                ...state,
                memberShips: action.payload,
                loading: false,
                memberShipsCount: action.memberShipsCount,
            };
        case "GET_MEMBERSHIP_REPORT_FULFILLED":
            return {
                ...state,
                memberShipRegistration: action.payload,
                loading: false,
                MemberShipRegistrationCount: action.MemberShipRegistrationCount,
            };

        case "GET_MEMBERSHIPS_FAILED":
        case "GET_MEMBERSHIP_SCOPE_FAILED":
        case "GET_MEMBERSHIP_REPORT_FAILED":
            toast.error(action.payload.message);
            return { ...state, loading: false, errors: action.payload };
        default:
            return state;
    }
};

export default MemberShipReducer;