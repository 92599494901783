import React, { useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import {} from "../../redux/actions/indexActions";
import { toast } from "react-toastify";
const AddBanner = () => {
  const [formData, setFormData] = useState({
    title: "",
    status: 1,
    thumbnail: "",
  });
  const [errors, setErrors] = useState({});
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "thumbnail") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      form.append([key], formData[key]);
    }
    axios
      .post(`/api/admin/v1/add-Banner`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data.errors);
      });
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h4 className="text-white">Add Banner</h4>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Title</Label>
              <Input
                invalid={Boolean(errors?.title)}
                value={formData?.title}
                name="title"
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.title} </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Upload Thumbnail</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.thumbnail)}
                name="thumbnail"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">{errors?.thumbnail}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
