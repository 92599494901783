import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import { getCategoryType } from "../../redux/actions/ConsultantAction";
import { getCourseTypes } from "../../redux/actions/CourseAction";
import {
  toBeEmpty,
  toBeRequired,
} from "@testing-library/jest-dom/dist/matchers";

const AddCourseCategory = () => {
  const [formData, setFormData] = useState({
    course_type: "",
    name: "",
    hindi_name: "",
    short_description: "",
    image: "",
    status: 1,
  });

  const {
    consultantReducer: { categorytype },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const {
    CourseReducer: { coursetype },
  } = useSelector((state) => state);

  const [errors, SetErrors] = useState({});

  useEffect(() => {
    dispatch(getCourseTypes(auth.token));
  }, [auth.token]);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      form.append([key], formData[key]);
    }
    axios
      .post(`/api/admin/v1/add-course-category`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        SetErrors(err.response.data.errors);
      });
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Course Category</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Course Type <span className="text-danger fs-4"> *</span>
              </Label>
              <Input
                value={formData?.course_type}
                type="select"
                name="course_type"
                invalid={Boolean(errors?.course_type)}
                toBeRequired={true}
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {coursetype?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.course_type}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{errors?.course_type}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label> English Name <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.name}
                name="name"
                type="text"
                invalid={errors?.name}
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.name} </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Name</Label>
              <Input
                value={formData?.hindi_name}
                name="hindi_name"
                type="text"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Description</Label>
              <Input
                name="short_description"
                value={formData?.short_description}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Upload Picture <span className="text-danger fs-4">*</span></Label>
              <Input
                type="file"
                name="image"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    image: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger"> {errors?.image} </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseCategory;
