import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMainStream, updateUser } from "../../redux/actions/MeetingAction";
import MeetingFooter from "./MeetingFooter/MeetingFooter";
import Participants from "./Participants/Participants";
import "./Meeting.css";
import Chat from "./Chat/Chat";
const MainScreen = () => {
  const dispatch = useDispatch();
  const { mainStream, participants, currentUser } = useSelector(
    (state) => state.meetingReducer
  );

  const [active, setActive] = useState(false);
  const participantRef = useRef(participants);
  const onMicClick = (micEnabled) => {
    if (mainStream) {
      mainStream.getAudioTracks()[0].enabled = micEnabled;
      dispatch(updateUser({ audio: micEnabled }));
    }
  };
  const onVideoClick = (videoEnabled) => {
    if (mainStream) {
      mainStream.getVideoTracks()[0].enabled = videoEnabled;
      dispatch(updateUser({ video: videoEnabled }));
    }
  };
  useEffect(() => {
    participantRef.current = participants;
  }, [participants]);

  const updateStream = (stream) => {
    for (let key in participantRef.current) {
      const sender = participantRef.current[key];
      if (sender.currentUser) continue;
      const peerConnection = sender.peerConnection
        .getSenders()
        .find((s) => (s.track ? s.track.kind === "video" : false));
      peerConnection.replaceTrack(stream.getVideoTracks()[0]);
    }
    dispatch(setMainStream(stream));
  };
  const onScreenShareEnd = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    localStream.getVideoTracks()[0].enabled =
      Object.values(currentUser)[0].video;

    updateStream(localStream);

    dispatch(updateUser({ screen: false }));
  };
  const onScreenClick = async () => {
    let mediaStream;
    if (navigator.getDisplayMedia) {
      mediaStream = await navigator.getDisplayMedia({ video: true });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
    } else {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      });
    }

    mediaStream.getVideoTracks()[0].onended = onScreenShareEnd;

    updateStream(mediaStream);

    dispatch(updateUser({ screen: true }));
  };
  return (
    <div className="wrapper">
      <div className="main-screen">
        <Participants />
        <div className={`chat ${active ? "chat-active" : "chat-disable"}`}>
          <Chat setActive={setActive} active={active} />
        </div>
      </div>

      <div className="footer">
        <MeetingFooter
          onScreenClick={onScreenClick}
          onMicClick={onMicClick}
          onVideoClick={onVideoClick}
          setActive={setActive}
          active={active}
        />
      </div>
    </div>
  );
};

export default MainScreen;
