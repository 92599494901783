import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    FormGroup,
    Input,
    Label,
    FormFeedback,
    Button,
    Table,
    FormText
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdAdd, MdHorizontalRule } from "react-icons/md";
const EditQuestion = () => {
    const [id, setId] = useState("");
    const [quizId, setQuizId] = useState("");
    const [questionData, setQuestionData] = useState([{ answer: '', image: '', correct: '' }]);
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [status, setStatus] = useState(1);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleRemoveClick = index => {
        const list = [...questionData];
        list.splice(index, 1);
        setQuestionData(list);
    };
    // handle click event of the Add button
    const handleAddClick = () => {
        setQuestionData([...questionData, { answer: '', image: '', correct: '' }]);
    };
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        if (name == 'correctss') {
            const re = /^[0-9\b]+$/;
            if (value === '') {
                const list = [...questionData];
                list[index][name] = value;
                setQuestionData(e.target);
            }
        } else if (name == 'image') {
            const list = [...questionData];
            list[index][name] = e.target.files[0];
            setQuestionData(list);

        } else {

            const list = [...questionData];
            list[index][name] = value;
            setQuestionData(list);
        }
    };

    const geturl = window.location.pathname.split("/");
    useEffect(() => {
        axios(`/api/admin/v1/edit-course-quiz-question/${geturl[2]}`)
            .then(res => {
                const data = res.data.CourseQuizQuestion;
                console.log(data.options);
                setQuizId(data.quiz_id);
                setQuestion(data.question);
                setAnswer(data.grade);
                // setQuestionData1(JSON.parse(data.options));
                setQuestionData(JSON.parse(data.options));

                setId(data.id);
            })
            .catch(err => console.log(err));
    }, []);

    console.log(questionData, "certi");
    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append("question", question);
        // const certificateData = questionData.map((item) => {
        //     return { option: item?.answer || item?.option, image: item?.image || item?.image, correct: item?.correct || item?.correct }
        // })
        // certificateData.forEach((res, i) => {
        //     data.append(`certificate_json[${i}][answer]`, res.answer);
        //     data.append(`certificate_json[${i}][correct]`, res.correct);
        //     data.append(`certificate_json[${i}][image]`, res.image);
        // });
        data.append("options", JSON.stringify(questionData));
        data.append("answer", answer);
        data.append("quiz_id", quizId);
        data.append("status", status);

        axios.put(`/api/admin/v1/update-course-quiz-question/${id}`, data)
            .then(res => {
                setLoading(false);
                toast.success(res.data.message);
                // if (redirect) {
                //     // setTimeout(() => {
                //     //     window.location.href = `/edit-question/${id}`;
                //     // }, 1000);
                // } else {
                //     setTimeout(() => {
                //         window.location.href = `/edit-question/${id}`;
                //     }, 1000);
                // }


            })
            .catch(err => {
                if (err.response.status === 422) {
                    setLoading(false);
                    setErrors(err.response.data.errors);
                } else {
                    setLoading(false);
                    toast.error(err.response.data.message);
                }
            });
    };

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h5 className="text-white">Edit Course Quiz Question</h5>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-5 offset-md-1">
                        <FormGroup>
                            <Label
                                for="question"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                                Questions Title
                            </Label>
                            <Input
                                type="textarea"
                                id="question"
                                className="col-md-7 h-auto"
                                rows={4}
                                invalid={!!errors.question}
                                value={question}
                                onChange={e =>
                                    setQuestion(
                                        e.target.value
                                    )
                                }
                            ></Input>
                            <FormFeedback className="offset-5">{!!errors.question ? errors.question[0] : ''}</FormFeedback>

                        </FormGroup>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <FormGroup>
                            <Label
                                for="answer"
                                className="col-md-5 control-label col-form-label col-12 text-md-right"
                            >
                                Answer Mark
                            </Label>
                            <Input
                                type="text"
                                id="answer"
                                className="col-md-7 h-auto"
                                rows={2}
                                invalid={!!errors.answer}
                                value={answer}
                                onChange={e =>
                                    setAnswer(
                                        e.target.value
                                    )
                                }
                            ></Input>
                            <FormFeedback className="offset-5">{!!errors.answer ? errors.answer[0] : ''}</FormFeedback>

                        </FormGroup>
                    </div>
                    <div className="col-md-11 offset-md-1"><h5>
                        Answer Options
                    </h5><hr className="mt-0" /></div>
                    <div className="col-md-11 offset-md-1">
                        <Table responsive striped>
                            <thead>
                                <tr><th>Question Answer</th><th>Image</th><th>Correct</th><th>Action</th></tr>
                            </thead>
                            <tbody>
                                {questionData && questionData.map((ix, i) => <tr><th><Input type="text" name="answer" value={ix.answer} onChange={e => handleInputChange(e, i)} /></th>
                                    <th><span><Input type="file" data-default-file={typeof ix.image === 'string' ? `/storage/${ix.image}` : ''} id="image" className="dropify" name="image" accept="image/jpg, image/jpeg, image/png" onChange={e => handleInputChange(e, i)} />                                        <FormText color="danger">accept only PNG, JPEG and JPG </FormText></span>
                                        {/* {!(ix.image) &&

                                        <a href={`/storage/${ix.image}`} download><img src={`/storage/${ix.image}`} height="30px" width="30px" /></a>
                                    } */}
                                    </th><th><Input type="select" name="correct" value={ix.correct} onChange={e => handleInputChange(e, i)}>
                                        <option value="false"> False </option>
                                        <option value="true"> True </option></Input></th><th> <span>

                                            {questionData.length !== 1 &&
                                                <button type="button" className="btn btn-sm btn-danger text-center"
                                                    onClick={() => handleRemoveClick(i)}><MdHorizontalRule size={22} />
                                                </button>}
                                            {questionData.length - 1 === i &&
                                                <button type="button"
                                                    className="btn btn-sm btn-success text-center ms-1"
                                                    onClick={handleAddClick}><MdAdd size={22} /></button>}
                                        </span></th></tr>)}
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-4 offset-md-5">
                        <Button color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditQuestion;
