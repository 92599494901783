import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
const ConsultancySettings = () => {
  const navigate = useNavigate();
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const [formData, setFormData] = useState({
    writtenConsultancyFees: "",
    writtenConsultancyType: "",
    audioPerMinute: "",
    videoPerMinute: "",
    tokenAmount: "",
    minWithdrawAmount: "",
    facebook: "",
    youtube: "",
    twitter: "",
    instagram: "",
    commission: "",
    commissionone: "",
    commissiontwo: "",
    commissionthree: "",
    helpDesk1: "",
    helpDesk2: "",
    helpDesk3: "",
    helpDesk4: "",
    helpDesk5: "",
    id: 0,
  });
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    axios("/api/admin/v1/customize-settings", {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then(({ data }) => {
        setFormData((prev) => ({
          ...prev,
          writtenConsultancyFees: data?.setting?.writtenConsultancyFees || "",
          writtenConsultancyType: data?.setting?.writtenConsultancyType || "",
          commission: data?.setting?.commission || "",
          commissionone: data?.setting?.commission_one || "",
          commissiontwo: data?.setting?.commission_two || "",
          commissionthree: data?.setting?.commission_three || "",
          audioPerMinute: data?.setting?.audioFeePerMinute || "",
          videoPerMinute: data?.setting?.videoFeePerMinute || "",
          tokenAmount: data?.setting?.defaultTokenAmount || "",
          minWithdrawAmount: data?.setting?.minWithdrawAmount || "",
          facebook: data?.setting?.facebookAccount || "",
          instagram: data?.setting?.instaAccount || "",
          youtube: data?.setting?.youtubeChannel || "",
          twitter: data?.setting?.twitterAccount || "",
          helpDesk1: data?.setting?.helpdesk_number_one || "",
          helpDesk2: data?.setting?.helpdesk_number_two || "",
          helpDesk3: data?.setting?.helpdesk_number_three || "",
          helpDesk4: data?.setting?.helpdesk_number_four || "",
          helpDesk5: data?.setting?.helpdesk_number_five || "",
          id: data?.setting?.id,
        }));
      })
      .catch((err) => console.error(err));
  }, [auth.token]);

  const handleSubmit = () => {
    axios
      .post("/api/admin/v1/customize-settings", formData, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    if (formData.writtenConsultancyType === "free")
      setFormData((prev) => ({ ...prev, writtenConsultancyFees: "" }));
    console.log(formData);
  }, [formData.writtenConsultancyType]);
  return (
    <div className="bg-white px-3 py-5 rounded-2  my-4">
      <div className="row">
        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Extra Video Fee Per Minute</Label>
            <Input
              name="videoPerMinute"
              onChange={inputHandler}
              value={formData.videoPerMinute}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Extra Audio Fee Per Minute</Label>
            <Input
              name="audioPerMinute"
              onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              value={formData.audioPerMinute}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Written Consultancy Fee Type</Label>
            <Input
              value={formData.writtenConsultancyType}
              type="select"
              name="writtenConsultancyType"
              onChange={inputHandler}
            >
              <option value="">Select</option>
              <option value="free">Free</option>
              <option value="paid">Paid</option>
            </Input>
          </FormGroup>
        </div>

        {formData.writtenConsultancyType === "paid" && (
          <div className="col-md-4 offset-md-2">
            <FormGroup>
              <Label>Written Consultancy Fee</Label>
              <Input
                value={formData.writtenConsultancyFees}
                name="writtenConsultancyFees"
                onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
              />
            </FormGroup>
          </div>
        )}

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Commission</Label>
            <Input
              value={formData.commission}
              name="commission"
              onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Commission One</Label>
            <Input
              value={formData.commissionone}
              name="commissionone"
              onChange={(e) => inputHandler(e)}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Commission Two</Label>
            <Input
              value={formData.commissiontwo}
              name="commissiontwo"
              onChange={(e) => inputHandler(e)}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Commission Three</Label>
            <Input
              value={formData.commissionthree}
              name="commissionthree"
              onChange={(e) => inputHandler(e)}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Default Token Value</Label>
            <Input
              value={formData.tokenAmount}
              name="tokenAmount"
              onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Minimum Withdraw Amount</Label>
            <Input
              value={formData.minWithdrawAmount}
              name="minWithdrawAmount"
              onChange={(e) => !isNaN(e.target.value) && inputHandler(e)}
            />
          </FormGroup>
        </div>

        <div className="col-10 offset-md-2">
          <h5>Social Media Links</h5>
          <hr />
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Facebook Account</Label>
            <Input
              value={formData.facebook}
              name="facebook"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Twitter Account</Label>
            <Input
              value={formData.twitter}
              name="twitter"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Youtube Channel</Label>
            <Input
              value={formData.youtube}
              name="youtube"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Instagram Account</Label>
            <Input
              value={formData.instagram}
              name="instagram"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>
        <div className="col-10 offset-md-2">
          <h5>HelpDesk Mobile Numbers</h5>
          <hr />
        </div>

        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Help Desk Number 1 </Label>
            <Input
              value={formData.helpDesk1}
              name="helpDesk1"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>
        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Help Desk Number 2</Label>
            <Input
              value={formData.helpDesk2}
              name="helpDesk2"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>
        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Help Desk Number 3</Label>
            <Input
              value={formData.helpDesk3}
              name="helpDesk3"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>
        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Help Desk Number 4</Label>
            <Input
              value={formData.helpDesk4}
              name="helpDesk4"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>
        <div className="col-md-4 offset-md-2">
          <FormGroup>
            <Label>Help Desk Number 5</Label>
            <Input
              value={formData.helpDesk5}
              name="helpDesk5"
              onChange={inputHandler}
            />
          </FormGroup>
        </div>
        <div className="col-md-4 offset-md-2">
          <div className="d-flex align-items-center">
            <Button color="primary" onClick={handleSubmit} className="me-3">
              Submit
            </Button>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultancySettings;
