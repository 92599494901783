import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import CKEditor from "react-ckeditor-component";
import {
  getSubCategories,
  getCategories,
} from "../../redux/actions/indexActions";
import { toast } from "react-toastify";
import { getProduct } from "../../redux/actions/ProductAction";
const UpdateProduct = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    sector_id: "",
    industry_id: "",
    consultantId: "",
    product_name_english: "",
    product_name_hindi: "",
    short_description_english: "",
    short_description_hindi: "",
    description_english: "",
    description_hindi: "",
    price: 0,
    url: "",
    manufactured_by: "",
    rate: 0,
    location: "",
    features: "",
    product_images: "",
    status: 1,
    avatar_english: "",
    avatar_hindi: "",
  });
  const [errors, setErrors] = useState({});
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [text, setText] = useState("");
  const [consultant, setConsultant] = useState([]);

  const {
    AuthReducer: { auth },
    ProductReducer: { products },
    indexReducer: { categories, subCategories },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (
      name === "product_images" ||
      name === "avatar_english" ||
      name === "avatar_hindi"
    ) {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleText = (e) => {
    const features = e.editor.getData();
    setText(features);
  }
  const handleSubmit = () => {
    const form = new FormData();
    form.append('sector_id', formData.sector_id);
    form.append('industry_id', formData.industry_id);
    form.append('consultantId', formData.consultantId);
    form.append('product_name_english', formData.product_name_english);
    form.append('product_name_hindi', formData.product_name_hindi);
    form.append('avatar_english', formData.avatar_english);
    form.append('avatar_hindi', formData.avatar_hindi);
    form.append('short_description_english', formData.short_description_english);
    form.append('short_description_hindi', formData.short_description_hindi);
    form.append('description_english', formData.description_english);
    form.append('description_hindi', formData.description_hindi);
    form.append('price', formData.price);
    form.append('url', formData.url);
    form.append('manufactured_by', formData.manufactured_by);
    form.append('rate', formData.rate);
    form.append('location', formData.location);
    form.append('features', text);
    form.append('status', formData.status);
    axios
      .put(`/api/admin/v1/product/${id}`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data);
      });
  };

  useEffect(() => {
    dispatch(getSubCategories(auth?.token));
    axios(`/api/admin/v1/product-edit/${id}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        console.log(res.data, "fgh");
        setText(res.data.Products.features);
        setFormData((prev) => ({
          ...prev,
          sector_id: res.data?.Products?.sector_id || "",
          industry_id: res.data?.Products?.industry_id || "",
          consultantId: res.data?.Products?.consultantId,
          product_name_english: res.data?.Products?.product_name_english || "",
          product_name_hindi: res.data?.Products?.product_name_hindi || "",
          price: res.data?.Products?.price,
          rate: res.data?.Products?.rate || "",
          url: res.data?.Products?.url || "",
          short_description_english:
            res.data?.Products?.short_description_english || "",
          short_description_hindi:
            res.data?.Products?.short_description_hindi || "",
          manufactured_by: res.data?.Products?.manufactured_by || "",
          location: res.data?.Products?.location || "",
          description_english: res.data?.Products?.description_english || "",
          description_hindi: res.data?.Products?.description_hindi || "",
          status: res.data?.Products?.status,
        }));
        // console.log(res.data?.subSubCategory?.subCategoryId ,"idddd")
      })
      .catch((err) => console.log(err));
  }, [auth]);
  useEffect(() => {
    if (formData.sector_id) {
      // setFormData((prev) => ({ ...prev, industry_id: "" }));
      axios(`/api/web/v1/get-industry-type/${formData.sector_id}`)
        .then(({ data }) => {
          setSubSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubSubCategories([]);
    }
  }, [formData.sector_id]);

  useEffect(() => {
    if (formData.industry_id) {
      axios(`/api/web/v1/get-consultant-byindustry/${formData.industry_id}`)
        .then(({ data }) => {
          setConsultant(data?.consultants);
        })
        .catch((err) => console.error(err));
    } else {
      setConsultant([]);
    }
  }, [formData.industry_id]);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Update Product</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Product Name English <span className="text-danger fs-4">*</span></Label>
              <Input
                invalid={Boolean(errors?.product_name_english)}
                value={formData?.product_name_english}
                name="product_name_english"
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.name} </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Product Name Hindi</Label>
              <Input
                invalid={Boolean(errors?.product_name_hindi)}
                value={formData?.product_name_hindi}
                name="product_name_hindi"
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.name} </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Price</Label>
              <Input
                value={formData?.price}
                name="price"
                type="integer"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Video Url <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.url}
                invalid={Boolean(errors?.url)}
                name="url"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.url}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Avatar English</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.avatar_english)}
                name="avatar_english"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">
                {errors?.avatar_english}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Avatar Hindi</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.avatar_hindi)}
                name="avatar_hindi"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">
                {errors?.avatar_hindi}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Segment <span className="text-danger fs-4">*</span></Label>
              <Input
                invalid={Boolean(errors?.sector_id)}
                value={formData?.sector_id}
                type="select"
                name="sector_id"
                onChange={inputHandler}
              >
                <option value="">Select Segment</option>
                {subCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name_english}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger"> {errors?.sector_id} </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Expertise <span className="text-danger fs-4">*</span></Label>
              <Input
                invalid={Boolean(errors?.industry_id)}
                value={formData?.industry_id}
                type="select"
                name="industry_id"
                onChange={inputHandler}
              >
                <option value="">Select Expertise</option>
                {subSubCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name_english}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger"> {errors?.industry_id} </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label className="text-black">Consultant <span className="text-danger fs-4">*</span></Label>
              <Input
                invalid={Boolean(errors?.consultantId)}
                value={formData?.consultantId}
                type="select"
                name="consultantId"
                onChange={inputHandler}
              >
                <option value="">Select Consultant</option>
                {consultant?.map?.((item) => {
                  return (
                    <option value={item?.consultant?.id} key={item.id}>
                      {item?.consultant?.name}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">
                {" "}
                {errors?.consultantId}{" "}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Manufactured By <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.manufactured_by}
                invalid={Boolean(errors?.manufactured_by)}
                name="manufactured_by"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {errors?.manufactured_by}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Rate</Label>
              <Input
                value={formData?.rate}
                invalid={Boolean(errors?.rate)}
                name="rate"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.rate}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Location <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.location}
                invalid={Boolean(errors?.location)}
                name="location"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.location}</span>
            </FormGroup>
          </div>

          <div className="col-md-11 offset-md-1">
            <FormGroup>
              <Label>Features <span className="text-danger fs-4">*</span></Label>
              <CKEditor
                activeClass="col-md-10 pr-0"
                content={text}
                events={{
                  change: handleText,
                }}
              />
              <span className="d-block text-danger"> {errors?.features} </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Description English <span className="text-danger fs-4">*</span></Label>
              <Input
                name="short_description_english"
                value={formData?.short_description_english}
                type="textarea"
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.short_description_english} </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Description Hindi</Label>
              <Input
                name="short_description_hindi"
                value={formData?.short_description_hindi}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Description English <span className="text-danger fs-4">*</span></Label>
              <Input
                value={formData?.description_english}
                name="description_english"
                type="textarea"
                onChange={inputHandler}
              />
              <span className="d-block text-danger"> {errors?.description_english} </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Description Hindi</Label>
              <Input
                value={formData?.description_hindi}
                name="description_hindi"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
