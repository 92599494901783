import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import { FcDocument, FcGallery, FcOpenedFolder } from "react-icons/fc";
import { Input } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "../Components/Paginate";
import { getLockerDetails } from "../redux/actions/indexActions";
import { FiLayers } from "react-icons/fi";
import { FaDownload, FaRegBuilding, FaUser } from "react-icons/fa";

const UserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { lockerDocs, loading },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get('userType');
  useEffect(() => {
    dispatch(getLockerDetails(id, userType));
  }, [id, userType]);
  const toggle = () => setIsOpen((prev) => !prev);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const [activeFolderIndex, setActiveFolderIndex] = useState(0);

  const handleFolderToggle = (index) => {
    setActiveFolderIndex(activeFolderIndex === index ? -1 : index);
  };

  // const handleDel = (id) => {
  //   if (window.confirm("Are You Sure You Want To Delete This User")) {
  //     axios
  //       .delete(`/api/admin/v1/user-delete/${id}`, {
  //         headers: {
  //           Authorization: `Bearer ${auth?.token}`,
  //         },
  //       })
  //       .then((res) => {
  //         toast.success(res.data.message);
  //         dispatch(getLockerDetails(auth?.token, paginate, page, search, sort));
  //       })
  //       .catch((err) => toast.error("Somthing Went Wrong"));
  //   }
  // };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">User Details</h5>
          </div>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
        <div className="row gy-3">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3">
                <span className="text-sm fw-semibold text-secondary">
                  Name
                </span>
              </div>
              <div className="col-md-9">
                <span>{lockerDocs?.name}</span>
              </div>

              <div className="col-md-3">
                <span className="text-sm fw-semibold text-secondary">
                  Mobile
                </span>
              </div>
              <div className="col-md-9">
                <span>{lockerDocs?.mobile || "N/A"}</span>
              </div>

              <div className="col-md-3">
                <span className="text-sm fw-semibold text-secondary">
                  Email
                </span>
              </div>
              <div className="col-md-9">
                <span>{lockerDocs?.email || "N/A"}</span>
              </div>

              <div className="col-md-3">
                <span className="text-sm fw-semibold text-secondary">
                  Gender
                </span>
              </div>
              <div className="col-md-9">
                <span>{lockerDocs?.gender || "N/A"}</span>
              </div>

              <div className="col-md-3">
                <span className="text-sm fw-semibold text-secondary">
                  City
                </span>
              </div>
              <div className="col-md-9">
                <span>
                  {lockerDocs?.city || "N/A"}
                </span>
              </div>

              <div className="col-md-3">
                <span className="text-sm fw-semibold text-secondary">
                  State
                </span>
              </div>
              <div className="col-md-9">
                <span>
                  {lockerDocs?.state || "N/A"}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="col-md-12">
              {lockerDocs?.thumbnail ? (
                <span>
                  <img
                    src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${lockerDocs?.thumbnail}`}
                    height={100}
                    width={100}
                  />
                </span>
              ) : null}
            </div>
          </div> */}
          {lockerDocs?.lockerGroups?.length ? (
            <div className="col-md-12">
              <div className="py-2 px-4 bg-light mt-3">
                <span><b> Locker Groups</b></span>
              </div>
              <div className="mt-3">
                <div className="accordion" id="lockerGroupsAccordion">
                  {lockerDocs?.lockerGroups?.map((lockerGroup, groupIndex) => (
                    <div className="accordion-item" key={groupIndex + 1}>
                      <h2 className="accordion-header" id={`headingGroup${groupIndex + 1}`}>
                        <button
                          className={`accordion-button ${activeIndex === groupIndex + 1 ? '' : 'collapsed'}`}
                          onClick={() => handleToggle(groupIndex + 1)}
                          aria-expanded={activeIndex === groupIndex + 1}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseGroup${groupIndex + 1}`}
                          aria-controls={`collapseGroup${groupIndex + 1}`}
                        >
                          <span className="d-flex me-3 pt-1">
                            {lockerGroup?.groupName == "1" ? (
                              <>
                                <span className="icon-box-4 bg-color-4 text-white">
                                  <FaRegBuilding size={22} />
                                </span>
                                <span className="ms-2 text-dark mt-2">{lockerGroup?.companyName}</span>
                              </>
                            ) : (
                              ""
                            )}

                            {lockerGroup?.groupName == "2" ? (
                              <>
                                <span className="icon-box-4 bg-color-7 text-white">
                                  <FaUser size={22} />
                                </span>
                                <span className="ms-2 text-dark mt-2">{lockerGroup?.companyName}</span>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapseGroup${groupIndex + 1}`}
                        className={`accordion-collapse collapse ${activeIndex === groupIndex + 1 ? 'show' : ''}`}
                        aria-labelledby={`headingGroup${groupIndex + 1}`}
                        data-bs-parent="#lockerGroupsAccordion"
                      >
                        <div className="accordion-body">
                          {lockerGroup?.lockers?.map((lockerDoc, lockerIndex) => (
                            <div className="accordion" id={`lockersAccordion${groupIndex + 1}`} key={lockerIndex + 1}>
                              <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id={`headingLocker${groupIndex + 1}-${lockerIndex + 1}`}>
                                  <button
                                    className={`accordion-button ${activeFolderIndex === lockerIndex + 1 ? '' : 'collapsed'}`}
                                    onClick={() => handleFolderToggle(lockerIndex + 1)}
                                    aria-expanded={activeFolderIndex === lockerIndex + 1}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                    aria-controls={`collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                  >
                                    <FcOpenedFolder size={22} className="me-2" /> {lockerDoc.documentName}
                                  </button>
                                </h2>
                                <div
                                  id={`collapseLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                  className={`accordion-collapse collapse ${activeFolderIndex === lockerIndex + 1 ? 'show' : ''}`}
                                  aria-labelledby={`headingLocker${groupIndex + 1}-${lockerIndex + 1}`}
                                  data-bs-parent={`#lockersAccordion${groupIndex + 1}`}
                                >
                                  <div className="accordion-body">
                                    <table className="table table-striped">
                                      <thead>
                                        <tr>
                                          <td>Locker Id</td>
                                          <td>Document</td>
                                          <td>Download</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {lockerDoc?.lockerDocuments?.map((lockerFile) => (
                                          <tr key={lockerFile.id}>
                                            <td>{lockerFile.lockerId || "N/A"}</td>
                                            <td>{lockerFile.documentPath || "N/A"}</td>
                                            <td> <a href={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${lockerFile.documentPath}`}><FaDownload className="me-2" /> Download </a></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

        </div>
      </div>
    </div>
  );
};

export default UserDetail;
