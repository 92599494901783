const inintialState = {
  consultants: [],
  categorytype: [],
 // ConsultantCount:0,
  consultantss:[],
  loading: false,
  error: {},
};

const consultantReducer = (state = inintialState, action) => {
  switch (action.type) {
    case "GET_CONSULTANT_LIST":
      return { ...state, loading: true };

    case "GET_CONSULTANT_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        consultants: action.payload?.consultants,
        consultantss:action.payload?.consultantss,
        activeConsultants: action.payload?.activeConsultants,
      };

    case "GET_CONSULTANT_LIST_REJECTED":
      return { ...state, loading: false, error: action.payload };

    case "GET_CATEGORY_TYPE_LIST":
      return { ...state, loading: true };

    case "GET_CATEGORY_TYPE_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        categorytype: action.payload?.data,
      };

    case "GET_CATEGORY_TYPE_LIST_REJECTED":
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default consultantReducer;
