import axios from "axios";

export const getCourseType =
  (token, limit, page, search = "", sort = "desc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_COURSETYPE_PENDING" });
        const response = await axios(
          `/api/admin/v1/course-type-list?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_COURSETYPE_FULFILLED",
          payload: response.data.CourseTypeList,
          courseTypesCount: response.data?.CourseTypeCount
        });
      } catch (error) {
        dispatch({ type: "GET_COURSETYPE_FAILED", payload: error.response.data });
      }
    };

export const getCourseCatgeory =
  (token, limit, page, search = "", sort = "asc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_COURSECATEGORY_PENDING" });
        const response = await axios(
          `/api/admin/v1/course-category-list?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_COURSECATEGORY_FULFILLED",
          payload: response.data.courseCategories,
          courseCategoriesCount: response.data?.courseCategoriesCount
        });
      } catch (error) {
        dispatch({ type: "GET_COURSECATEGORY_FAILED", payload: error.response.data });
      }
    };

export const getCourseTypes = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_TYPE_LIST_PENDING" });
    const response = await axios("/api/mobile/v1/coure-types", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_TYPE_LIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_TYPE_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseSubCatgeory =
  (token, limit, page, search = "", sort = "desc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_COURSE_SUB_CATEGORY_PENDING" });
        const response = await axios(
          `/api/admin/v1/course-sub-category-list?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_COURSE_SUB_CATEGORY_FULFILLED",
          payload: response.data.courseSubCategories,
          courseSubCategoriesCount: response.data?.courseSubCategoriesCount
        });
      } catch (error) {
        dispatch({ type: "GET_COURSE_SUB_CATEGORY_FAILED", payload: error.response.data });
      }
    };

export const getCourseSubSubCategory =
  (token, limit, page, search = "", sort = "asc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_COURSE_SUB_SUB_CATEGORY_PENDING" });
        const response = await axios(
          `/api/admin/v1/course-sub-sub-category-list?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_COURSE_SUB_SUB_CATEGORY_FULFILLED",
          payload: response.data.courseSubSubCategories,
          courseSubSubCategoriesCount: response.data?.courseSubSubCategoriesCount
        });
      } catch (error) {
        dispatch({ type: "GET_COURSE_SUB_SUB_CATEGORY_FAILED", payload: error.response.data });
      }
    };

export const getCourseSubCategoryList = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_SUB_CATEGORY_LIST_PENDING" });
    const response = await axios(`/api/mobile/v1/get-course-sub-category-list/id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_SUB_CATEGORY_LIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_SUB_CATEGORY_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseCategories = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_CATEGORIES_LIST_PENDING" });
    const response = await axios("/api/mobile/v1/get-course-category-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_CATEGORIES_LIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_CATEGORIES_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseSectionModule = (token, limit, page, search = "", sort = "desc") => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_SECTION_MODULE_PENDING" });
    const response = await axios(
      `/api/admin/v1/course-module-section-list?limit=${limit || 10}&page=${page || 0
      }&search=${search}&sort=${sort}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_SECTION_MODULE_FULFILLED",
      payload: response.data,
      courseModuleSectionCount: response.data.courseModuleSectionCount,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_SECTION_MODULE_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseSectionModuleList = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_SECTION_MODULE_LIST_PENDING" });
    const response = await axios("/api/mobile/v1/course-list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_SECTION_MODULE_LIST_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_SECTION_MODULE_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseModule = (token, limit, page, search = "", sort = "desc") => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_MODULE_PENDING" });
    const response = await axios(
      `/api/admin/v1/course-module-list?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_MODULE_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_MODULE_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseOrder = (token, limit, page, search = "", sort = "desc", course) => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_ORDER_PENDING" });
    const response = await axios(
      `/api/admin/v1/all-course-order?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}&course=${course}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_ORDER_FULFILLED",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_ORDER_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getAllCourses = (token, limit, page, search = "", sort="desc") => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_LIST_PENDING" });
    const response = await axios(`/api/admin/v1/course-list?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "GET_COURSE_LIST_FULFILLED", payload: response.data.courses, coursesCount: response.data?.coursesCount });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseQuizes = (token, limit, page, search = "", sort="desc") => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_QUIZ_LIST_PENDING" });
    const response = await axios(`/api/admin/v1/course-quiz-list?limit=${limit || 10}&page=${page || 0}&search=${search}&sort=${sort}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_QUIZ_LIST_FULFILLED",
      payload: response.data.courseQuizes,
      courseQuizCount: response.data?.courseQuizCount
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_QUIZ_LIST_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourses = (token) => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_PENDING" });
    const response = await axios("/api/admin/v1/get-all-courses", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_FULFILLED",
      payload: response.data.courses
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_REJECTED",
      payload: error.response.data,
    });
  }
};

export const getCourseQuizQuestions = (token, limit, page, quizId = "") => async (dispatch) => {
  try {
    dispatch({ type: "GET_COURSE_QUIZ_QUESTION_PENDING" });
    const response = await axios(`/api/admin/v1/questions-by-quiz/${quizId}?limit=${limit || 10}&page=${page || 0}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: "GET_COURSE_QUIZ_QUESTION_FULFILLED",
      payload: response.data.CourseQuizQuestions,
      CourseQuizQuestionsCount: response.data?.CourseQuizQuestionsCount
    });
  } catch (error) {
    dispatch({
      type: "GET_COURSE_QUIZ_QUESTION_REJECTED",
      payload: error.response.data,
    });
  }
};