import axios from "axios";
import React, { useState, useEffect } from "react";
import { Table, Spinner, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Components/Paginate";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";
import {
  getCategories,
  getSubCategories,
} from "../../redux/actions/indexActions";
const Index = () => {
  const [loading, setLoading] = useState(true);
  const [payouts, setPayouts] = useState([]);
  const [paginate, setPaginate] = useState(10);
  const [page, setPage] = useState(0);
  const [payoutCount, setPayoutCount] = useState(0);

  const [sort, setSort] = useState("desc");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subSubSubCategoryFilter, setSubSubCategoryFilter] = useState("");
  const [subCategoriess, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const {
    AuthReducer: { auth },
    indexReducer: { categories },
    PermissionReducer: { allowedPermissions }
  } = useSelector((state) => state);
  useEffect(() => {
    if (categoryFilter) {
      // setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${categoryFilter}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [categoryFilter]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);
  useEffect(() => {
    axios(
      `/api/admin/v1/get-payment-report?limit=${paginate || 10}&page=${page || 0
      }&sort=${sort}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}&subSubCategoryFilter=${subSubSubCategoryFilter}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then(({ data }) => {
        setPayouts(data?.appointments || []);
        setLoading(false);
        setPayoutCount(data?.payoutCount);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [
    paginate,
    page,
    sort,
    categoryFilter,
    subCategoryFilter,
    subSubSubCategoryFilter,
  ]);
  useEffect(() => {
    if (subCategoryFilter) {
      axios(`/api/web/v1/get-industry-type/${subCategoryFilter}`)
        .then(({ data }) => {
          setSubSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubSubCategories([]);
    }
  }, [subCategoryFilter]);
  const csvHead = [
    [
      "name",
      "consultant",
      "amount",
      "date",
      "start time",
      "end time",
      "rating",
      "sector",
      "industry",
    ],
  ];
  const csvData = payouts.map((cv) => [
    cv?.user?.name,
    cv?.consultant?.name,
    cv?.amount,
    cv?.consultant_slot?.consultant_slote_date?.dateIndex,
    cv?.consultant_slot?.startTime,
    cv?.consultant_slot?.endTime,
    cv?.user_review?.rating,
    cv?.subCategory?.name_english,
    cv?.subSubCategory?.name_english,
  ]);
  const exportData = csvHead.concat(csvData);

  return (
    <div>
      <div className="row ">
        <div className="col-md-12">
          <div className="mb-4 d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white fs-4">Payment Report</h5>
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2 my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row my-3">
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={categoryFilter}
              type="select"
              name="categoryId"
              onChange={({ target }) => setCategoryFilter(target.value)}
            >
              <option value="">Select Category</option>
              {categories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.type}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subCategoryFilter}
              type="select"
              name="subCategoryId"
              onChange={({ target }) => setSubCategoryFilter(target.value)}
            >
              <option value="">Select Segment</option>
              {subCategoriess?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subSubSubCategoryFilter}
              type="select"
              name="subSubCategoryId"
              onChange={({ target }) => setSubSubCategoryFilter(target.value)}
            >
              <option value="">Select Expertise</option>
              {subSubCategories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2 ">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType ===
              "PAYMENTREPORT" &&
              item?.permission?.permission === "export"
          ) && (
              <div className="col-md-1 mb-2">
                <CSVLink
                  filename={"payout.csv"}
                  className="text-black mr-1"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <Table hover responsive>
              <thead>
                <tr>
                  <th>SL. No.</th>
                  <th>User Name</th>
                  <th>Consultant</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Rating</th>
                  <th>Sector</th>
                  <th>Industry</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      <Spinner />
                    </td>
                  </tr>
                ) : (
                  payouts.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item?.user?.name}</td>
                        <td>{item?.consultant?.name}</td>
                        <td>{item?.amount}</td>
                        <td>
                          {
                            item?.consultant_slot?.consultant_slote_date
                              ?.dateIndex
                          }
                        </td>
                        <td>{item?.consultant_slot?.startTime}</td>
                        <td>{item?.consultant_slot?.endTime}</td>
                        <td>{item?.user_review?.rating}</td>
                        <td>{item?.subCategory?.name_english}</td>
                        <td>{item?.subSubCategory?.name_english}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <select
                id="paginate"
                onChange={(e) => setPaginate(e.target.value)}
                value={paginate}
                className="paging custom-select custom-select-page"
              >
                <option value="10" selected>
                  10
                </option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </div>
            <Pagination
              pageCount={payoutCount / paginate}
              onChange={(e) => setPage(e.selected)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
