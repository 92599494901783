import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import {
    getSeoList,
} from "../../redux/actions/indexActions";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";

const SeoIndex = () => {
    const dispatch = useDispatch();
    const {
        AuthReducer: { auth },
        indexReducer: {
            seolists,
            seoCount,
        },
        PermissionReducer: { allowedPermissions },
    } = useSelector((state) => state);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState('desc');
    const [paginate, setPaginate] = useState(10);
    const indexOfLastItem = (page + 1) * paginate;
    const indexOfFirstItem = indexOfLastItem - paginate;
    useEffect(() => {
        dispatch(getSeoList(auth?.token, paginate, page, search, sort))
    }, [auth, paginate, page, search, sort]);


    const handleDel = (id) => {
        if (window.confirm("Are You Sure You Want To Delete This Seo")) {
            axios
                .delete(`/api/admin/v1/delete-seo/${id}`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                })
                .then((res) => {
                    toast.success(res.data.message);
                    dispatch(getSeoList(auth?.token));
                })
                .catch((err) => toast.error("Somthing Went Wrong"));
        }
    };
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between" >
                        <h5 className="mb-0 text-white fs-4">Seo List</h5>
                        {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "SEOLIST" && item?.permission?.permission === "create") && (
                            <Link to="/add-seo">
                                <Button className="bg-white text-black" size="sm">
                                    <span className="me-2">
                                        <MdAdd size={22} />
                                    </span>
                                    Add Seo
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4" style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>
                <div className="row">
                    <div className="col-md-3 mb-2">
                        <Input size={"sm"} value={search} placeholder='Search By Title' onChange={(({ target: { value } }) => setSearch(value))} />
                    </div>
                    <div className="col-md-2 offset-md-7">
                        <Input type="select" size={"sm"} value={sort} onChange={(({ target }) => setSort(target.value))} >
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </Input>
                    </div>
                    <div className="col-md-12">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>SL. No.</th>
                                    <th>Title</th>
                                    <th>Url</th>
                                    <th>Keywords</th>
                                    <th>Description</th>
                                    {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "SEOLIST" && (item?.permission?.permission === "edit" || item?.permission?.permission === "delete")) && (
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {seolists?.length === 0 ? (
                                    <tr>
                                        <td colSpan="6">No data found</td>
                                    </tr>
                                ) : (<>
                                    {seolists?.map?.((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <th scope="row">{index + 1 + page * paginate}</th>
                                                <td>{item?.title}</td>
                                                <td>{item?.url}</td>
                                                <td>{item?.keywords}</td>
                                                <td dangerouslySetInnerHTML={{
                                                    __html: item?.description?.slice(0, 50),
                                                }}></td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        {allowedPermissions?.some?.(
                                                            (item) =>
                                                                item?.permission?.moduleType ===
                                                                "SEOLIST" &&
                                                                item?.permission?.permission === "edit"
                                                        ) && (
                                                                <Link to={`/edit-seo/${item?.id}`}>
                                                                    <AiOutlineEdit
                                                                        size={22}
                                                                        className="text-warning me-3"
                                                                    />
                                                                </Link>
                                                            )}
                                                        {allowedPermissions?.some?.(
                                                            (item) =>
                                                                item?.permission?.moduleType ===
                                                                "SEOLIST" &&
                                                                item?.permission?.permission === "delete"
                                                        ) && (
                                                                <AiOutlineDelete
                                                                    size={22}
                                                                    className="text-danger"
                                                                    onClick={() => handleDel(item.id)}
                                                                />
                                                            )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}</>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6 d-flex">
                        <div className="col-md-2">
                            <Input
                                type="select"
                                id="paginate"
                                onChange={e =>
                                    setPaginate(
                                        e.target.value
                                    )
                                }
                                value={paginate}
                                className="paging custom-select custom-select-page m-0"
                            >
                                <option value="10">
                                    10
                                </option>
                                <option value="20">
                                    20
                                </option>
                                <option value="50">
                                    50
                                </option>
                                <option value="100">
                                    100
                                </option>
                                <option value="200">
                                    200
                                </option>
                                <option value="500">
                                    500
                                </option>
                            </Input>
                        </div>
                        <div className='col-md-10 text-black d-flex align-items-center ps-3'>
                            {seoCount > 0 ? (
                                <>
                                    Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, seoCount)} of {seoCount} entries
                                </>
                            ) : (
                                <>
                                    Showing 0 to 0 of 0 entries
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-flex justify-content-end'>
                            <Pagination
                                pageCount={(seoCount) / paginate}
                                onChange={(e) => setPage(e.selected)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeoIndex;
