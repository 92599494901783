import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import CreateAdmin from "./pages/CreateAdmin";
import CreateRole from "./pages/Roles/CreateRole";
import Category from "./pages/Categories/Category";
import ServiceCreate from "./pages/Services/ServiceCreate";
import Services from "./pages/Services/Services";
import ServiceUpdate from "./pages/Services/ServiceUpdate";
import AddServiceCategory from "./pages/Services/AddServiceCategory";
import ServicesCategories from "./pages/Services/ServicesCategories";
import UpdateServicesCategory from "./pages/Services/UpdateServicesCategory";
import UpdateCategory from "./pages/Categories/UpdateCategory";
import AddCategory from "./pages/Categories/AddCategory";
import Subcategory from "./pages/SubCategory/Subcategory";
import UpdateSubCategory from "./pages/SubCategory/UpdateSubCategory";
import AddSubCategory from "./pages/SubCategory/AddSubCategory";
import SubSubCategory from "./pages/SubSubCategories";
import AddSubSubCategory from "./pages/SubSubCategories/AddSubSubCategory";
import UpdateSubSubCategory from "./pages/SubSubCategories/UpdateSubSubCategory";
import Roles from "./pages/Roles/Roles";
import Permissions from "./pages/Permission";
import AssignPermissions from "./pages/Permission/AssignPermissions";
import ConsultantList from "./pages/Consultants/ConsultantList";
import ConsultantUpdate from "./pages/Consultants/ConsultantUpdate";
import ConsultantAdd from "./pages/Consultants/ConsultantAdd";
import BookingList from "./pages/BookingList/BookingList";
import Withdrawal from "./pages/Withdrawal/Withdrawal";
import Faq from "./pages/Faq/Faq";
import TaxForm from "./pages/TaxForm";
import PayoutReport from "./pages/PayoutReport";
import ConsultancySettings from "./pages/ConsultancySettings";
import AdminUsers from "./pages/AdminUsers/AdminUsers";
import CreateMeeting from "./pages/CreateMeeting";
import MeetingLis from "./pages/CreateMeeting/MeetingLis";
import AddExpertVideos from "./pages/ExpertVideos/AddExpertVideos";
import ExpertVideos from "./pages/ExpertVideos/index";
import Offerlist from "./pages/Offer/index";
import AddOffer from "./pages/Offer/AddOffer";
import EditExpertVideos from "./pages/ExpertVideos/Edit";
import EditOffer from "./pages/Offer/Edit";
import MeetingPage from "./pages/MeetingPage";
import MeetingReview from "./pages/MeetingReview";
import UserTopUp from "./pages/UserTopUp";
import Bannerlist from "./pages/Banner/index";
import AddBanner from "./pages/Banner/AddBanner";
import EditBanner from "./pages/Banner/Edit";
import CourseType from "./pages/CoursesType/index";
import AddCourseType from "./pages/CoursesType/addtype";
import UpdateCourseType from "./pages/CoursesType/edittype";
import CourseCategory from "./pages/CourseCategory/index";
import AddCourseCategory from "./pages/CourseCategory/addcategory";
import UpdateCourseCategory from "./pages/CourseCategory/editcategory";
import CourseSubcategory from "./pages/CourseSubCategory/index";
import AddCourseSubCategory from "./pages/CourseSubCategory/addCourseSubCatgeory";
import UpdateCourseSubCategory from "./pages/CourseSubCategory/editCourseSubCategory";
import CourseSubSubCategory from "./pages/CourseSubSubCategory/index";
import AddCourseSubSubCategory from "./pages/CourseSubSubCategory/addCourseSubSubCategory";
import UpdateCourseSubSubCategory from "./pages/CourseSubSubCategory/editCourseSubSubCategory";
import ProdcutList from "./pages/Products/prodcutList";
import AddProduct from "./pages/Products/addProduct";
import UpdateProduct from "./pages/Products/editProduct";
import UpdateUser from "./pages/EditUser";
import CourseSectionModule from "./pages/CourseSection/coursesection";
import AddCourseSectionModule from "./pages/CourseSection/addCourseSection";
import UpdateCourseSectionModule from "./pages/CourseSection/editCourseSection";
import CourseModule from "./pages/CourseModule/coursemodule";
import AddCourseModule from "./pages/CourseModule/addCourseModule";
import UpdateCourseModule from "./pages/CourseModule/editCourseModule";
import CourseOrder from "./pages/CourseOrder/courseOrder";
import CourseList from "./pages/Courses/CourseList";
import AddCourse from "./pages/Courses/AddCourse";
import EditCourse from "./pages/Courses/EditCourse";
import CourseQuizList from "./pages/CourseQuiz/CourseQuizList";
import AddCourseQuiz from "./pages/CourseQuiz/AddCourseQuiz";
import UpdateCourseQuiz from "./pages/CourseQuiz/UpdateCourseQuiz";
import CreateQuestion from "./pages/CourseQuizQuestions/CreateQuestion";
import EditQuestion from "./pages/CourseQuizQuestions/EditQuestion";
import ImportQuestion from "./pages/CourseQuizQuestions/ImportQuestion";
import UserList from "./pages/Userlist";
import ServiceUser from "./pages/ServiceUser"
import EdpUser from "./pages/EdpUser"
import UserForm from "./pages/UserForm";
import EditAdmin from "./pages/EditAdmin";
import AddSeo from "./pages/Seo/AddSeo";
import EditSeo from "./pages/Seo/EditSeo";
import SeoIndex from "./pages/Seo";
import MemberShips from "./pages/MemberShip/Index";
import AddMemberShip from "./pages/MemberShip/Create";
import EditMemberShip from "./pages/MemberShip/Edit";
import MemberShipScope from "./pages/MemberShipScope/Index";
import AddMemberShipScope from "./pages/MemberShipScope/Create";
import EditMemberShipScope from "./pages/MemberShipScope/Edit";
import MemberShipReport from "./pages/MemberShipReport/Index";
import UserDetail from "./pages/UserDetail";
import EdpDetail from "./pages/EdpDetails";
import ServiceDetail from "./pages/ServiceDetail"
import ServiceSubCategories from "./pages/ServiceSubCategories/index"
import AddServiceSubCategory from "./pages/ServiceSubCategories/AddServiceSubCategory";
import UpdateServiceSubCategory from "./pages/ServiceSubCategories/UpdateServiceSubCategory";
import EnquiriesList from "./pages/EnquiriesList/enquiriesList";
const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/user" element={<UserList />} />
          <Route path="/service-user" element={<ServiceUser />} />
          <Route path="/edp-user" element={<EdpUser />} />
          <Route path="/user-form" element={<UserForm />} />
          <Route path="/create-user" element={<CreateAdmin />} />
          <Route path="/course-type" element={<CourseType />} />
          <Route path="/add-course-type" element={<AddCourseType />} />
          <Route path="/edit-course-type/:id" element={<UpdateCourseType />} />
          <Route path="/product" element={<ProdcutList />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/edit-product/:id" element={<UpdateProduct />} />
          <Route path="/edit-user/:id" element={<UpdateUser />} />
          <Route path="/view-detail-user/:id" element={<UserDetail />} />
          <Route path="/view-detail-edp/:id" element={<EdpDetail />} />
          <Route path="/view-detail-service/:id" element={<ServiceDetail />} />
          <Route path="/course-category" element={<CourseCategory />} />
          <Route path="/add-course-category" element={<AddCourseCategory />} />
          <Route
            path="/edit-course-category/:id"
            element={<UpdateCourseCategory />}
          />
          <Route path="/course-sub-category" element={<CourseSubcategory />} />
          <Route
            path="/add-course-sub-category"
            element={<AddCourseSubCategory />}
          />
          <Route
            path="/edit-course-sub-category/:id"
            element={<UpdateCourseSubCategory />}
          />
          <Route
            path="/course-section-module"
            element={<CourseSectionModule />}
          />
          <Route
            path="/add-course-section-module"
            element={<AddCourseSectionModule />}
          />
          <Route
            path="/edit-course-section-module/:id"
            element={<UpdateCourseSectionModule />}
          />
          <Route
            path="/course-sub-sub-category"
            element={<CourseSubSubCategory />}
          />
          <Route
            path="/add-course-sub-sub-category"
            element={<AddCourseSubSubCategory />}
          />
          <Route
            path="/edit-course-sub-sub-category/:id"
            element={<UpdateCourseSubSubCategory />}
          />
          <Route path="/create-role" element={<CreateRole />} />
          <Route path="/role" element={<Roles />} />
          <Route path="/categories" element={<Category />} />
          <Route path="/category/:id" element={<UpdateCategory />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/subcategory" element={<Subcategory />} />
          <Route path="/add-subcategory" element={<AddSubCategory />} />

          <Route path="/add-service" element={<ServiceCreate />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service/:id" element={<ServiceUpdate />} />

          <Route path="/add-service-category" element={<AddServiceCategory />} />
          <Route path="/service-categories" element={<ServicesCategories />} />
          <Route path="/service-category/:id" element={<UpdateServicesCategory />} />

          <Route
            path="/update-subcategory/:id"
            element={<UpdateSubCategory />}
          />
          <Route path="/sub-sub-category" element={<SubSubCategory />} />
          <Route path="/add-sub-sub-category" element={<AddSubSubCategory />} />
          <Route path="/services-sub-category" element={<ServiceSubCategories />} />
          <Route path="/add-services-sub-category" element={<AddServiceSubCategory />} />
          <Route path="/update-services-sub-category/:id" element={<UpdateServiceSubCategory />} />
          <Route
            path="/update-sub-sub-category/:id"
            element={<UpdateSubSubCategory />}
          />
          <Route path="/assign-permissions" element={<AssignPermissions />} />
          <Route path="/permissions" element={<Permissions />} />
          <Route path="/consultants" element={<ConsultantList />} />
          <Route path="/consultant-update/:id" element={<ConsultantUpdate />} />
          <Route path="/consultant-add" element={<ConsultantAdd />} />
          <Route path="/booking-list" element={<BookingList />} />
          <Route path="/withdrawal-requests" element={<Withdrawal />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/tax-queries" element={<TaxForm />} />
          <Route path="/payout-report" element={<PayoutReport />} />
          <Route path="/admin-users" element={<AdminUsers />} />
          <Route path="/admin-users/edit/:id" element={<EditAdmin />} />
          <Route path="/customize-settings" element={<ConsultancySettings />} />
          <Route path="/create-meeting" element={<CreateMeeting />} />
          <Route path="/meeting-list" element={<MeetingLis />} />
          <Route path="/add-expertvideos" element={<AddExpertVideos />} />
          <Route path="/expert-videos" element={<ExpertVideos />} />
          <Route
            path="/expert-videos-update/:id"
            element={<EditExpertVideos />}
          />
          <Route path="/offer-list" element={<Offerlist />} />
          <Route path="/add-offer" element={<AddOffer />} />
          <Route path="/offer-update/:id" element={<EditOffer />} />
          <Route path="/banner-list" element={<Bannerlist />} />
          <Route path="/add-banner" element={<AddBanner />} />
          <Route path="/banner-update/:id" element={<EditBanner />} />

          <Route path="/user-topups" element={<UserTopUp />} />
          <Route path="/course-module" element={<CourseModule />} />
          <Route path="/add-course-module" element={<AddCourseModule />} />
          <Route
            path="/edit-course-module/:id"
            element={<UpdateCourseModule />}
          />
          <Route path="/course-order" element={<CourseOrder />} />
          <Route path="/course-list" element={<CourseList />} />
          <Route path="/add-course" element={<AddCourse />} />
          <Route path="/course-edit/:id" element={<EditCourse />} />
          <Route path="/course-quiz-list" element={<CourseQuizList />} />
          <Route path="/add-course-quiz" element={<AddCourseQuiz />} />
          <Route
            path="/update-course-quiz/:id"
            element={<UpdateCourseQuiz />}
          />
          <Route
            path="/course-quiz-list/add-question/:id"
            element={<CreateQuestion />}
          />
          <Route path="/edit-question/:id" element={<EditQuestion />} />
          <Route path="/import-question/:id" element={<ImportQuestion />} />
          <Route path="/seo-list" element={<SeoIndex />} />
          <Route path="/add-seo" element={<AddSeo />} />
          <Route path="/edit-seo/:id" element={<EditSeo />} />
          <Route path="*" element={<h2>Not Found</h2>} />
          <Route path="/memberships" element={<MemberShips />} />
          <Route path="/add-membership" element={<AddMemberShip />} />
          <Route path="/edit-membership/:id" element={<EditMemberShip />} />
          <Route path="/membership-scope" element={<MemberShipScope />} />
          <Route path="/add-membership-scope" element={<AddMemberShipScope />} />
          <Route path="/edit-membership-scope/:id" element={<EditMemberShipScope />} />
          <Route path="/membership-report" element={<MemberShipReport />} />
          <Route path="/enquiries" element={<EnquiriesList />} />
        </Route>
        <Route path="/meeting" element={<MeetingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/leave-review" element={<MeetingReview />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
