import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getSubCategories,
  getCategories,
} from "../../redux/actions/indexActions";
import { toast } from "react-toastify";
import {
  getCourseCatgeory,
  getCourseSubCatgeory,
  getCourseSectionModuleList,
} from "../../redux/actions/CourseAction";
const UpdateCourseModule = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    course_id: "",
    section_id: "",
    title: "",
    hindi_title: "",
    url: "",
    hindi_url: "",
    duration: "",
    duration_hindi: "",
    content_type: "",
    total_pages: "",
    total_pages_hindi: "",
    thumbnail: "",
    thumbnail_hindi: "",
    file: "",
    file_hindi: "",
    type: "",
    is_preview: "",
    source_code: "",
    priority: "",
    meeting_id: "",
    quiz_id: "",
    description: "",
    status: 1,
  });
  const [errors, setErrors] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [courseSection, setCourseSection] = useState([]);
  const {
    AuthReducer: { auth },
    CourseReducer: {
      courseCategories,
      courseSubCategories,
      courseSectionModuleList,
    },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    // if ( 
    // name === "thumbnail" ||
    // name === "thumbnail_hindi" ||
    // name === "file" ||
    // name === "file_hindi" ||
    // name === "source_code") {
    //   setFormData({ ...formData, [name]: e.target.files[0] });
    // }
    if (name === "status" || name === "home_page" || name === "courses") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  console.log(formData)
  const handleSubmit = () => {
    
    const form = new FormData();
    for (const key in formData) {
      if (key === "categoryId") {
        form.append("categoryTypeId", formData[key]);
      }
      form.append([key], formData[key]);
    }

    axios
      .put(`/api/admin/v1/course-module/${id}`, form, {
        headers: {
         // "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data);
      });
  };

  useEffect(() => {
    dispatch(getCourseSectionModuleList(auth.token));
    axios(`/api/admin/v1/course-module-edit/${id}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        setFormData((prev) => ({
          ...prev,
          course_id: res.data?.CourseModules?.course_id,
          section_id: res.data?.CourseModules?.section_id,
          title: res.data?.CourseModules?.title,
          hindi_title: res.data?.CourseModules?.hindi_title,
          url: res.data?.CourseModules?.url,
          hindi_url: res.data?.CourseModules?.hindi_url,
          duration: res.data?.CourseModules?.duration,
          duration_hindi: res.data?.CourseModules?.duration_hindi,
          content_type: res.data?.CourseModules?.content_type,
          total_pages: res.data?.CourseModules?.total_pages,
          total_pages_hindi: res.data?.CourseModules?.total_pages_hindi,
          thumbnail: res.data?.CourseModules?.thumbnail,
          thumbnail_hindi: res.data?.CourseModules?.thumbnail_hindi,
          file: res.data?.CourseModules?.file,
          file_hindi: res.data?.CourseModules?.file_hindi,
          is_preview: res.data?.CourseModules?.is_preview,
          source_code: res.data?.CourseModules?.source_code,
          description: res.data?.CourseModules?.description,
          priority: res.data?.CourseModules?.priority,
          quiz_id: res.data?.CourseModules?.quiz_id,
          meeting_id: res.data?.CourseModules?.meeting_id,
          type: res.data?.CourseModules?.type,
          status: res.data?.CourseModules?.status,
        }));
      })
      .catch((err) => console.log(err));
  }, [auth]);
  useEffect(() => {
    if (formData.course_id) {
      axios(`/api/mobile/v1/course-sectoion-list/${formData.course_id}`)
        .then(({ data }) => {
          setCourseSection(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setCourseSection([]);
    }
  }, [formData.course_id]);
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Edit Course Module</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Course <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                className="text-black"
                value={formData?.course_id}
                type="select"
                name="course_id"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {courseSectionModuleList?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.title}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{errors?.course_id}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Course Section <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                className="text-black"
                value={formData?.section_id}
                type="select"
                name="section_id"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {courseSection?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">{errors?.section_id}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title English <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.title}
                name="title"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.title}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Title Hindi</Label>
              <Input
                value={formData?.hindi_title}
                name="hindi_title"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>URL</Label>
              <Input
                value={formData?.url}
                name="url"
                onChange={inputHandler}
                invalid={errors?.name}
              />
              <span className="d-block text-danger">{errors?.url}</span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Url</Label>
              <Input
                value={formData?.hindi_url}
                name="hindi_url"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Duration(please enter in minutes) </Label>
              <Input
                name="duration"
                value={formData?.duration}
                type="integer"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.duration}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Duration(please enter in minutes)</Label>
              <Input
                name="duration_hindi"
                value={formData?.duration_hindi}
                type="integer"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Total Pages </Label>
              <Input
                name="total_pages"
                value={formData?.total_pages}
                type="integer"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.total_pages}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Total Pages Hindi</Label>
              <Input
                name="total_pages_hindi"
                value={formData?.total_pages_hindi}
                type="integer"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Content Type <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                className="text-black"
                value={formData?.content_type}
                type="select"
                name="content_type"
                onChange={inputHandler}
              >
                <option value="">Select Content Type</option>
                <option value="Video">Video</option>
                <option value="Document">Document</option>
                <option value="Quiz">Quiz</option>
              </Input>
              <span className="d-block text-danger">
                {errors?.content_type}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Thumbnail</Label>
              <Input
                type="file"
                name="thumbnail"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    thumbnail: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Thumbnail Hindi</Label>
              <Input
                type="file"
                name="thumbnail_hindi"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    thumbnail_hindi: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>File</Label>
              <Input
                type="file"
                name="file"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    file: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>File Hindi</Label>
              <Input
                type="file"
                name="file_hindi"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    file_hindi: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label className="text-black">
                Select Type <span className="text-danger fs-4">*</span>
              </Label>
              <Input
                value={formData?.type}
                type="select"
                name="type"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                <option value="Free">Free</option>
                <option value="Paid">Paid</option>
              </Input>
              <span className="d-block text-danger">{errors?.type}</span>
            </FormGroup>
          </div>
          {/* {formData.type === "Paid" ? (
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label className="text-black">Amount</Label>
              <Input  value={formData?.price} name="price" onChange={inputHandler} />
            </FormGroup>
          </div>
        ) : null} */}
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Meeting ID</Label>
              <Input
                value={formData?.meeting_id}
                type="integer"
                name="meeting_id"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Priority</Label>
              <Input
                value={formData?.priority}
                name="priority"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Is Preview</Label>
              <Input
                value={formData?.is_preview}
                name="is_preview"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Source Code (Only Pdf Or Docs)</Label>
              <Input
                type="file"
                name="source_code"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    source_code: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Quiz Id </Label>
              <Input
                value={formData?.quiz_id}
                name="quiz_id"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">{errors?.quiz_id}</span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Description</Label>
              <Input
                value={formData?.description}
                name="description"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCourseModule;
