import React, { memo } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap'
const MultiInput = ({ fields, index, setFormData, formData, }) => {

    const handleChange = (e) => {
        const { name, value } = e.target
        const newFields = { ...formData }
        newFields.educationDetail[index][name] = value
        setFormData(newFields)
    }
    const handleRemove = () => {
        setFormData(prev => ({ ...prev, educationDetail: prev.educationDetail.filter((_, ind) => ind !== index) }))
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    <FormGroup>
                        <Label>
                            College
                        </Label>
                        <Input name="college" value={fields.college} onChange={handleChange} />
                    </FormGroup>
                </div>
                <div className="col-md-4">
                    <FormGroup>
                        <Label>
                            Passing Year
                        </Label>
                        <Input name="passingYear" value={fields.passingYear} onChange={(e) => !isNaN(e.target.value) && handleChange(e)} />
                    </FormGroup>
                </div>
                <div className="col-md-4">
                    <div className="d-flex align-items-start">

                        <div  >
                            <Label>
                                Degree
                            </Label>
                            <Input name='degree' value={fields.degree} onChange={handleChange} />
                        </div>
                        {Boolean(index) && <Button color='danger' className='ms-1' size='sm' onClick={handleRemove} >
                            -
                        </Button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(MultiInput)