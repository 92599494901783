import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getServiceCategories,
} from "../../redux/actions/indexActions";
import { toast } from "react-toastify";

const AddServiceSubCategory = () => {
  const [formData, setFormData] = useState({
    service_category_id: "",
    name: "",
    description: "",
    status: 1,
  });
  const [errors, setErrors] = useState({});
  const {
    AuthReducer: { auth },
    indexReducer: { categories },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      form.append([key], formData[key]);
    }
    axios
      .post(`/api/admin/v1/service/sub/category`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate("/services-sub-category");
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data.errors);
      });
  };

  useEffect(() => {
    dispatch(getServiceCategories(auth.token));
  }, [auth]);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Service Sub Category</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Name <span className="text-danger">*</span>
              </Label>
              <Input
                invalid={Boolean(errors?.name)}
                value={formData?.name}
                name="name"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.name}{" "}
              </span>
            </FormGroup>
          </div>

     
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Select Category <span className="text-danger">*</span>
              </Label>
              <Input
                value={formData?.service_category_id}
                type="select"
                name="service_category_id"
                onChange={inputHandler}
                invalid={errors.service_category_id}
              >
                <option value="">Select</option>
                {categories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">
                {errors?.service_category_id}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Description<span className="text-danger">*</span>
              </Label>
              <Input
                name="description"
                value={formData?.description}
                type="textarea"
                onChange={inputHandler}
                invalid={errors.description}
              />
              <p className="text-danger">{errors.description}</p>
            </FormGroup>
          </div>
      

          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddServiceSubCategory;
