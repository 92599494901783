import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import { FcGallery } from "react-icons/fc";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { getProduct } from "../../redux/actions/ProductAction";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const ProdcutList = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    ProductReducer: { products, loading, productCount },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImages] = useState([]);
  const [data, setData] = useState({});
  useEffect(() => {
    dispatch(getProduct(auth?.token, paginate, page, search, sort));
  }, [auth, paginate, page, search, sort]);
  const toggle = () => setIsOpen((prev) => !prev);
  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Product")) {
      axios
        .delete(`/api/admin/v1/product-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getProduct(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  const handleDelproductImage = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Product")) {
      axios
        .delete(`/api/admin/v1/product-image-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getProduct(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      // Convert the FileList to an array of file objects
      const fileList = Array.from(files);

      // Update the state with the array of file objects
      setImages(fileList);
    }
  };

  const saveImage = () => {
    const formData = new FormData();
    image.forEach((file, index) => {
      formData.append(`images`, file);
    });
    formData.append("productId", data?.id);
    // formData.append("userId", auth.id);
    axios
      .post("/api/admin/v1/upload/product-image", formData, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        toast.success(data?.data?.message);
        navigate("/product");
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  };
  console.log(productCount, "productCount");
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Products</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "PRODUCTS" &&
                item?.permission?.permission === "create"
            ) && (
              <Link to="/add-product">
                <Button
                  className="bg-white text-black"
                  style={{ color: "primary" }}
                  size="sm"
                >
                  <span className="me-2">
                    <MdAdd size={22} />
                  </span>
                  Add Products
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-3 mb-2 ">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Thumbnail</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        (item?.permission?.moduleType === "CATEGORY" &&
                          item?.permission?.permission === "edit") ||
                        item?.permission?.permission === "delete"
                    ) && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {products?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + page * 10}</th>
                        <td>{item?.product_name_english}</td>
                        <td>{item?.description_english}</td>
                        <td>
                          <img
                            src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${item?.avatar_english}`}
                            height={45}
                            width={45}
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <FcGallery
                              size={22}
                              className="me-2 cursor-pointer"
                              onClick={() => {
                                setIsOpen(!isOpen);
                                setData(item);
                              }}
                            />
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "PRODUCTS" &&
                                item?.permission?.permission === "edit"
                            ) && (
                              <Link to={`/edit-product/${item?.id}`}>
                                <AiOutlineEdit
                                  size={25}
                                  className="text-warning me-3"
                                />
                              </Link>
                            )}
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType === "PRODUCTS" &&
                                item?.permission?.permission === "delete"
                            ) && (
                              <AiOutlineDelete
                                size={22}
                                className="text-danger"
                                onClick={() => handleDel(item.id)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <Modal isOpen={isOpen} toggle={toggle} size="lg" data={data}>
          <ModalHeader toggle={toggle}>Product Images </ModalHeader>
          <ModalBody>
            <div className="row ">
              <div className="col-md-6">
                <Input
                  size={"sm"}
                  // You might want to change this depending on how you want to handle the
                  type="file"
                  multiple
                  onChange={handleImageChange}
                />
              </div>
              {console.log(data?.product_images, "data11")}
              <div className="col-md-6 d-flex justify-content-end">
                <Button
                  className="bg-white text-black px-3 py-2"
                  style={{ color: "primary" }}
                  size="sm"
                  onClick={saveImage}
                >
                  Save Image
                </Button>
              </div>
            </div>
            <Table hover responsive className="mt-2">
              <thead>
                <tr>
                  <th>SL. No.</th>
                  <th>Image</th>

                  <th>Action</th>
                </tr>
              </thead>
              {loading ? (
                <Spinner />
              ) : (
                <tbody>
                  {data.product_images?.map?.((items, index) => {
                    return (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <img
                            src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${items?.product_english}`}
                            height={45}
                            width={45}
                          />
                        </td>

                        <td>
                          <AiOutlineDelete
                            size={22}
                            onClick={() => handleDelproductImage(items.id)}
                            className="text-danger"
                          />
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {productCount}
            </div>
          </div>
          <Pagination
            pageCount={productCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProdcutList;
