import React, { useState, useEffect } from 'react'
import { Input, FormGroup, Label, Button } from 'reactstrap'
import { phoneValid } from '../utils'
import { getRoles } from '../redux/actions/indexActions'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify"
const EditAdmin = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { AuthReducer: { auth }, indexReducer: { roles } } = useSelector(state => state)
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        role_id: "",
        status: 1,
    })

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    useEffect(() => {
        axios(`/api/admin/v1/admin/edit/${id}`, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        })
            .then((res) => {
                setFormData({
                    name: res.data?.admin?.name,
                    email: res.data?.admin?.email,
                    mobile: res.data?.admin?.mobile,
                    role_id: res.data?.admin?.role_id,
                    status: res.data?.admin?.status ? true : false,
                });
            })
            .catch((err) => console.log(err));
    }, [auth]);

    const handleSubmit = () => {
        const form = new FormData()
        for (const key in formData) {
            form.append([key], formData[key])
        }
        axios.put(`/api/admin/v1/admin/update/${id}`, form, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`
            }
        }).then((res) => {
            toast.success(res.data?.message)
            navigate(-1)
        }
        ).catch((err) => {
            toast.error(err.response.data);
            setErrors(err.response.data);
        })
    }


    useEffect(() => {
        dispatch(getRoles(auth?.token))
    }, [auth])

    return (
        <div className='bg-white px-3 py-5 rounded-2  my-4' >
            <div className="row gy-4 ">
                <div className="col-md-8 offset-md-1">
                    <h5>Update Admin User</h5>
                </div>
                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Name
                        </Label>
                        <Input value={formData?.name} invalid={Boolean(errors?.name)} onChange={inputHandler} name="name" />
                        <p className='text-danger' >
                            {errors?.name}
                        </p>
                    </FormGroup>
                </div>

                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Email
                        </Label>
                        <Input value={formData?.email} invalid={Boolean(errors?.email)} onChange={inputHandler} name="email" />
                        <p className='text-danger' >
                            {errors?.email}
                        </p>
                    </FormGroup>
                </div>

                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Mobile
                        </Label>
                        <Input value={formData?.mobile} invalid={Boolean(errors?.mobile)} onChange={(e) => phoneValid(e.target.value) && inputHandler(e)} name="mobile" />
                        <p className='text-danger' >
                            {errors?.mobile}
                        </p>
                    </FormGroup>
                </div>


                <div className="col-md-4 offset-md-1">
                    <FormGroup>
                        <Label>
                            Select Role
                        </Label>
                        <Input type='select' invalid={Boolean(errors?.role)} value={formData?.role_id} onChange={inputHandler} name="role_id" >
                            <option value="">Select</option>
                            {
                                roles?.map?.((item) => {
                                    return <option value={item?.id} key={item.id} >{item?.name}</option>
                                })
                            }
                        </Input>
                        <p className='text-danger' >
                            {errors?.role}
                        </p>
                    </FormGroup>
                </div>
                <div className="col-md-4 offset-md-1">
                    <FormGroup switch>
                        <Input
                            type="switch"
                            checked={formData?.status}
                            onChange={inputHandler}
                            name="status"
                        />
                        <Label check>Status</Label>
                    </FormGroup>
                </div>
                <div className="col-md-4 offset-md-4">
                    <Button color='primary' onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button className='ms-3' onClick={() => navigate(-1)} >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EditAdmin