import React, { useEffect } from "react";
import firepadRef, { db, userName } from "../redux/server/firebase";
import {
    setMainStream,
    addParticipant,
    setUser,
    removeParticipant,
    updateParticipant,
} from "../redux/actions/MeetingAction";
import { useSelector, useDispatch } from "react-redux"
import MainScreen from "../Components/Meeting/MainScreen";
import "../Components/Meeting/Meeting.css"

const MeetingPage = () => {
    const dispatch = useDispatch();
    const { mainStream, currentUser } = useSelector((state) => state.meetingReducer)
    const getUserStream = async () => {
        const localStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
        });
        return localStream;
    };
    const connectedRef = db.database().ref(".info/connected");
    const participantRef = firepadRef.child("participants");

    useEffect(() => {
        (async () => {
            const stream = await getUserStream();
            stream.getVideoTracks()[0].enabled = false;
            dispatch(setMainStream(stream));
            connectedRef.on("value", (snap) => {

                if (snap.val()) {
                    const defaultPreference = {
                        audio: true,
                        video: false,
                        screen: false,
                    };
                    if (userName != undefined && userName != null) {
                        const userStatusRef = participantRef.push({
                            userName,
                            preferences: defaultPreference,
                        });
                        dispatch(setUser({
                            [userStatusRef.key]: { name: userName, ...defaultPreference },
                        }));
                        userStatusRef.onDisconnect().remove();
                    }

                }
            });
        })();

    }, [])
    const isUserSet = !!currentUser;
    const isStreamSet = !!mainStream;
    useEffect(() => {
        if (isStreamSet && isUserSet) {
            participantRef.on("child_added", (snap) => {
                const preferenceUpdateEvent = participantRef
                    .child(snap.key)
                    .child("preferences");
                preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
                    dispatch(updateParticipant({
                        [snap.key]: {
                            [preferenceSnap.key]: preferenceSnap.val(),
                        },
                    }));
                });
                const { userName: name, preferences = {} } = snap.val();
                if (name != undefined && name != null) {
                    dispatch(addParticipant({
                        [snap.key]: {
                            name,
                            ...preferences,
                        },
                    }));
                }

            });

            participantRef.on("child_removed", (snap) => {
                dispatch(removeParticipant(snap.key));
            });
        }
    }, [isStreamSet, isUserSet])
    return (
        <MainScreen />
    )
}

export default MeetingPage;