import React, { useState, useEffect, useDebugValue } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormFeedback, Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { MdAdd } from "react-icons/md";
import { Spinner } from "reactstrap";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  getCategories,
  getSubCategories,
} from "../../redux/actions/indexActions";
import { removeDupes } from "../../utils";
import moment from "moment/moment";
import Pagination from "../../Components/Paginate";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";

const BookingList = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    consultant_id: "",
    user_id: "",
    sector_id: "",
    industry_id: "",
  });
  const [bookingList, setBookingList] = useState([]);
  const [bookingListcount, setBookingListCount] = useState([]);

  const [dateSlote, setDateSlote] = useState([]);
  const [datetimeSlote, setDateTimeSlote] = useState([]);
  const [sectorConsultant, setSectorConsultant] = useState([]);
  const [consultantIndustry, setConsultantIndustry] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [dateID, setDateID] = useState("");
  const [time, setTime] = useState("");
  const [modal, setModal] = useState(false);
  const [consultancyType, setConsultancyType] = useState("");
  const [userWalletAmount, setuserWalletAmount] = useState(0);
  const [consultantFee, setConsultantFee] = useState({});
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [paginate, setPaginate] = useState(10);
  const [sort, setSort] = useState("desc");
  const [type, setType] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subSubSubCategoryFilter, setSubSubCategoryFilter] = useState("");
  const [subCategoriess, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const {
    AuthReducer: { auth },
    indexReducer: { categories, loading },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "sector_id") {
      console.log(consultantIndustry.filter((item) => item));
      setIndustryOptions(
        consultantIndustry.filter(
          (item) => item.subCategory === parseInt(value)
        )
      );
    }
  };
  const getBookingList = (
    limit,
    page,
    search = "",
    sort = "",
    categoryFilter = "",
    subCategoryFilter = "",
    subSubSubCategoryFilter = "",
    type = ""
  ) => {
    axios
      .get(
        `/api/admin/v1/get-allappointments?limit=${limit || 10}&page=${page || 0
        }&search=${search}&sort=${sort}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}&subSubCategoryFilter=${subSubSubCategoryFilter}&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      )
      .then((res) => {
        setBookingList(res?.data?.data);
        setBookingListCount(res?.data?.BookAppointmentCount);
      })
      .catch((err) => toast.error("Somthing Went Wrong"));
  };
  useEffect(() => {
    getBookingList(
      paginate,
      page,
      search,
      sort,
      categoryFilter,
      subCategoryFilter,
      subSubSubCategoryFilter,
      type
    );
    axios
      .get(`/api/admin/v1/get-all-consultants`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setConsultantList(res?.data?.consultants);
      })
      .catch((err) => toast.error("Somthing Went Wrong"));

    axios
      .get(`/api/admin/v1/get-users`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setUserList(res?.data?.users);
      })
      .catch((err) => toast.error("Somthing Went Wrong"));
  }, [
    auth?.token,
    paginate,
    page,
    search,
    sort,
    categoryFilter,
    subCategoryFilter,
    subSubSubCategoryFilter,
    type,
  ]);
  useEffect(() => {
    if (categoryFilter) {
      // setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${categoryFilter}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [categoryFilter]);

  useEffect(() => {
    if (subCategoryFilter) {
      axios(`/api/web/v1/get-industry-type/${subCategoryFilter}`)
        .then(({ data }) => {
          setSubSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubSubCategories([]);
    }
  }, [subCategoryFilter]);
  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);
  useEffect(() => {
    if (formData.consultant_id) {
      axios
        .get(`/api/admin/v1/get-date-slote/${formData.consultant_id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          setDateSlote(res?.data?.consultantSlotDates);
        })
        .catch((err) => toast.error("Somthing Went Wrong"));

      axios
        .get(`/api/admin/v1/sectors/${formData.consultant_id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          setSectorConsultant(
            removeDupes(
              res?.data?.data?.map((item) => ({
                id: item?.subCategory?.id,
                label: item?.subCategory?.name_english,
              }))
            )
          );
          const industries = res?.data?.data?.map((item) => ({
            id: item?.subSubCategory?.id,
            label: item?.subSubCategory?.name_english,
            subCategory: item?.subSubCategory?.subCategoryId,
          }));
          setConsultantIndustry(industries);
          setIndustryOptions(industries);
        })
        .catch((err) => toast.error("Somthing Went Wrong"));

      axios(`/api/admin/v1/get-consultant-fee/${formData.consultant_id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then(({ data }) => {
          setConsultantFee(data?.fees);
        })
        .catch((err) => {
          toast.error("Something Failed");
          console.log(err);
        });
    }

    setTime("");
    setDateID("");
    setConsultancyType("");
    setDateTimeSlote([]);
  }, [auth?.token, formData.consultant_id]);

  useEffect(() => {
    if (formData.user_id) {
      axios(`/api/admin/v1/get-user-wallet-amount/${formData.user_id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
        .then(({ data }) => {
          const amount =
            (data?.getCreditAmount?.amount || 0) -
            (data?.debitAmount?.amount || 0);
          setuserWalletAmount(amount);
        })
        .catch((err) => console.log(err));
    }
  }, [formData.user_id]);

  const setSlot = (id) => {
    {
      if (id !== dateID) {
        setDateID(id);
        axios
          .get(`/api/admin/v1/get-date-time-slote/${id}`, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          })
          .then((res) => {
            setDateTimeSlote(res?.data?.consultantDateTime);
            setTime("");
          })
          .catch((err) => toast.error("Somthing Went Wrong"));
      }
    }
  };

  const handleAmountValid = () => {
    if (consultancyType === "audio") {
      if (userWalletAmount >= consultantFee?.audioFee) {
        return true;
      } else {
        return false;
      }
    } else {
      if (userWalletAmount >= consultantFee?.videoFee) {
        return true;
      } else {
        return false;
      }
    }
  };
  const handleSubmit = () => {
    if (handleAmountValid()) {
      axios
        .post(
          `/api/admin/v1/add-bookAppointment`,
          {
            ...formData,
            slotDateId: dateID,
            slotTimeId: time,
            consultancyType,
            amount:
              consultancyType === "audio"
                ? consultantFee?.audioFee
                : consultantFee?.videoFee,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data?.message);
          setModal(false);
          setFormData({
            consultant_id: "",
            user_id: "",
            sector_id: "",
            industry_id: "",
          });
          setTime("");
          setDateID("");
          setConsultancyType("");
          setDateSlote([]);
          setDateTimeSlote([]);
          getBookingList();
        })
        .catch((err) => toast.error(err.response.data));
    } else {
      toast.error("User Wallet Amount is not enough ");
    }
  };

  const toggle = () => {
    if (modal) {
      setModal((prev) => !prev);
      setFormData({
        consultant_id: "",
        user_id: "",
        sector_id: "",
        industry_id: "",
      });
      setTime("");
      setDateID("");
      setConsultancyType("");
      setDateSlote([]);
      setDateTimeSlote([]);
    } else {
      setModal(!modal);
    }
  };

  const csvHead = [
    [
      "name",
      "consultant",
      "sector",
      "industry",
      "date",
      "start time",
      "end time",
      "amount",
      "consultancy type",
      "status",
    ],
  ];
  const csvData = bookingList.map((cv) => [
    cv?.user?.name,
    cv?.consultant?.name,
    cv?.subCategory?.name_english,
    cv?.subSubCategory?.name_english,
    cv?.consultant_slote_date?.dateIndex,
    cv?.consultant_slot?.startTime,
    cv?.consultant_slot?.endTime,
    cv?.amount,
    cv?.consultancyType,
    cv?.isUsed,
  ]);
  const exportData = csvHead.concat(csvData);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-cetner justify-content-between">
            <h5 className="text-white">Booking List</h5>
            {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "BOOKINGLIST" &&
              item?.permission?.permission === "create") && (
                <div>
                  <Button
                    className="bg-white text-black"
                    size="sm"
                    onClick={toggle}
                  >
                    <span className="me-2">
                      <MdAdd size={22} />
                    </span>
                    Add Booking
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-2 mb-2">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={categoryFilter}
              type="select"
              name="categoryId"
              onChange={({ target }) => setCategoryFilter(target.value)}
            >
              <option value="">Select Category</option>
              {categories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.type}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subCategoryFilter}
              type="select"
              name="subCategoryId"
              onChange={({ target }) => setSubCategoryFilter(target.value)}
            >
              <option value="">Select Segment</option>
              {subCategoriess?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={subSubSubCategoryFilter}
              type="select"
              name="subSubCategoryId"
              onChange={({ target }) => setSubSubCategoryFilter(target.value)}
            >
              <option value="">Select Expertise</option>
              {subSubCategories?.map?.((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item?.name_english}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-md-2">
            <Input
              size={"sm"}
              value={type}
              type="select"
              name="type"
              onChange={({ target }) => setType(target.value)}
            >
              <option value="">Consultancy Type</option>
              <option value="video">Video</option>
              <option value="audio">Audio</option>
            </Input>
          </div>
          <div className="col-md-2 ">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.((item) => item?.permission?.moduleType === "BOOKINGLIST" &&
            item?.permission?.permission === "export") && (
              <div className="col-md-1 mb-2">
                <CSVLink
                  filename={"booking-lists.csv"}
                  className="text-black ms-3"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>User Name</th>
                    <th className="text-center">Consultant Name</th>
                    <th>Sector</th>
                    <th>Industry</th>
                    <th>Slot Date</th>
                    <th className="text-center">Slot Start Time</th>
                    <th className="text-center">Slot End Time</th>
                    <th>Amount</th>
                    <th className="text-center">Consultancy Type</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {bookingList?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row"> {index + 1 + page * 10}</th>
                        <td>{item?.user?.name}</td>
                        <td>{item?.consultant?.name}</td>
                        <td>{item?.subCategory?.name_english}</td>
                        <td>{item?.subSubCategory?.name_english}</td>
                        <td>{item?.consultant_slote_date?.dateIndex}</td>
                        <td>{item?.consultant_slot?.startTime}</td>
                        <td>{item?.consultant_slot?.endTime}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.consultancyType}</td>
                        {item?.isUsed ? (
                          <td>Completed</td>
                        ) : (
                          <td> Not Completed</td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Booking</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Select User</Label>
              <Input
                value={formData.user_id}
                onChange={handleChange}
                name="user_id"
                type="select"
              >
                <option value="">Select</option>
                {userList?.map?.((u, index) => {
                  return (
                    <option value={u?.id} key={u.id}>
                      {u?.name}
                    </option>
                  );
                })}
              </Input>
              {formData.user_id && (
                <span className="d-block text-success">
                  {" "}
                  User Wallet Amount : {userWalletAmount}{" "}
                </span>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Select Consultant</Label>

              <Input
                value={formData.consultant_id}
                onChange={handleChange}
                name="consultant_id"
                type="select"
              >
                <option value="">Select</option>
                {consultantList?.map?.((item, index) => {
                  return (
                    <option value={item?.id} key={item.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
              {formData.consultant_id && (
                <span className="block mt-2 text-success">
                  Audio Fee: {consultantFee?.audioFee} Video Fee :{" "}
                  {consultantFee?.videoFee}
                </span>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Select Sector</Label>

              <Input
                value={formData.sector_id}
                onChange={handleChange}
                name="sector_id"
                type="select"
              >
                <option value="">Select</option>
                {sectorConsultant.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.label}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Select Industry</Label>

              <Input
                value={formData.industry_id}
                onChange={handleChange}
                name="industry_id"
                type="select"
              >
                <option value="">Select</option>
                {industryOptions.map((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.label}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>

            <div>
              <h6 className="mb-4">Choose Date</h6>
              <div className="row gy-3">
                {dateSlote.map((item) => {
                  return (
                    <div className="col-md-4" key={item.id}>
                      <div
                        className={`border   d-flex align-items-center justify-content-center  rounded-2 py-3 text-sm ${dateID == item.id
                          ? "text-white bg-primary"
                          : "text-primary  border-primary"
                          }`}
                        role="button"
                        name="slotDateId"
                        onClick={() => setSlot(item.id)}
                      >
                        {item?.dateIndex}
                      </div>
                    </div>
                  );
                })}
              </div>

              <h6 className="my-2">Choose Slot</h6>

              <div className="row gy-3">
                {datetimeSlote.map((item) => {
                  return (
                    <div className="col-md-4" key={item.id}>
                      <div
                        className={`border  d-flex align-items-center justify-content-center  rounded-2 py-3 ${time == item.id
                          ? "text-white bg-primary"
                          : "text-primary  border-primary"
                          } text-sm `}
                        name="slotTimeId"
                        id="slotTimeId"
                        role="button"
                        onClick={() => setTime(item.id)}
                      >
                        <small style={{ fontSize: 12 }}>
                          {moment(item?.startTime, ["HH.mm"]).format("hh:mm a")}{" "}
                          : {moment(item?.endTime, ["HH.mm"]).format("hh:mm a")}
                        </small>
                      </div>
                    </div>
                  );
                })}
              </div>

              {time && (
                <div>
                  <h6 className="my-4">Consultancy Type</h6>
                  <div className="row gy-3">
                    <div className="col-md-3">
                      <div
                        className={`border  d-flex align-items-center justify-content-center  rounded-2 py-2 ${consultancyType === "audio"
                          ? "text-white bg-primary"
                          : "text-primary  border-primary"
                          } text-sm `}
                        role="button"
                        onClick={() => setConsultancyType("audio")}
                      >
                        Audio
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className={`border  d-flex align-items-center justify-content-center  rounded-2 py-2 ${consultancyType === "video"
                          ? "text-white bg-primary"
                          : "text-primary  border-primary"
                          } text-sm `}
                        role="button"
                        onClick={() => setConsultancyType("video")}
                      >
                        Video
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={
                !formData.user_id ||
                !formData.consultant_id ||
                !formData.industry_id ||
                !formData.sector_id ||
                !time ||
                !dateID ||
                !consultancyType
              }
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
          <Pagination
            pageCount={
              search
                ? bookingList.length / paginate
                : bookingListcount / paginate
            }
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingList;
