import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Input, FormGroup, Label, Button } from 'reactstrap'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
const CreateRole = () => {
    const [formData, setFormData] = useState({
        name: "",
        status: 1
    })
    const [errors, setError] = useState({})
    const {
        AuthReducer: { auth },
    } = useSelector((state) => state);
    const navigate = useNavigate()
    const inputHandler = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handlSubmit = () => {
        const form = new FormData();
        for (const key in formData) {
            form.append([key], formData[key]);
        }
        axios.post(`/api/admin/v1/role-create`, form, {
            headers: {
                Authorization: `Bearer ${auth.token}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                toast.success("Role Created Successfully")
                // setFormData({
                //     status: 1,
                //     name: ""
                // })
            }).catch((err) => {
                toast.error(err.response.data.message)
                setError(err.response.data)
            })
    }
    return (
        <div>
            <div className='row my-3'>
                <div className='col-md-12'>
                    <h5 className='text-white'>Add Role</h5>
                </div>
            </div>
            <div className='bg-white px-3 py-5 rounded-2  my-4' >
                <div className="row gy-4 ">
                    <div className="col-md-4 offset-md-4">
                        <FormGroup>
                            <Label>
                                Role  Name
                            </Label>
                            <Input value={formData.name} invalid={Boolean(errors?.name)} onChange={inputHandler} name="name" />
                            <p className='text-danger' >
                                {errors?.name}
                            </p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color='primary' onClick={handlSubmit}>
                            Submit
                        </Button>
                        <Button className='ms-3' onClick={() => navigate(-1)}  >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateRole