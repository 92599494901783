import axios from "axios";

export const getDashboardCount =
  (token, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: "GET_CONSULTANT_LIST" });
      const response = await axios(
        `/api/admin/v1/get-dashboard-count?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_DASHBOARDCOUNT_LIST_FULFILLED",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_DASHBOARDCOUNT_LIST_REJECTED",
        payload: error.response.data,
      });
    }
  };
