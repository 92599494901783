import React, { Fragment, useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  Table,
  FormText,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Components/Paginate";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { MdAdd, MdHorizontalRule } from "react-icons/md";
import { getCourseQuizes } from "../../redux/actions/CourseAction";
import { getCourseQuizQuestions } from "../../redux/actions/CourseAction";
import ImportQuestion from "./ImportQuestion";

const CreateQuestion = () => {
  const geturl = window.location.pathname.split("/");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [paginate, setPaginate] = useState(20);
  const [refresh, setRefresh] = useState(0);
  const [questionData, setQuestionData] = useState([
    { answer: "", image: "null", correct: "false" },
  ]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteIds, setDeleteIds] = useState([]);
  const [quiz, setQuiz] = useState("");
  const [status, setStatus] = useState(1);

  const [quizQuestions, setQuizQuestions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    CourseReducer: { CourseQuizQuestions, CourseQuizQuestionsCount },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);

  useEffect(() => {
    axios
      .get(`/api/admin/v1/course-quiz-detail/${geturl[3]}`)
      .then((res) => {
        setQuiz(res.data.CourseQuizes);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(getCourseQuizQuestions(auth?.token, paginate, page, id));
  }, [auth, paginate, page, id]);
  // useEffect(() => {
  //     setLoading(true);
  //     axios
  //         .get(
  //             `/api/admin/v1/quiz-questions-by-course?id=${geturl[3]}&page=${page}&search=${search}`
  //         )
  //         .then(res => {
  //             console.log(res);
  //             setQuizQuestions(res.data.question.data);
  //             setLoading(false);
  //         })
  //         .catch(err => {
  //             setLoading(false);
  //             toast.error(err.response.data.message);
  //         });
  // }, [page, refresh, search]);

  const handleRemoveClickQuestion = (index) => {
    const list = [...questionData];
    list.splice(index, 1);
    setQuestionData(list);
  };
  console.log(quiz.id, "quiz");
  // handle click event of the Add button
  const handleAddClickQuestion = () => {
    setQuestionData([
      ...questionData,
      { answer: "", image: "null", correct: "false" },
    ]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name == "corrects") {
      const re = /^[0-9\b]+$/;
      if (value === "" || (re.test(value) && value.length <= 4)) {
        const list = [...questionData];
        list[index][name] = value;
        setQuestionData(e.target);
      }
    } else if (name == "image") {
      console.log(name, e.target.files, "woetteg");
      const list = [...questionData];
      list[index][name] = e.target.files[0];
      setQuestionData(list);
    } else {
      const list = [...questionData];
      list[index][name] = value;
      setQuestionData(list);
    }
  };
  const handleMultiSelect = (e) => {
    if (e.target.checked) {
      setDeleteIds(
        quizQuestions.reduce((ids, is) => {
          return [...ids, is.id];
        }, [])
      );
    } else {
      setDeleteIds([]);
    }
  };
  const handleOneDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      axios
        .delete(`/api/admin/v1/quiz-question-delete/${id}`)
        .then((res) => {
          setLoading(false);
          setRefresh((refresh) => refresh + 1);
          toast.success(res.data.message);
          getCourseQuizQuestions(auth?.token);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  const handleSingleSelect = (e, id) => {
    if (!e.target.checked) {
      setDeleteIds((deleteIds) => deleteIds.filter((d) => d !== id));
    } else {
      setDeleteIds((deleteIds) => [...deleteIds, id]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(questionData, "asdfg");
    setLoading(true);
    const data = new FormData();
    data.append("question", question);
    // questionData.forEach((res, i) => {
    //     data.append(`certificate_json[${i}][option]`, res.option);
    //     data.append(`certificate_json[${i}][year]`, res.year);
    //     data.append(`certificate_json[${i}][image]`, res.image);
    // });
    data.append("options", JSON.stringify(questionData));
    // data.append(
    //     "images.1",
    //     questionData[1].image
    // )

    // data.append(
    //     "images.0",
    //     questionData[2].image
    // )
    // data.append(
    //     "images",
    //     questionData[2]
    // )
    data.append("answer", answer);
    data.append("quiz_id", geturl[3]);
    data.append("status", status);
    axios
      .post("/api/admin/v1/add-quiz-question", data)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.href = "/course-quiz-list/";
        }, 1000);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setLoading(false);
          setErrors(err.response.data.errors);
        } else {
          setLoading(false);
          toast.error(err.response.data.message);
        }
      });
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Course Quiz Question</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-12">
            <div class="row p-3">
              <div class="col-md-6 border border-info">
                <div class="p-3">
                  <h3 className="text-black">
                    <strong>Assessment Name</strong>
                  </h3>
                  <h4 className="text-black">{quiz?.name}</h4>
                </div>
              </div>
              <div class="col-md-3 border border-info">
                <div class="p-3">
                  <h3 className="text-black">
                    <strong>Pass mark</strong>
                  </h3>
                  <h4 className="text-black">{quiz?.pass_mark}</h4>
                </div>
              </div>
              <div class="col-md-3 border border-info">
                <div class="p-3">
                  <h3 className="text-black">
                    <strong>Assessment time</strong>
                  </h3>
                  <h4 className="text-black">{quiz?.quiz_time}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <FormGroup>
              <Label
                for="question"
                className="col-md-5 control-label col-form-label col-12 text-md-right"
              >
                Questions Title
              </Label>
              <Input
                type="textarea"
                id="question"
                className="col-md-7 h-auto"
                rows={4}
                invalid={!!errors.question}
                onChange={(e) => setQuestion(e.target.value)}
              ></Input>
              <FormFeedback className="offset-5">
                {!!errors.question ? errors.question[0] : ""}
              </FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-5 offset-md-1">
            <FormGroup>
              <Label
                for="answer"
                className="col-md-5 control-label col-form-label col-12 text-md-right"
              >
                Answer Mark
              </Label>
              <Input
                type="text"
                id="answer"
                className="col-md-7 h-auto"
                rows={2}
                invalid={!!errors.answer}
                onChange={(e) => setAnswer(e.target.value)}
              ></Input>
              <FormFeedback className="offset-5">
                {!!errors.answer ? errors.answer[0] : ""}
              </FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-11 offset-md-1">
            <h5>Answer Options</h5>
            <hr className="mt-0" />
          </div>
          <div className="col-md-11 offset-md-1">
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Question Answer</th>
                  <th>Image</th>
                  <th>Correct</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {questionData &&
                  questionData.map((ix, i) => (
                    <tr>
                      <th>
                        <Input
                          type="text"
                          name="answer"
                          value={ix.answer}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </th>
                      <th>
                        <span>
                          <Input
                            type="file"
                            data-default-file={
                              typeof ix.image === "string"
                                ? `/storage/${ix.image}`
                                : ""
                            }
                            id="image"
                            className="dropify"
                            name="image"
                            accept="image/jpg, image/jpeg, image/png"
                            onChange={(e) => handleInputChange(e, i)}
                          />{" "}
                          <FormText color="danger">
                            accept only PNG, JPEG and JPG{" "}
                          </FormText>
                        </span>
                        {/* {!(ix.image) &&

                                        <a href={`/storage/${ix.image}`} download><img src={`/storage/${ix.image}`} height="30px" width="30px" /></a>
                                    } */}
                      </th>
                      <th>
                        <Input
                          type="select"
                          name="correct"
                          value={ix.correct}
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          {" "}
                          <option value="false"> False </option>
                          <option value="true"> True </option>
                        </Input>
                      </th>
                      <th>
                        {" "}
                        <span>
                          {questionData.length !== 1 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-danger text-center"
                              onClick={() => handleRemoveClickQuestion(i)}
                            >
                              <MdHorizontalRule size={22} />
                            </button>
                          )}
                          {questionData.length - 1 === i && (
                            <button
                              type="button"
                              className="btn btn-sm btn-success text-center ms-1"
                              onClick={handleAddClickQuestion}
                            >
                              <MdAdd size={22} />
                            </button>
                          )}
                        </span>
                      </th>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div className="col-md-4 offset-md-5">
              <Button color="primary" onClick={handleSubmit}>
                Save
              </Button>
              <Button className="ms-3" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ImportQuestion />
      <div className="card-body p-0">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="table-responsive">
              <table className="table table-hover mb-0 table-sm small">
                <thead className="thead-light">
                  <tr>
                    <th className="font-weight-bold w-20px">
                      S.No.
                      {/* <Input
                                                id="default"
                                                type="checkbox"
                                                onChange={
                                                    handleMultiSelect
                                                }
                                                checked={
                                                    deleteIds.length ===
                                                    quizQuestions.length &&
                                                    deleteIds.length
                                                }
                                            /> */}
                    </th>
                    <th className="font-weight-bold">question</th>
                    {/* <th className="font-weight-bold">Answer Mark</th> */}

                    <th className="font-weight-bold text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <Fragment>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Fragment>
                        {CourseQuizQuestions?.length ? (
                          <Fragment>
                            {CourseQuizQuestions?.map((is, index) => (
                              <tr key={is.id}>
                                <td>
                                  {index + 1}
                                  {/* <Input
                                                                            id={`default-${is.id}`}
                                                                            type="checkbox"
                                                                            checked={deleteIds.some(
                                                                                d =>
                                                                                    d ===
                                                                                    is.id
                                                                            )}
                                                                            onChange={e =>
                                                                                handleSingleSelect(
                                                                                    e,
                                                                                    is.id
                                                                                )
                                                                            }
                                                                        /> */}
                                </td>
                                <td className="w-75">{is.question}</td>
                                {/* <td className="text-wrap">
                                                                        {is.grade}
                                                                    </td> */}
                                {/* <td className="text-wrap">
                                  <span
                                    className="dot-tooltip"
                                    title={is.answer}
                                  >
                                    {is.answer}
                                  </span>
                                </td> */}
                                <td className="text-right">
                                  {
                                    <Link to={`/edit-question/${is.id}`}>
                                      <AiOutlineEdit
                                        size={22}
                                        className="text-warning me-3"
                                      />
                                    </Link>
                                  }
                                  {
                                    <AiOutlineDelete
                                      size={22}
                                      className="text-danger"
                                      onClick={(e) => handleOneDelete(e, is.id)}
                                    />
                                  }
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        ) : (
                          <tr className="text-center">
                            <td colSpan={7}>No data found</td>
                          </tr>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <select
            id="paginate"
            onChange={(e) => setPaginate(e.target.value)}
            value={paginate}
            className="paging custom-select custom-select-page"
          >
            <option value="10" selected>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </select>
        </div>
        <Pagination
          pageCount={CourseQuizQuestionsCount / paginate}
          onChange={(e) => setPage(e.selected)}
        />
      </div>
    </div>
  );
};

export default CreateQuestion;
