import Routes from "./Routes";
import { Provider } from "react-redux";
import Store from "./redux/Store";
import "react-toastify/dist/ReactToastify.css";
import axios from "./axios";
import SocketContext from "./Context/SocketContext";
function App() {
  return (
    <SocketContext>
      <Provider store={Store}>
        <Routes />
      </Provider>
    </SocketContext>
  );
}

export default App;
