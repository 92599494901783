
import React from 'react'
import { useState, useEffect } from 'react'
import { Input, Button } from 'reactstrap'
import BasicDetailUpdate from '../../Components/Consultant/BasicDetailUpdate'
import ConsultantSector from '../../Components/Consultant/ConsultantSector'
import { useSelector } from 'react-redux';
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ConsultantSlots from '../../Components/Consultant/ConsultantSlots'
const ConsultantUpdate = () => {
    const [active, setActive] = useState(1)
    const { AuthReducer: { auth } } = useSelector(state => state)
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(0)
    const { id } = useParams()
    const toggleActive = (id) => {
        setActive(id)
    }
    useEffect(() => {
        axios.get(`/api/admin/v1/get-consultant-dtail/${id}`, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        })
            .then((({ data }) => {
                setData(data)
            })).catch(err => console.log(err))

    }, [refresh])

    return (
        <div>
            <div className='row my-3'>
                <div className='col-md-12'>
                    <h5 className='text-white'>Edit Consultant</h5>
                </div>
            </div>
        <div className='bg-white px-3 py-4 rounded-2  my-4'   >
            <div className="d-flex align-items-center">
                <Button color={active === 1 ? "primary" : "light"} onClick={() => toggleActive(1)} >
                    Basic Details
                </Button>
                <Button color={active === 2 ? "primary" : "light"} className='mx-3' onClick={() => toggleActive(2)}>
                    Consultant Sector
                </Button>
                <Button color={active === 3 ? "primary" : "light"} className='mx-3' onClick={() => toggleActive(3)}>
                    Consultant Slot
                </Button>
            </div>
            <div className="mt-5">
                {
                    active === 1 && <BasicDetailUpdate data={data} setRefresh={setRefresh} />
                }
                {
                    active === 2 && <ConsultantSector setRefresh={setRefresh} sectors={data?.consultant?.consultant_sectors} />
                }
                {
                    active === 3 && <ConsultantSlots setRefresh={setRefresh} slots={data?.consultant?.consultant_slots} />
                }
            </div>
        </div>
        </div>
    )
}

export default ConsultantUpdate