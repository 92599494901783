import axios from 'axios';

export const getProduct =
  (token, limit, page, search = "", sort = "asc") =>
    async (dispatch) => {
      try {
        dispatch({ type: "GET_PRODUCT_PENDING" });
        const response = await axios(
          `/api/admin/v1/product-list?limit=${limit || 10}&page=${page || 0
          }&search=${search}&sort=${sort}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: "GET_PRODUCT_FULFILLED",
          payload: response.data,
        });
      } catch (error) {
        dispatch({ type: "GET_PRODUCT_FAILED", payload: error.response.data });
      }
    };
