import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Label, FormGroup, Button } from 'reactstrap'
import { MultiSelect } from 'react-multi-select-component'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BiTrashAlt } from "react-icons/bi"
import { useParams } from 'react-router-dom'
const associateOpt = [

    {
        label: "Professional", value: 2, sector: [], industries: [], sectorOpt: [],
        industriesOpt: []
    },
    { label: " Industry", value: 1, sector: [], industries: [], sectorOpt: [], industriesOpt: [] },
    { label: "Service", value: 3, sector: [], industries: [], sectorOpt: [], industriesOpt: [] },

]

const ConsultantSector = ({ sectors, setRefresh }) => {
    const [associate, setAssociate] = useState([])
    const { AuthReducer: { auth } } = useSelector(state => state)
    const { id } = useParams()
    const getIndutries = (idss, index) => {
        const prev = [...associate]
        axios.get(`/api/admin/v1/get-industry-by-multi-sector/${idss}`, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }).then((({ data }) => {
            prev[index].industriesOpt = data?.industries.map((item) => ({ label: item.name_english, value: item.id, subCategoryId: item.subCategoryId }))
            setAssociate(prev)
        })).catch((err) => console.log(err))
    }
    const handleMultiSectorChange = (val, id, pos) => {
        const prev = [...associate]
        prev[pos].sector = val.map((it) => ({ ...it, categoryId: id }))
        setAssociate(prev)
        if (prev[pos].sector.length) {
            getIndutries(val.map(it => it.value).join(","), pos)
        } else {
            prev[pos].industries = []
            prev[pos].industriesOpt = []
        }
    }
    const handleMultiIndustryChange = (val, id, pos) => {
        const prev = [...associate]
        prev[pos].industries = val.map((it) => ({ ...it, categoryId: id }))
        setAssociate(prev)
    }

    const handleAssociateChagne = (val) => {
        axios.get(`/api/admin/v1/get-multi-sectors/${val.map(it => it.value).join(',')}`, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        })
            .then((({ data }) => setAssociate(prev => prev.map((it) => ({ ...it, sectorOpt: data?.sectors?.filter((item) => it.value === item.category_type_id).map(i => ({ label: i.name_english, value: i.id })) })))))
            .catch((err) => console.log(err))
    }
    const handleDelete = (id) => {
        axios.delete(`/api/admin/v1/delete-consultant-sector/${id}`, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        })
            .then((({ data }) => toast.success(data?.message)))
            .catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
    }

    const handleSubmit = () => {
        let data = []
        for (let index = 0; index < associate.length; index++) {
            if (associate[index].value === 3) {
                data.push(...associate[index].sector)
            } else {
                data.push(...associate[index].industries)
            }

        }
        data = data.map((it) => it.categoryId !== 3 ? ({ ...it, subSubCategoryId: it.value, id }) : ({ ...it, subCategoryId: it.value, id }))
        axios.post("/api/admin/v1/add-consultant-sector", { categories: data }, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }).then((({ data }) => {
            toast.success(data.message)
            setRefresh(prev => prev + 1)
            setAssociate([])
        }))
            .catch(err => toast.error(err?.response?.data?.message))
    }
    return (
        <div className='bg-white px-3 py-4 rounded-2  my-4' >
            <div className="row">
                <div className="col-md-4">
                    <FormGroup>
                        <Label> Associate With us As </Label>
                        <MultiSelect
                            options={associateOpt}
                            value={associate}
                            onChange={(val) => {
                                setAssociate(val)
                                handleAssociateChagne(val)
                            }}
                            labelledBy="Select"
                        />
                    </FormGroup>
                </div>
                {
                    associate.map((item, index) => {
                        return <div className='bg-light py-3 my-3 rounded-3' key={item.value} >
                            <h5 className=' mb-4' > {item.label} </h5>
                            <div className="row">
                                {Boolean(associate.length) && <div className="col-md-4 ">
                                    <FormGroup>
                                        <Label>Sector Industry</Label>
                                        <MultiSelect
                                            value={item.sector}
                                            options={item.sectorOpt}
                                            className='w-100'
                                            onChange={val => handleMultiSectorChange(val, item.value, index)}
                                        />
                                    </FormGroup>
                                </div>}


                                {(Boolean(associate.length) && item.value !== 3) && <div className="col-md-4 offset-md-1">
                                    {console.log(item.industriesOpt)}
                                    <FormGroup>
                                        <Label>Choose Industry</Label>
                                        <MultiSelect
                                            options={item.industriesOpt}
                                            value={item.industries}
                                            onChange={(val) => handleMultiIndustryChange(val, item.value, index)}
                                        />
                                    </FormGroup>

                                </div>
                                }
                                {Boolean(associate.length) && <div>
                                    <Button color='primary' onClick={handleSubmit} >
                                        Submit
                                    </Button>
                                </div>}
                            </div>
                        </div>
                    })

                }
            </div>
            <Table
                bordered
                hover
                responsive
                striped
            >
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Category
                        </th>
                        <th>
                            SubCategory
                        </th>
                        <th>
                            SubSubCategory
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sectors.map((sector, index) => {
                            return !Boolean(sector.isDeleted) && <tr key={sector.id} >
                                <th scope="row">
                                    {index + 1}
                                </th>
                                <td>
                                    {
                                        sector?.['category-type']?.type
                                    }
                                </td>
                                <td>
                                    {
                                        sector?.subCategory?.name_english
                                    }
                                </td>
                                <td>
                                    {
                                        sector?.subSubCategory?.name_english || "N/A"
                                    }
                                </td>
                                <td>
                                    <span className='text-danger d-block text-center ' role="button" onClick={() => {
                                        handleDelete(sector.id)
                                        setTimeout(() => setRefresh(prev => prev + 1), 1000)
                                    }} >
                                        <BiTrashAlt size={20} />
                                    </span>
                                </td>
                            </tr>
                        })
                    }


                </tbody>
            </Table>

        </div>
    )
}

export default ConsultantSector