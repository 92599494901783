import React from "react";
import { useState } from "react";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const UserForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    pincode: "",
    mobile: "",
    city: "",
    user_type: 1,
    password: "",
    email: "",
    state: "",
    gender: "",
    thumbnail: "",
  });

  const [errors, setErrors] = useState({});
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }

    axios
      .post("/api/admin/v1/add-user", form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then(({ data }) => {
        toast.success(data.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setErrors(err.response.data);
      });
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">User Form</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  value={formData.name}
                  onChange={handleChange}
                  name="name"
                  placeholder="name"
                  invalid={errors?.name}
                />
                <span className="d-block text-danger"> {errors?.name} </span>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Email<span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="email"
                  name="email"
                  value={formData.email}
                  invalid={errors.email}
                  onChange={handleChange}
                />
                <span className="d-block text-danger"> {errors?.email} </span>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Mobile <span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={(e) =>
                    e.target.value.length < 11 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                  invalid={errors?.mobile}
                />
                <span className="d-block text-danger"> {errors?.mobile} </span>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>
                  Password <span className="text-danger">*</span>
                </Label>
                <Input
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                  placeholder="password"
                  invalid={errors?.password}
                  type="text"
                />
                <span className="d-block text-danger">
                  {" "}
                  {errors?.password}{" "}
                </span>
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Gender</Label>
                <Input
                  value={formData.gender}
                  onChange={handleChange}
                  name="gender"
                  type="select"
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Input>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>User Type</Label>
                <Input
                  value={formData.user_type}
                  onChange={handleChange}
                  name="user_type"
                  type="select"
                >
                  <option value="">Select</option>
                  <option value="1">User</option>
                  <option value="2">Agent</option>
                </Input>
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Pincode</Label>
                <Input
                  placeholder="PinCode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={(e) =>
                    e.target.value.length < 7 &&
                    !isNaN(e.target.value) &&
                    handleChange(e)
                  }
                />
              </FormGroup>
            </div>
            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>City</Label>
                <Input
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>State</Label>
                <Input
                  placeholder="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </FormGroup>
            </div>

            {/* <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Upload Thumbnail</Label>
                <Input
                  type="file"
                  invalid={Boolean(errors?.thumbnail)}
                  name="thumbnail"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.files[0],
                    }))
                  }
                />
                <span className="d-block text-danger">{errors?.thumbnail}</span>
              </FormGroup>
            </div> */}

            <div className="col-md-4 offset-md-4">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
              <Button className="ms-3" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
