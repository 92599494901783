import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Input, Label, FormGroup, Button } from "reactstrap"
import { useDispatch } from 'react-redux'
import { getCourses } from "../../redux/actions/CourseAction";
import moment from 'moment/moment';
const UpdateCourseQuiz = () => {
    const { id } = useParams()
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        courseId: "",
        name: "",
        quiz_time: "",
        pass_mark: "",
        number_of_attempts: "",
        quiz_course_duration: "",
        quiz_course_start: "",
        quiz_course_end: "",
        is_demo: 0,
        status: 1,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        AuthReducer: { auth },
        CourseReducer: { allCourses }
    } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getCourses(auth.token))
    }, [auth.token])

    useEffect(() => {
        if (id) {
            axios(`/api/admin/v1/edit-course-quiz/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`
                }
            })
                .then((res) => {
                    setFormData((prev) => {
                        return {
                            ...prev
                            ,
                            courseId: res.data?.CourseQuizs?.courseId,
                            name: res.data?.CourseQuizs?.name,
                            quiz_time: res.data?.CourseQuizs?.quiz_time,
                            pass_mark: res.data?.CourseQuizs?.pass_mark,
                            number_of_attempts: res.data?.CourseQuizs?.number_of_attempts,
                            quiz_course_duration: res.data?.CourseQuizs?.quiz_course_duration,
                            quiz_course_start: moment(res.data?.CourseQuizs?.quiz_course_start).format("YYYY-MM-DD"),
                            quiz_course_end: moment(res.data?.CourseQuizs?.quiz_course_end).format("YYYY-MM-DD"),
                            is_demo: res.data?.CourseQuizs?.is_demo,
                            status: res.data?.CourseQuizs?.status
                        }
                    })
                })

                .catch((err) =>
                    toast.error(err.response.data))
        }
    }, [id, auth])


    const inputHandler = (e) => {
        console.log(e.target, 'targer');
        const { name, value } = e.target;
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        }
        if (name === "is_demo") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = () => {
        const form = new FormData()
        for (const key in formData) {
            form.append([key], formData[key])
        }
        axios.put(`/api/admin/v1/update-course-quiz/${id}`, form, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`
            }
        })
            .then((res) => {
                toast.success(res.data?.message)
                navigate(-1)
            }
            ).catch((err) => {
                toast.error(err.response.data);
                setErrors(err.response.data);
            })
    }

    console.log(errors, 'poiuytrewq');
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h4 className="text-white">Edit Course Quiz</h4>
                </div>
            </div>
            <div className='bg-white px-3 py-4 rounded-2  my-4' >
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Course <span className="text-danger fs-4">*</span></Label>
                            <Input
                                value={formData?.courseId}
                                type="select"
                                name="courseId"
                                invalid={errors?.courseId}
                                onChange={inputHandler}
                            >
                                <option value="">Select Course</option>
                                {allCourses?.map?.((item) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item?.title}
                                        </option>
                                    );
                                })}
                            </Input>
                            <span className="d-block text-danger">{errors?.courseId}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Name <span className="text-danger fs-4">*</span></Label>
                            <Input value={formData?.name} name="name" invalid={errors?.name} onChange={inputHandler} />
                            <span className="d-block text-danger">{errors?.name}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Quiz Time <span className="text-danger fs-4">*</span></Label>
                            <Input
                                value={formData?.quiz_time}
                                name="quiz_time"
                                invalid={errors?.quiz_time}
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger">{errors?.quiz_time}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Passing Mark <span className="text-danger fs-4">*</span></Label>
                            <Input
                                value={formData?.pass_mark}
                                name="pass_mark"
                                maxLength={3}
                                invalid={errors?.pass_mark}
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger">{errors?.pass_mark}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Number Of Attempts <span className="text-danger fs-4">*</span></Label>
                            <Input
                                value={formData?.number_of_attempts}
                                name="number_of_attempts"
                                maxLength={2}
                                invalid={errors?.number_of_attempts}
                                onChange={inputHandler}
                            />
                            <span className="d-block text-danger">{errors?.number_of_attempts}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Quiz Course Start Date</Label>
                            <Input
                                type="date"
                                value={formData?.quiz_course_start}
                                name="quiz_course_start"
                                invalid={errors?.quiz_course_start}
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Quiz Course End Date</Label>
                            <Input
                                type="date"
                                value={formData?.quiz_course_end}
                                name="quiz_course_end"
                                invalid={errors?.quiz_course_end}
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Quiz Course Duration</Label>
                            <Input
                                value={formData?.quiz_course_duration}
                                name="quiz_course_duration"
                                invalid={errors?.quiz_course_duration}
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.is_demo}
                                // onChange={inputHandler}
                                onChange={() =>
                                    setFormData({ ...formData, is_demo: !formData.is_demo })
                                }
                                name="is_demo"
                            />
                            <Label check>Is Demo</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                // onChange={inputHandler}
                                onChange={() =>
                                    setFormData({ ...formData, status: !formData.status })
                                }
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color='primary' onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className='ms-3' onClick={() => navigate(-1)}  >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateCourseQuiz