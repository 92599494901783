import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UncontrolledCollapse, Collapse } from "reactstrap";
import { BsBuildingFillGear } from "react-icons/bs";

import { GiBigGear, GiHamburgerMenu } from "react-icons/gi";
import { GiBookshelf } from "react-icons/gi";
import Logo from "../../images/consultancy.webp";
import {
  MdEmail,
  MdOutlineSegment,
  MdOutlineVideoSettings,
  MdPayments,
} from "react-icons/md";
import { BiUserPlus, BiMoneyWithdraw, BiSolidOffer } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsChevronUp, BsPencil } from "react-icons/bs";
import { LuListMinus, LuUserCog } from "react-icons/lu";
import { VscSettings } from "react-icons/vsc";
import { BsChevronDown, BsImages } from "react-icons/bs";
import { FaClipboardList, FaWpforms } from "react-icons/fa";
const Sidebar = () => {
  const { pathname } = useLocation();
  const [collapse, setCollapse] = useState(false);
  const [reportCollapse, setReportCollapse] = useState(false);
  const [courseCollapse, setCourseCollapse] = useState(false);
  const [serviceCollapse, setServiceCollapse] = useState(false);
  const [memberShipCollapse, setMemberShipCollapse] = useState(false);
  const [status, setStatus] = useState("Closed");
  const toggle = () => setCollapse(!collapse);
  const reportToggle = () => setReportCollapse(!reportCollapse);
  const courseToggle = () => setCourseCollapse(!courseCollapse);
  const serviceToggle = () => setServiceCollapse(!serviceCollapse);
  const membershipToggle = () => setMemberShipCollapse(!memberShipCollapse);
  const {
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const collapseClose = () => setCollapse(false);
  const reportCollapseClose = () => setReportCollapse(false);
  const courseCollapseClose = () => setCourseCollapse(false);
  const serviceCollapseClose = () => setServiceCollapse(false);
  const memberShipCollapseClose = () => setMemberShipCollapse(false)
  return (
    <Wrapper>
      <div className="d-flex sidebar flex-column  flex-shrink-0 side_bg">
        {/* <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
          onClick={collapseClose}
        >
          <span className="fs-4 text-white">Consultancy</span>
        </Link> */}

        <nav className="sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left navbar">
          <div className="col-md-3">
            <div className="logodiv m-0">
              <Link to="/">
                <img img className="img2" src={Logo} alt=""></img>
              </Link>
            </div>
          </div>
        </nav>
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item text-white">
            <Link
              to="/"
              className={`nav-link py-3 ps-3 align-items-center d-flex ${pathname === "/" ? "link_active text-black" : "text-secondary"
                } `}
              aria-current="page"
              onClick={collapseClose}
            >
              <AiOutlineHome className="sideicon" size={22} color="black" />
              <span className="sidetext  ms-3 text-black">Home</span>
            </Link>
          </li>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "CATEGORY" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/categories"
                  className={`nav-link  py-3  ps-3 align-items-center d-flex ${pathname === "/categories"
                    ? "link_active text-primary"
                    : "text-secondary"
                    }`}
                  onClick={collapseClose}
                >
                  <BiCategory className="sideicon" size={22} color="black" />
                  <span className="sidetext ms-3 text-black">Category</span>
                </Link>
              </li>
            )}

          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "SUBCATEGORIES" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/subcategory"
                  className={`nav-link  py-3 ps-3  align-items-center d-flex ${pathname.includes("subcategory")
                    ? "link_active text-primary"
                    : "text-secondary"
                    }`}
                >
                  <MdOutlineSegment
                    className="sideicon"
                    size={22}
                    color="black"
                  />
                  <span className="sidetext ms-3 text-black">Segment</span>
                </Link>
              </li>
            )}
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "PRODUCTS" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/product"
                  className={`nav-link  py-3 ps-3  align-items-center d-flex ${pathname.includes("product")
                    ? "link_active text-primary"
                    : "text-secondary"
                    }`}
                >
                  <BsBuildingFillGear
                    className="sideicon"
                    size={22}
                    color="black"
                  />
                  <span className="sidetext ms-3 text-black">Product</span>
                </Link>
              </li>
            )}
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/sub-sub-category"
                  className={`nav-link  py-3 ps-3 align-items-center d-flex ${pathname.includes("sub-sub-category")
                    ? "link_active text-primary"
                    : "text-secondary"
                    }`}
                  onClick={collapseClose}
                >
                  <BiUserPlus className="sideicon" size={22} color="black" />
                  <span className="sidetext ms-3 text-black">Expertise</span>
                </Link>
              </li>
            )}

          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "CONSULTANT" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/consultants"
                  className={`nav-link  py-3 ps-3  align-items-center d-flex ${pathname === "/consultants"
                    ? "link_active text-primary"
                    : "text-secondary"
                    }`}
                  onClick={collapseClose}
                >
                  <HiOutlineUserGroup
                    className="sideicon"
                    size={22}
                    color="black"
                  />
                  <span className="sidetext ms-3 text-black">Consultant</span>
                </Link>
              </li>
            )}

          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "OFFERLIST" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common ">
                <Link
                  to="/offer-list"
                  className={`nav-link py-3 ps-3 ${pathname === "/offer-list"
                    ? "link_active text-primary"
                    : "text-secondary"
                    } align-items-center d-flex`}
                >
                  <BiSolidOffer className="sideicon" size={22} color="black" />
                  <span className="sidetext ms-3 text-black">Offer</span>
                </Link>
              </li>
            )}

          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "BANNERLIST" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common ">
                <Link
                  to="/banner-list"
                  className={`nav-link py-3 ps-3 ${pathname === "/banner-list"
                    ? "link_active text-primary"
                    : "text-secondary"
                    } align-items-center d-flex`}
                >
                  <BsImages className="sideicon" size={22} color="black" />
                  <span className="sidetext ms-3 text-black">Banner</span>
                </Link>
              </li>
            )}
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "SEOLIST" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common ">
                <Link
                  to="/seo-list"
                  className={`nav-link py-3 ps-3 ${pathname === "/seo-list"
                    ? "link_active text-primary"
                    : "text-secondary"
                    } align-items-center d-flex`}
                >
                  <FaClipboardList className="sideicon" size={22} color="black" />
                  <span className="sidetext ms-3 text-black">Seo</span>
                </Link>
              </li>
            )}
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "USERLIST" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item text-white">
                <Link
                  to="/user"
                  className={`nav-link py-3 ps-3 align-items-center d-flex ${pathname === "/user"
                    ? "link_active text-black"
                    : "text-secondary"
                    } `}
                  aria-current="page"
                  onClick={collapseClose}
                >
                  <FaWpforms className="sideicon" size={22} color="black" />
                  <span className="sidetext  ms-3 text-black">User List</span>
                </Link>
              </li>
            )}

          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "COURSELIST" || item?.permission?.moduleType === "COURSEQUIZ" || item?.permission?.moduleType === "COURSETYPE" || item?.permission?.moduleType === "COURSECATEGORY" || item?.permission?.moduleType === "COURSESUBCATEGORY" || item?.permission?.moduleType === "COURSESUBSUBCATEGORY" || item?.permission?.moduleType === "COURSEMODULE" || item?.permission?.moduleType === "COURSESECTIONMODULE" &&
              item?.permission?.permission === "view"
          ) && (
              <li
                role="button"
                className={` border_common nav-item nav-link  py-3 ps-3 align-items-center d-flex  ${courseCollapse ? "link_active text-primary" : " text-secondary"
                  }`}
                onClick={courseToggle}
              >
                <GiBookshelf size={22} className="sideicon" color="black" />
                <span className="sidetext ms-3 text-black ">Courses</span>
                <span className="ic" style={{ paddingLeft: "90px" }}>
                  <BsChevronDown size={18} color="black" />
                </span>
              </li>
            )}
          <Collapse isOpen={courseCollapse} className="mt-3">
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSETYPE" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-type"
                    className={` nav-link  ${pathname === "/coursestype"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Course Type</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSECATEGORY" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-category"
                    className={` nav-link  ${pathname === "/coursecategory"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">
                      Course Catgeory
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSESUBCATEGORY" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-sub-category"
                    className={` nav-link  ${pathname === "/coursesubcategory"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">
                      Course Sub Catgeory
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSESUBSUBCATEGORY" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-sub-sub-category"
                    className={` nav-link  ${pathname === "/coursesubsubcategory"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">
                      Course Sub Sub Catgeory
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSESECTIONMODULE" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-section-module"
                    className={` nav-link  ${pathname === "/coursesectionmodule"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">
                      Course Section Module
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSEMODULE" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-module"
                    className={` nav-link  ${pathname === "/coursemodule"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Course Module</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSEQUIZ" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-quiz-list"
                    className={` nav-link  ${pathname === "/coursequiz"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Course Quiz</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "COURSELIST" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-list"
                    className={` nav-link  ${pathname === "/course-list"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Courses</span>
                  </Link>
                </li>
              )}
            <li className="nav-item">
              <Link to="#" onClick={courseCollapseClose}></Link>
            </li>

          </Collapse>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
              item?.permission?.permission === "view"
          ) && (
              <li
                role="button"
                className={` border_common nav-item nav-link  py-3 ps-3 align-items-center d-flex  ${serviceCollapse ? "link_active text-primary" : " text-secondary"
                  }`}
                onClick={serviceToggle}
              >
                <GiBigGear size={22} className="sideicon" color="black" />
                <span className="sidetext ms-3 text-black ">Services</span>
                <span className="ic" style={{ paddingLeft: "90px" }}>
                  <BsChevronDown size={18} color="black" />
                </span>
              </li>
            )}
          <Collapse isOpen={serviceCollapse} className="mt-3">
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/services"
                    className={` nav-link  ${pathname === "/services"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Services</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/service-categories"
                    className={` nav-link  ${pathname === "/servicecategories"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Service Categories</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "SUBSUBCATEGORIES" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/services-sub-category"
                    className={` nav-link  ${pathname === "/servicessubcategory"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Service Sub Categories</span>
                  </Link>
                </li>
              )}
          </Collapse>
          <li
            role="button"
            className={` border_common nav-item nav-link  py-3 ps-3 align-items-center d-flex  ${memberShipCollapse ? "link_active text-primary" : " text-secondary"
              }`}
            onClick={membershipToggle}
          >
            <MdOutlineVideoSettings
              className="sideicon"
              size={22}
              color="black"
            />
            <span className="sidetext ms-3 text-black ">MemberShips</span>
            <span className="ic" style={{ paddingLeft: "90px" }}>
              <BsChevronDown size={18} color="black" />
            </span>
          </li>

          <Collapse isOpen={memberShipCollapse} className="mt-3">

            <li className="nav-item border_common">
              <Link
                to="/memberships"
                className={` nav-link  ${pathname === "/memberships"
                  ? "link_active text-primary"
                  : "text-secondary"
                  } align-items-center d-flex`}
              >
                <span className="sidetext ms-4 text-black">MemberShips</span>
              </Link>
            </li>
            <li className="nav-item border_common">
              <Link
                to="/membership-scope"
                className={` nav-link  ${pathname === "/membership-scope"
                  ? "link_active text-primary"
                  : "text-secondary"
                  } align-items-center d-flex`}
              >
                <span className="sidetext ms-4 text-black">MemberShip Scope</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link to="#" onClick={memberShipCollapseClose}></Link>
            </li>

          </Collapse>

          <li
            role="button"
            className={` border_common nav-item nav-link  py-3 ps-3 align-items-center d-flex  ${reportCollapse ? "link_active text-primary" : " text-secondary"
              }`}
            onClick={reportToggle}
          >
            <FaClipboardList size={22} className="sideicon" color="black" />
            <span className="sidetext ms-3 text-black ">Report</span>
            <span className="ic" style={{ paddingLeft: "100px" }}>
              <BsChevronDown size={18} color="black" />
            </span>
          </li>
          <Collapse isOpen={reportCollapse} className="mt-3">
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "BOOKINGLIST" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/booking-list"
                    className={` nav-link  align-items-center d-flex ${pathname.includes("booking-list")
                      ? "link_active text-primary"
                      : "text-secondary"
                      }align-items-center d-flex`}
                  >
                    <FaClipboardList size={22} className="dropicon" color="black" />
                    <span className="sidetext ms-4 text-black">Booking List</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "USERLIST" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/service-user"
                    className={` nav-link ${pathname.includes("service-user")
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Service Users </span>
                  </Link>
                </li>

              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "USERLIST" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/edp-user"
                    className={` nav-link ${pathname.includes("edp-user")
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">EDP Users </span>
                  </Link>
                </li>

              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "WITHDRAWAL" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/withdrawal-requests"
                    className={` nav-link ${pathname.includes("withdrawal-requests")
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Withdrawal </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType ===
                "WRITTENCONSULTANCY" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/tax-queries"
                    className={` nav-link  ${pathname === "/tax-queries"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">
                      Written Consultancy
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType ===
                "PAYMENTREPORT" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/payout-report"
                    className={` nav-link align-items-center d-flex  ${pathname === "/payout-report"
                      ? "link_active text-primary"
                      : "text-secondary"
                      }`}
                  >
                    <span className="sidetext ms-4 text-black">Payment Report</span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType ===
                "COURSEORDER" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/course-order"
                    className={` nav-link  ${pathname === "/course-order"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Course Order</span>
                  </Link>
                </li>
              )}

            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType ===
                "MEMBERSHIPREGISTRATION" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/membership-report"
                    className={` nav-link  ${pathname === "/membership-report"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="sidetext ms-4 text-black">Membership Report</span>
                  </Link>
                </li>
              )}

            <li className="nav-item">
              <Link to="#" onClick={reportCollapseClose}></Link>
            </li>
          </Collapse>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "MEETINGLIST" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/enquiries"
                  className={`nav-link py-3 ps-3 ${pathname === "/enquiries"
                    ? "link_active text-primary"
                    : "text-secondary"
                    } align-items-center d-flex`}
                >
                  <MdEmail
                    className="sideicon"
                    size={22}
                    color="black"
                  />
                  <span className="droptext ms-3  text-black">Enquiries</span>
                </Link>
              </li>
            )}
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "MEETINGLIST" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common">
                <Link
                  to="/meeting-list"
                  className={`nav-link py-3 ps-3 ${pathname === "/meeting-list"
                    ? "link_active text-primary"
                    : "text-secondary"
                    } align-items-center d-flex`}
                >
                  <HiOutlineUserGroup
                    className="sideicon"
                    size={22}
                    color="black"
                  />
                  <span className="droptext ms-3  text-black">Meetings</span>
                </Link>
              </li>
            )}
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "EXPERTVIDEOS" &&
              item?.permission?.permission === "view"
          ) && (
              <li className="nav-item border_common ">
                <Link
                  to="/expert-videos"
                  className={`nav-link py-3 ps-3 ${pathname === "/expert-videos"
                    ? "link_active text-primary"
                    : "text-secondary"
                    } align-items-center d-flex`}
                >
                  <MdOutlineVideoSettings
                    className="sideicon"
                    size={22}
                    color="black"
                  />
                  <span className="sidetext ms-3 text-black">Expert Videos</span>
                </Link>
              </li>
            )}

          <li
            role="button"
            className={`reportdrop border_common nav-item nav-link  py-3 ps-3 align-items-center d-flex  ${collapse ? "link_active text-primary" : " text-secondary"
              }`}
            onClick={toggle}
          >
            <VscSettings className="sideicon" size={22} color="black" />
            <span className="sidetext ms-3 text-black">Settings</span>
            <span className="ic" style={{ paddingLeft: "90px" }}>
              <BsChevronDown size={18} color="black" />
            </span>
          </li>

          <Collapse isOpen={collapse} className="mt-3">
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "ROLE" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/role"
                    className={`nav-link  ${pathname.includes("role")
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="droptext ms-4  text-black">Role</span>
                  </Link>
                </li>
              )}


            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "PERMISSIONS" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/permissions"
                    className={`nav-link ${pathname === "/permissions"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="droptext ms-4  text-black">Permission</span>
                  </Link>
                </li>
              )}

            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "ASSIGN_PERMISSION" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/assign-permissions"
                    className={`nav-link  ${pathname === "/assign-permissions"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="droptext ms-4  text-black">
                      Assign Permission
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>

                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item border_common">
                  <Link
                    to="/customize-settings"
                    style={{ fontSize: 14 }}
                    className={`nav-link  ${pathname === "/customize-settings"
                      ? "link_active text-primary"
                      : "text-secondary"
                      } align-items-center d-flex`}
                  >
                    <span className="droptext ms-4  text-black">
                      Customize Settings
                    </span>
                  </Link>
                </li>
              )}
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "ADMINUSERS" &&
                item?.permission?.permission === "view"
            ) && (
                <li className="nav-item">
                  <Link
                    to="/admin-users"
                    className={`nav-link text-secondary align-items-center d-flex ${pathname === "/admin-users"
                      ? "link_active text-primary"
                      : "text-secondary"
                      }`}
                    onClick={collapseClose}
                  >
                    <span className="ms-4 text-black">Admin Users</span>
                  </Link>
                </li>
              )}
          </Collapse>
        </ul>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  .active {
    font-weight: 700;
  }
  .link_active {
    background: rgba(0, 0, 0, 0.14) !important;
  }
  .sidebar {
    width: 230px;
    background-color: transparent;
    min-height: 100vh;
    padding-top: 10px;
  }

  .side_bg {
    background: white;
  }
  .nav-pills .nav-link {
    background: none;
    border-radius: 0 !important;
  }
`;

export default Sidebar;
