import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import Pagination from "../../Components/Paginate";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";
import moment from "moment/moment";
import { useSocketContext } from "../../Context/SocketContext";
const Withdrawal = () => {
  const [requests, setRequest] = useState([]);
  const [status, setStatus] = useState([]);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState("desc");
  const { socket } = useSocketContext();
  const [search, setSearch] = useState("");
  const [paginate, setPaginate] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [withdrawCount, setwithdrawCount] = useState(0);
  const {
    AuthReducer: { auth },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  const withdrawalRequest = useRef(() => undefined);
  withdrawalRequest.current = () => {
    axios(
      `/api/admin/v1/withdrawal-request?limit=${paginate || 10}&page=${
        page || 0
      }&search=${search}&status=${statusFilter}&sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then(({ data }) => {
        setRequest(data?.data);
        setwithdrawCount(data?.withdrawalCount);
        setStatus(
          data?.data?.map?.((item) => {
            return { id: item.id, status: item?.withDrawlStatus };
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    withdrawalRequest.current();
  }, [paginate, page, search, statusFilter, sort]);

  const handleStatusChange = (val, id, consultantId) => {
    setStatus((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, status: val };
        }
        return item;
      })
    );
    axios
      .put(
        `/api/admin/v1/update-withdrawal-status`,
        { status: val, id, consultantId },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message);

        withdrawalRequest.current();
        const data = {
          val: val,
          consultantId: consultantId,
        };
        socket.emit("withdrawrequest", data);
        socket.emit("withdrawlrequest", data);
      })
      .catch((err) => toast.error(err.response.data?.message));
  };

  const csvHead = [
    [
      "consultant name",
      "mobile",
      "bank name",
      "account number",
      "branch name",
      "amount",
      "status",
    ],
  ];
  const csvData = requests.map((cv) => [
    cv?.consultant?.name,
    cv?.consultant?.mobile,
    cv?.consultant?.consultant_profile?.bankName,
    cv?.consultant?.consultant_profile?.accountNo,
    cv?.consultant?.consultant_profile?.branchName,
    cv?.amount,
    cv?.status,
  ]);
  const exportData = csvHead.concat(csvData);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Withdrawal Requests</h5>
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4 "
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row mb-2">
          <div className="col-md-3">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
          <div className="col-md-3">
            <Input
              type="select"
              size={"sm"}
              value={statusFilter}
              onChange={({ target }) => setStatusFilter(target.value)}
            >
              <option value="">Select Status</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="hold">On Hold</option>
              <option value="completed">Completed</option>
            </Input>
          </div>
          <div
            className={`col-md-2 ${
              allowedPermissions?.some?.(
                (item) =>
                  item?.permission?.moduleType === "WITHDRAWAL" &&
                  item?.permission?.permission === "export"
              )
                ? "offset-md-3"
                : "offset-md-4"
            }`}
          >
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "WITHDRAWAL" &&
              item?.permission?.permission === "export"
          ) && (
            <div className="col-md-1">
              <CSVLink
                filename={"withdrawal-lists.csv"}
                className="text-black ms-3"
                title="Export To CSV"
                data={exportData}
              >
                <BiSolidFileExport size={25} />
              </CSVLink>
            </div>
          )}
        </div>
        {/* <div className="bg-white px-3 py-5 rounded-2  my-4">
        <div className="row"> */}
        {/* <div className="col-md-12">
            <div className="mb-4 d-flex align-items-center justify-content-between">
              <h5 className="mb-0">Withdrawal Requests</h5>
              <div className="col-md-2">
                <Input type="select" value={statusFilter} onChange={(({ target }) => setStatusFilter(target.value))} >
                  <option value="">Select</option>
                  <option value="pending">Pending</option>
                  <option value="rejected">Rejected</option>
                  <option value="hold">On Hold</option>
                  <option value="completed">Completed</option>
                </Input>
              </div>
              <div>
                <CSVLink
                  filename={
                    "withdrawal-lists.csv"
                  }
                  className="text-black ms-3"
                  title="Export To CSV"
                  data={exportData}
                >
                  <BiSolidFileExport size={25} />
                </CSVLink>
              </div>
            </div>
          </div> */}
        <div className="col-md-12">
          <Table hover responsive>
            <thead>
              <tr>
                <th>SL. No.</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Amount</th>
                <th>Date</th>
                {allowedPermissions?.some?.(
                  (item) =>
                    item?.permission?.moduleType === "WITHDRAWAL" &&
                    item?.permission?.permission === "status-action"
                ) && <th>Status</th>}
                <th>Bank Name</th>
                <th>Account No</th>
                <th>Branch Name</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1 + page * 10}</td>
                    <td>{item?.consultant?.name}</td>
                    <td>{item?.consultant?.mobile}</td>
                    <td>{item?.amount}</td>
                    <td>
                      {moment(item?.createdAt).format("DD-MM-yyyy hh:mm A")}
                    </td>
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType === "WITHDRAWAL" &&
                        item?.permission?.permission === "status-action"
                    ) && (
                      <td>
                        <Input
                          type="select"
                          value={status.find((it) => it.id === item.id)?.status}
                          onChange={({ target: { value } }) =>
                            handleStatusChange(
                              value,
                              item.id,
                              item?.consultant?.id
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="pending">Pending</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                          <option value="hold">On Hold</option>
                          <option value="completed">Completed</option>
                        </Input>
                      </td>
                    )}
                    <td>{item?.consultant?.consultant_profile?.bankName}</td>
                    <td>{item?.consultant?.consultant_profile?.accountNo}</td>
                    <td>{item?.consultant?.consultant_profile?.branchName}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {/* </div> */}

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
          <Pagination
            pageCount={withdrawCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Withdrawal;
