import React, { useState } from 'react'
import { Button, FormGroup, Input, Label, Table } from 'reactstrap'
import { BiTrashAlt } from 'react-icons/bi'
import moment from 'moment/moment'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
const ConsultantSlots = ({ slots, setRefresh }) => {
    const [formData, setFormData] = useState({
        dayIndex: "",
        startTime: "",
        endTime: "",
        dateIndex: "",
        timeZone: ""
    })
    const { AuthReducer: { auth } } = useSelector(state => state)
    const { id } = useParams()
    const handleDelete = (slotId) => {
        axios.put(`/api/admin/v1/delete-consultant-slot`, {
            id: slotId,
            consultantId: id
        }, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }
        ).then((({ data }) => {
            toast.success(data?.message)
            setTimeout(() => {
                setRefresh(prev => prev + 1)
            }, 1000)
        }))
            .catch((err) => {
                console.log(err);
                toast.error("Something Went Wrong")
            })
    }

    const handleSubmit = () => {
        axios.post("/api/admin/v1/add-consultant-slot", {
            ...formData,
            id
        }, {
            headers: {
                Authorization: `Bearer ${auth.token}`
            }
        }).then((({ data }) => {
            toast.success(data?.message)
            setTimeout(() => {
                setRefresh(prev => prev + 1)
            }, 1000)
        }))
            .catch(err => {
                console.log(err);
                toast.error("Something went wrong")
            })
    }

    const handleDay = (day) => {
        switch (day) {
            case 0:

                return "Sunday"

            case 1:

                return "Monday"

            case 2:

                return "Tuesday"

            case 3:

                return "Wednesday"

            case 4:

                return "Thursday"

            case 5:
                return "Friday"

            case 6:
                return "Saturday"

            default:
                return "Sunday"
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
      };

    return (
        <div>

            <div className="row gy-3">
                <div className="col-md-4">

                    <FormGroup>
                        <Label>
                            Date
                        </Label>
                        <Input type='date' name='dateIndex' value={formData.dateIndex} min={disablePastDate()} onChange={handleChange} />
                    </FormGroup>
                </div>

                <div className="col-md-4">

                    <FormGroup>
                        <Label>
                            Start Time
                        </Label>
                        <Input type='time' value={formData.startTime} name='startTime' onChange={handleChange} />
                    </FormGroup>
                </div>
                <div className="col-md-4">

                    <FormGroup>
                        <Label>
                            End Time
                        </Label>
                        <Input type='time' value={formData.endTime} name='endTime' onChange={handleChange} />
                    </FormGroup>
                </div>

                <div className="col-md-4">

                    <FormGroup>
                        <Label>
                            Timezone
                        </Label>
                        <Input type='select' value={formData.timeZone} name='timeZone' onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="morning">AM</option>
                            <option value="evening">PM</option>
                        </Input>
                    </FormGroup>
                </div>
                <div className='mb-3' >
                    <Button color='primary' onClick={handleSubmit} >
                        Submit
                    </Button>
                </div>
            </div>

            <Table
                bordered
                hover
                responsive
                striped
            >
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Slot Date
                        </th>
                        <th>
                            Day
                        </th>
                        <th>
                            Start Time
                        </th>
                        <th>
                            End Time
                        </th>

                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        slots?.map((slot, index) => {
                            return !slot.isDeleted &&  <tr key={slot.id} >
                                <th scope="row">
                                    {index + 1}
                                </th>
                                <td>
                                    {slot?.consultant_slote_date?.dateIndex}
                                </td>
                                <td>

                                    {handleDay(moment(slot?.consultant_slote_date?.dateIndex).day())}

                                </td>
                                <td>
                                    {
                                        slot?.startTime
                                    }
                                </td>
                                <td>
                                    {
                                        slot?.endTime
                                    }
                                </td>
                                <td>
                                    <span className='text-danger d-block text-center ' role="button" onClick={() => {
                                        handleDelete(slot.id)
                                        setTimeout(() => setRefresh(prev => prev + 1), 1000)
                                    }} >
                                        <BiTrashAlt size={20} />
                                    </span>
                                </td>
                            </tr>
                        })
                    }


                </tbody>
            </Table>
        </div>
    )
}

export default ConsultantSlots