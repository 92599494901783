import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getSubCategories,
  getCategories,
} from "../../redux/actions/indexActions";
import { toast } from "react-toastify";
const AddSubSubCategory = () => {
  const [formData, setFormData] = useState({
    categoryId: "",
    subCategoryId: "",
    title_english: "",
    title_hindi: "",
    name_english: "",
    name_hindi: "",
    description_hindi: "",
    short_description_english: "",
    short_description_hindi: "",
    description_english: "",
    status: 1,
    avatar_english: "",
    avatar_hindi: "",
    featured: "",
  });
  const [errors, setErrors] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const {
    AuthReducer: { auth },
    indexReducer: { categories },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "avatar_english" || name === "avatar_hindi") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = () => {
    const form = new FormData();
    for (const key in formData) {
      if (key === "categoryId") {
        form.append("categoryTypeId", formData[key]);
      }
      form.append([key], formData[key]);
    }
    axios
      .post(`/api/admin/v1/sub/sub/category`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate("/sub-sub-category");
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data.errors);
      });
  };

  useEffect(() => {
    dispatch(getCategories(auth.token));
    dispatch(getSubCategories(auth.token));
  }, [auth]);

  useEffect(() => {
    if (formData.categoryId) {
      setFormData((prev) => ({ ...prev, subCategoryId: "" }));
      axios(`/api/web/v1/get-sectorBy-type/${formData.categoryId}`)
        .then(({ data }) => {
          setSubCategories(data?.data);
        })
        .catch((err) => console.error(err));
    } else {
      setSubCategories([]);
    }
  }, [formData.categoryId]);

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Expertise</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title English <span className="text-danger">*</span>
              </Label>
              <Input
                invalid={Boolean(errors?.title_english)}
                value={formData?.title_english}
                name="title_english"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.title_english}{" "}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Title Hindi</Label>
              <Input
                value={formData?.title_hindi}
                name="title_hindi"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                English Name <span className="text-danger">*</span>
              </Label>
              <Input
                value={formData?.name_english}
                invalid={Boolean(errors?.name_english)}
                name="name_english"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {errors?.name_english}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Upload English Thumbnail <span className="text-danger">*</span>
              </Label>
              <Input
                type="file"
                invalid={Boolean(errors?.avatar_english)}
                name="avatar_english"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">
                {errors?.avatar_english}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Upload Hindi Thumbnail</Label>
              <Input
                type="file"
                invalid={Boolean(errors?.avatar_hindi)}
                name="avatar_hindi"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.files[0],
                  }))
                }
              />
              <span className="d-block text-danger">
                {errors?.avatar_hindi}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Select Category <span className="text-danger">*</span>
              </Label>
              <Input
                value={formData?.categoryId}
                type="select"
                name="categoryId"
                onChange={inputHandler}
                invalid={errors.categoryTypeId}
              >
                <option value="">Select</option>
                {categories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.type}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">
                {errors?.categoryTypeId}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Select Segment <span className="text-danger">*</span>
              </Label>
              <Input
                invalid={Boolean(errors?.subCategoryId)}
                value={formData?.subCategoryId}
                type="select"
                name="subCategoryId"
                onChange={inputHandler}
              >
                <option value="">Select</option>
                {subCategories?.map?.((item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name_english}
                    </option>
                  );
                })}
              </Input>
              <span className="d-block text-danger">
                {errors?.subCategoryId}
              </span>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Name</Label>
              <Input
                value={formData?.name_hindi}
                name="name_hindi"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Hindi Description</Label>
              <Input
                name="short_description_hindi"
                value={formData?.short_description_hindi}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Short English Description<span className="text-danger">*</span>
              </Label>
              <Input
                name="short_description_english"
                value={formData?.short_description_english}
                type="textarea"
                onChange={inputHandler}
                invalid={errors.short_description_english}
              />
              <p className="text-danger">{errors.short_description_english}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>English Full Description</Label>
              <Input
                value={formData?.description_english}
                name="description_english"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Full Description</Label>
              <Input
                value={formData?.description_hindi}
                name="description_hindi"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Featured</Label>
              <Input
                value={formData?.featured}
                name="featured"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubSubCategory;
