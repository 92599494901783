import React, { useEffect, useMemo, useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { GoCopy } from "react-icons/go";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import CKEditor from "react-ckeditor-component";
import moment from "moment/moment";

const Index = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [userWalletBal, setUserWalletBal] = useState(0);
  const [consultantName, setConsultantName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [mobile, setMobile] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [textMsg, setTextMsg] = useState("");
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);
  const [linkDetail, setLinkDetail] = useState({
    subject: "",
    consultantName: "",
    mobile: "",
    message: "",
    whatsapp: false,
    sms: false,
    link: "",
    host: "",
    id: "",
    consultant: "",
    user: "",
    useType: "",
    amount: "",
  });
  const [isGenerated, setIsGenerated] = useState(false);

  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "userType") {
      setLinkDetail((prev) => ({
        ...prev,
        [name]: value,
        user: "",
        host: "",
        consultant: "",
      }));
    } else {
      setLinkDetail((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersResponse = await axios("/api/admin/v1/get-all-users", {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        setOptions(usersResponse.data.users);

        const consultantsResponse = await axios(
          "/api/admin/v1/get-consultant-list",
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setConsultants(consultantsResponse.data.getConsultant);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [auth.token]);

  const opt = options?.map?.((item) => ({ label: item.name, value: item.id }));

  function randomID(len) {
    let result = "";
    if (result) return result;
    var chars =
      "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
      maxPos = chars.length,
      i;
    len = len || 16;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
  }

  const linkGenerator = () => {
    const id = uuid();
    setLinkDetail((prev) => ({
      ...prev,
      link: `${process.env.REACT_APP_MEETING_URL}/${randomID(16)}`,
      id,
    }));
    setIsGenerated(true);
  };

  const copyLink = () => {
    if (
      typeof window.navigator !== "undefined" &&
      typeof window.navigator.clipboard !== "undefined" &&
      window.navigator.permissions !== "undefined"
    ) {
      window.navigator.clipboard
        ?.writeText?.(linkDetail.link)
        .then(() => toast.success(`Copied`))
        .catch((err) => console.log(err));
    }
  };



  useEffect(() => {
    if (linkDetail.user) {
      axios
        .get(`/api/admin/v1/get-userwallet-balance?id=${linkDetail.user}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((res) => {
          setUserWalletBal(res.data.totalAmount);
        })
        .catch((err) => console.error(err));
    }
  }, [auth.token, linkDetail.user]);

  const handleText = (e) => {
    const newDescription = e.editor.getData();
    setTextMsg(newDescription);
  }

  const userBal = useMemo(() => userWalletBal, [userWalletBal])

  useEffect(() => {
    if (linkDetail.user || linkDetail.consultant) {
      const videofees = consultants.find(
        (item) => item.id === linkDetail.consultant
      );

      const userDetail = options.find(
        (item) => item.id === linkDetail.user
      );
      if (userDetail) {
        setUserMobile(userDetail.mobile);
      }

      if (videofees && videofees.consultant_profile) {
        setMobile(videofees.mobile);
        setConsultantName(videofees.name);
        const videoFee = videofees?.consultant_profile?.videoFee || 0;

        if (parseInt(userBal) >= parseInt(videoFee)) {
          setAmount(videoFee)
          console.log("videoFee")
          console.log(videoFee)
          console.log("userBal")
          console.log(userBal)
          // setLinkDetail((prev) => ({
          //   ...prev,
          //   amount: videoFee,
          // }));
        }
        if (linkDetail.user && parseInt(userWalletBal) && parseInt(videoFee)) {
          if (parseInt(userBal) < parseInt(videoFee)) {
            toast.warning("User has a low wallet balance");
          }
        }
      }
    }
  }, [linkDetail.user,userWalletBal, linkDetail.consultant]);

  useEffect(() => {
    setTextMsg(text);
    if ((text && mobile && linkDetail.mobile) || (text && mobile && userMobile)) {
      const phoneNumbers = [mobile, linkDetail.mobile, userMobile];
      phoneNumbers.forEach((phoneNumber) => {
        if (phoneNumber) {
          axios
            .post(
              `/api/admin/v1/send-whatsapp`,
              {
                mobile: `91${phoneNumber}`,
                message: text,
              },
              {
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                },
              }
            )
            .then(() => {
              toast.success("Successfully send");
            })
            .catch((err) => console.error(err));
        }
      })
    }
  }, [linkDetail.whatsapp, linkDetail.mobile, mobile, userMobile, text]);

  useEffect(() => {
    if (((linkDetail.subject && linkDetail.useType && linkDetail.consultant && linkDetail.user) !== "") && linkDetail.whatsapp === true && linkDetail.useType === "internal") {
      if (userWalletBal < linkDetail.amount) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } else {
      if (((linkDetail.subject && linkDetail.useType && linkDetail.consultant && linkDetail.mobile) !== "") && linkDetail.whatsapp === true) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
    }
  }, [auth.token, linkDetail.subject, linkDetail.useType, linkDetail.mobile, linkDetail.whatsapp, linkDetail.consultant, linkDetail.user, linkDetail.amount, userWalletBal])

  useEffect(() => {
    if (isActive === false && linkDetail.whatsapp === true && linkDetail.link) {
      setText(
        `Consultancy Meeting Invitation\n\n${consultantName} is inviting you to a scheduled Consultancy meeting.\n\nTopic: ${consultantName}'s Consultancy Meeting\nTime: ${moment().format('LLL')} \n\nJoin Consultancy Meeting\n${linkDetail.link}`
      )
    }
  }, [consultantName, linkDetail.whatsapp, linkDetail.link, isActive])
  const handleSubmit = () => {
   
    const videofees = consultants.find(
      (item) => item.id === linkDetail.consultant
    );

    const userDetail = options.find(
      (item) => item.id === linkDetail.user
    );
    if (userDetail) {
      setUserMobile(userDetail.mobile);
    }
    var videoFee;
    if (videofees && videofees.consultant_profile) {
      setMobile(videofees.mobile);
      setConsultantName(videofees.name);
       videoFee = videofees?.consultant_profile?.videoFee || 0;
    }
    axios
      .post("/api/admin/v1/add-meeting", {
        subject: linkDetail?.subject,
        consultantName: consultantName,
        mobile: linkDetail?.mobile,
        message: textMsg,
        whatsapp: linkDetail?.whatsapp,
        sms: linkDetail?.sms,
        link: linkDetail?.link,
        host: linkDetail?.host,
        id: linkDetail?.id,
        consultant: linkDetail?.consultant,
        user: linkDetail?.user,
        useType: linkDetail?.useType,
        amount: videoFee
      }, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then(() => {
        toast.success("Successfully added");
        navigate(-1);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Create Meeting</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-5 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-2">
            <FormGroup>
              <Label>Subject</Label>
              <Input
                name="subject"
                onChange={(e) => inputHandler(e)}
                value={linkDetail.subject}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-2">
            <FormGroup>
              <Label>Select use type</Label>
              <Input
                name="useType"
                onChange={inputHandler}
                type="select"
                value={linkDetail.useType}
              >
                <option value="">Select</option>
                <option value="external">External</option>
                <option value="internal">Internal</option>
              </Input>
            </FormGroup>
          </div>
          {linkDetail.useType === "external" && (
            <>
              <div className="col-md-4 offset-md-2">
                <FormGroup>
                  <Label>Select Consultant</Label>
                  <Multiselect
                    options={consultants}
                    value={linkDetail.consultant}
                    selectionLimit={1}
                    className="text-dark"
                    showCheckbox
                    displayValue="name"
                    onSelect={(val) =>
                      setLinkDetail({ ...linkDetail, consultant: val[0]?.id })
                    }
                  />
                </FormGroup>
              </div>
              {/* <div className="col-md-4 offset-md-2">
                <FormGroup>
                  <Label>Consultant Name</Label>
                  <Input
                    name="consultantName"
                    onChange={(e) => inputHandler(e)}
                    value={linkDetail.consultantName}
                  />
                </FormGroup>
              </div> */}
              <div className="col-md-4 offset-md-2">
                <FormGroup>
                  <Label>User Mobile No</Label>
                  <Input
                    name="mobile"
                    maxLength={11}
                    onChange={(e) => inputHandler(e)}
                    value={linkDetail.mobile}
                  />
                </FormGroup>
              </div>
            </>
          )}

          <div className="col-md-4 offset-md-2">
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-2  ">
                <span> Whatsapp </span>
                <Input
                  type="checkbox"
                  checked={linkDetail.whatsapp}
                  onChange={(e) =>
                    setLinkDetail((prev) => ({
                      ...prev,
                      whatsapp: e.target.checked,
                    }))
                  }
                />
              </div>
              {/* <div className="d-flex gap-2  aling-items-center">
              <span> Sms </span>
              <Input
                type="checkbox"
                checked={linkDetail.sms}
                onChange={(e) =>
                  setLinkDetail((prev) => ({ ...prev, sms: e.target.checked }))
                }
              />
            </div> */}
            </div>
          </div>

          {linkDetail.useType === "internal" && (
            <div className="col-md-4 offset-md-2">
              <FormGroup>
                <Label>Select Consultant</Label>
                <Multiselect
                  options={consultants}
                  value={linkDetail.consultant}
                  selectionLimit={1}
                  className="text-dark"
                  showCheckbox
                  displayValue="name"
                  onSelect={(val) =>
                    setLinkDetail({ ...linkDetail, consultant: val[0]?.id })
                  }
                />
              </FormGroup>
            </div>
          )}

          {linkDetail.useType === "internal" && (
            <div className="col-md-4 offset-md-2">
              <FormGroup>
                <Label>Select User</Label>
                <Multiselect
                  options={opt}
                  value={linkDetail.user}
                  selectionLimit={1}
                  className="text-dark"
                  displayValue="label"
                  onSelect={(val) =>
                    setLinkDetail({ ...linkDetail, user: val[0]?.value })
                  }
                  labelledBy="Select"
                />
              </FormGroup>
            </div>
          )}
          <div className="col-md-4 offset-md-2">
            <div className="d-flex gap-3 mt-4">
              {!linkDetail.link && (
                <Button onClick={linkGenerator} size="sm" disabled={isActive} color="primary">
                  Generate Link
                </Button>
              )}
              <div className="d-flex gap-1">
                <Input value={linkDetail.link} disabled placeholder="link" />
                <Button size="sm" onClick={() => isGenerated && copyLink()}>
                  <GoCopy />
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-12 offset-md-2">
            <FormGroup>
              <Label>Add Message</Label>
              <CKEditor
                activeClass="col-md-10 pr-0"
                content={textMsg}
                events={{
                  "change": handleText
                }}
              />
            </FormGroup>
          </div>

          <div className="col-md-10 offset-md-2  ">
            <div className="d-flex align-items-center gap-4 mt-4">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>

              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
