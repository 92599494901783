import React, { useEffect, useMemo } from "react";
import { io } from "socket.io-client";
const Context = React.createContext(null);
const SocketContext = ({ children }) => {
  const socket = useMemo(() => 
  io("https://consultancy-api.iid.org.in", {
    cors: {
      origins:  '*:*',
      methods: ["GET", "POST"],
      credentials: true,
    },
  })
  , []);
  useEffect(() => {
    socket.connect();
    socket.on("connection", (messg) => {
      console.log(messg);
    });
  }, [socket]);
  return <Context.Provider value={{ socket }}> {children} </Context.Provider>;
};

export const useSocketContext = () => {
  return React.useContext(Context);
};
export default SocketContext;
