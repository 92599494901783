const initialState = {
  user: [],
  userCount: 0,
  serviceCount: 0,
  serviceUser: [],
  edpUser: [],
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_PENDING":
    case "GET_EDP_USER_PENDING":
    case "GET_SERVICE_USER_PENDING":
      return { ...state, loading: true };

    case "GET_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        user: action.payload?.users,
        userCount: action?.payload?.total,
      };

    case "GET_SERVICE_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        serviceUser: action.payload?.serviceUser,
        userCount: action?.payload?.total,
      };
    case "GET_EDP_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        edpUser: action.payload?.edpUser,
        userCount: action?.payload?.total,
      };
    case "GET_USER_FAILED":
    case "GET_SERVICE_USER_FAILED":
    case "GET_EDP_USER_FAILED":
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};

export default UserReducer;
