import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import { getCategoryType } from "../../redux/actions/ConsultantAction";
import { getCourseType } from "../../redux/actions/CourseAction";

const AddCourseType = () => {
  const [formData, setFormData] = useState({
    course_type: "",
    description: "",
    status: 1,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);

  const [errors, setErrors] = useState({});

  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    const form = new FormData();
    form.append("course_type", formData.course_type);
    form.append("description", formData.description);
    form.append("status", formData.status);
    axios
      .post(`/api/admin/v1/add-course-type`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setErrors(err.response.data);
      });
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Course Type</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Course Type <span className="text-danger fs-4">*</span> </Label>
              <Input
                invalid={errors?.course_type}
                value={formData?.course_type}
                name="course_type"
                onChange={inputHandler}
              />
              <span className="d-block text-danger">
                {" "}
                {errors?.course_type}{" "}
              </span>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Full Description</Label>
              <Input
                value={formData?.description}
                name="description"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseType;
