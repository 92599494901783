import React from 'react'

const useDebounce = ( value = "", delay = 500 ) => {
    const [debouncedValue, setDebouncedValue] = React.useState("")
    React.useEffect(() => {
        let timer = setTimeout(() => setDebouncedValue(value), delay)
        return () => clearTimeout(timer)
    }, [value, delay])
    return debouncedValue
}

export default useDebounce