import firebase from "firebase";
import Axios from "axios";
import axios from "../../axios";

var firebaseConfig = {
  apiKey: "AIzaSyDt09ExMnRhWUGgkYbQ-uOxOH06IQ1nJWo",
  authDomain: "consultancy-meet.firebaseapp.com",
  databaseURL: "https://consultancy-meet-default-rtdb.firebaseio.com",
  projectId: "consultancy-meet",
  storageBucket: "consultancy-meet.appspot.com",
  messagingSenderId: "579929531872",
  appId: "1:579929531872:web:ee74c985480eca8ced3785",
  measurementId: "G-KZ5LXBXKNP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref();
export let userName = ""
const checkMeetingValidation = async (roomId) => {
  const meeting = await Axios(`/api/admin/v1/match-meeting?id=${roomId}`);
  const data = meeting?.data?.meetingByID;
  return data;
}
const getUserName = async() => {
  const urlparams = new URLSearchParams(window.location.search);
  const roomId = urlparams.get("id");
  let joinUserName = "";
  const meetingData = await checkMeetingValidation(roomId);
    if(meetingData?.isUsed == 0) {
      joinUserName = prompt("What's your name?");
      firepadRef = firepadRef.child(roomId);
    }else{
      window.location.href = "/leave-review";
    }
  return joinUserName;
}
if (window.location.pathname === "/meeting") {
  (async() => {
    userName = await getUserName()
  })()
}

export default firepadRef;
