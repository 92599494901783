import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, Label, Button } from 'reactstrap'
import { useDispatch, useSelector } from "react-redux"
import { login } from '../redux/actions/AuthActions'
import { useNavigate } from 'react-router-dom'
import loginImg from "../images/Data_security_01.jpg"
const Login = () => {
    const { AuthReducer: { loading, errors, isAuthenticated } } = useSelector(state => state)
    const [formData, setFormData] = useState({ email: "", password: "" })
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const inputHandler = (e) => {
        const { value, name } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
        // eslint-disable-next-line
    }, [isAuthenticated])

    return (
        <Wrapper>
            <div className='login-container bg-primary-100 ' >
                <div className="login-wrapper d-flex rounded-3 overflow-hidden">

                    <div className="card py-4 px-3 border-0 rounded-0 flex-grow-1">
                        <div className="card-body">
                            <h4 className='text-center mb-4'>Log in</h4>
                            <div className="form-group ">
                                <Label>
                                    Email
                                </Label>
                                <Input invalid={Boolean(errors?.email)} onChange={inputHandler} name="email" />
                                <span className="d-block text-danger">
                                    {errors?.email}
                                </span>
                            </div>

                            <div className="form-group mt-3 mb-4">
                                <Label>
                                    Password
                                </Label>
                                <Input invalid={Boolean(errors?.password)} onChange={inputHandler} name="password" type='password' />
                                <span className="d-block text-danger">
                                    {errors?.password}
                                </span>
                            </div>
                            <Button disabled={loading} color='primary' className='w-100 mt-3' onClick={() => dispatch(login(formData))} >
                                Submit
                            </Button>

                        </div>
                    </div>
                    <img className='img' width="50%" src={loginImg} alt="" />
                </div>
            </div>

        </Wrapper>
    )
}

const Wrapper = styled.div`
.login-container{
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
}
.card{
    box-shadow: 12px 12px 30px rgba(0,0,0,0.06),
    -12px -12px 30px rgba(0,0,0,0.06)
    ;
}
.login-wrapper{
    max-width: 800px;
}

.login-wrapper .img{
    object-fit: cover;
}

`

export default Login