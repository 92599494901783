import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { getCategories } from "../../redux/actions/indexActions";
import { getCategoryType } from "../../redux/actions/ConsultantAction";
const AddSubCategory = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { categories },
    consultantReducer: { categorytype },
  } = useSelector((state) => state);
  const [formData, setFormData] = useState({
    consultanttype: [],
    name_english: "",
    name_hindi: "",
    categoryId: "",
    title_english: "",
    title_hindi: "",
    description_english: "",
    short_description_english: "",
    description_hindi: "",
    short_description_hindi: "",
    status: 1,
    avatar_hindi: "",
    featured: 0,
    avatar_english: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const inputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "avatar") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    }
    if (name === "status") {
      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    dispatch(getCategoryType(auth.token));
  }, [auth.token]);

  useEffect(() => {
    dispatch(getCategories(auth.token));
    // eslint-disable-next-line
  }, [auth]);

  const handleSubmit = () => {
    const form = new FormData();
    const consultantType = JSON.stringify(formData.consultanttype);
    for (const key in formData) {
      if (key !== "consultanttype") {
        form.append([key], formData[key]);
      }
    }
    form.append("categoryType", consultantType);
    axios
      .post(`/api/admin/v1/sub/category`, form, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        toast.success(res.data?.message);
        navigate(-1);
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
        toast.error(err.response.data);
      });
  };
  console.log(errors, "errors");
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="text-white">Add Segment</h5>
        </div>
      </div>
      <div className="bg-white px-3 py-4 rounded-2  my-4">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Category Type</Label>
              <Multiselect
                className="text-black"
                options={categorytype}
                displayValue="type"
                onSelect={(val) =>
                  setFormData({ ...formData, consultanttype: [...val] })
                }
                invalid={errors?.categoryType}
              />

              <p className="text-danger">{errors?.categoryType}</p>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Title English<span className="text-danger"> *</span>
              </Label>
              <Input
                value={formData?.title_english}
                name="title_english"
                onChange={inputHandler}
                invalid={errors?.title_english}
              />
            </FormGroup>
            <p className="text-danger">{errors?.title_english}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Title Hindi</Label>
              <Input
                value={formData?.title_hindi}
                name="title_hindi"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                English Name<span className="text-danger"> *</span>
              </Label>
              <Input
                value={formData?.name_english}
                name="name_english"
                onChange={inputHandler}
                invalid={errors?.name_english}
              />
            </FormGroup>
            <p className="text-danger">{errors?.name_english}</p>
          </div>

          {/* <div className="col-md-4 offset-md-1">
          <FormGroup>
            <Label>Select Category</Label>
            <Input
              value={formData?.categoryId}
              type="select"
              name="categoryId"
              onChange={inputHandler}
            >
              <option value="">Select</option>
              {categories.map((item) => {
              {console.log(item)}
                return (
                  <option key={item.id} value={item.id}>
                    {item?.title_english}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </div> */}

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Name</Label>
              <Input
                value={formData?.name_hindi}
                name="name_hindi"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Short Hindi Description</Label>
              <Input
                name="short_description_hindi"
                value={formData?.short_description_hindi}
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Short English Description<span className="text-danger"> *</span>
              </Label>
              <Input
                name="short_description_english"
                value={formData?.short_description_english}
                type="textarea"
                onChange={inputHandler}
                invalid={errors.short_description_english}
              />
              <p className="text-danger">{errors.short_description_english}</p>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>English Full Description</Label>
              <Input
                value={formData?.description_english}
                name="description_english"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Thumbnail Hindi</Label>
              <Input
                type="file"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    avatar_hindi: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Thumbnail English<span className="text-danger"> *</span>
              </Label>
              <Input
                type="file"
                invalid={errors.short_description_english}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    avatar_english: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
            <p className="text-danger">{errors.short_description_english}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Hindi Full Description</Label>
              <Input
                value={formData?.description_hindi}
                name="description_hindi"
                type="textarea"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Featured</Label>
              <Input
                value={formData?.featured}
                name="featured"
                onChange={inputHandler}
              />
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup switch>
              <Input
                type="switch"
                checked={formData?.status}
                onChange={inputHandler}
                name="status"
              />
              <Label check>Status</Label>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubCategory;
