import React, { useState, useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import {
  getSubCategories,
  getCategories,
} from "../../redux/actions/indexActions";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { MdAdd } from "react-icons/md";
import { Input, Label, FormGroup } from "reactstrap";
import Pagination from "../../Components/Paginate";
import { CSVLink } from "react-csv";
import { BiSolidFileExport } from "react-icons/bi";
import { Spinner } from "reactstrap";
const Subcategory = () => {
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth },
    indexReducer: { subCategories, subCategoryCount, categories, loading },
    PermissionReducer: { allowedPermissions },
    consultantReducer: { categorytype },
  } = useSelector((state) => state);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [limit, setLimit] = useState(10);

  const [paginate, setPaginate] = useState(10);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [positions, setPositions] = useState({});
  const inputHandler = (e) => {
    const { name, value } = e.target;
  };

  useEffect(() => {
    dispatch(getCategories(auth.token, limit, page, search));
  }, [auth, limit, page, search]);

  useEffect(() => {
    dispatch(
      getSubCategories(
        auth?.token,
        paginate,
        page,
        search,
        categoryFilter,
        sort
      )
    );
  }, [auth?.token, paginate, page, search, categoryFilter, sort]);

  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This SubCategory")) {
      axios
        .delete(`/api/admin/v1/sub/category/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          dispatch(getSubCategories(auth?.token));
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  const csvHead = [["category", "name", "description"]];
  const csvData = subCategories.map((cv) => [
    cv?.["category-type"]?.type,
    cv?.name_english,
    cv?.description_english,
  ]);
  const exportData = csvHead.concat(csvData);

  useEffect(() => {
    if (subCategories) {
      const initialPositions = {};
      subCategories.forEach(subCategorie => {
        initialPositions[subCategorie.id] = subCategorie.position;
      });
      setPositions(initialPositions);
    }
  }, [subCategories]);
  const handlePosition = useCallback((id) => {
    axios
      .put(
        `/api/admin/v1/update-subcategory-position/${id}`,
        { position: parseInt(positions[id]) },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message);
        dispatch(getCategories(auth.token, limit, page, search));
      })
      .catch((err) => toast.error(err.response.data?.message));
  }, [auth, positions, dispatch, paginate, page, search]);
  const handlePositionChange = (id, value) => {
    setPositions({
      ...positions,
      [id]: value
    });
  };
  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Segment</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "SUBCATEGORIES" &&
                item?.permission?.permission === "create"
            ) && (
              <Link to="/add-subcategory">
                <Button className="bg-white text-black" size="sm">
                  <span className="me-2">
                    <MdAdd size={22} />
                  </span>
                  Add Segment
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div
        className="bg-white px-3 py-4 rounded-2  my-4"
        style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}
      >
        <div className="row">
          <div className="col-md-7 mb-2">
            <div className="row">
              <div className="col-md-4">
                <Input
                  size={"sm"}
                  value={search}
                  placeholder="Search By Name"
                  onChange={({ target: { value } }) => setSearch(value)}
                />
              </div>
              <div className="col-md-4">
                <Input
                  size={"sm"}
                  type="select"
                  name="categoryId"
                  value={categoryFilter}
                  onChange={({ target }) => setCategoryFilter(target.value)}
                >
                  <option value="">Select Category</option>
                  {categories?.map?.((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item?.type}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>
          </div>
          <div
            className={`col-md-2 ${
              allowedPermissions?.some?.(
                (item) =>
                  item?.permission?.moduleType === "SUBCATEGORIES" &&
                  item?.permission?.permission === "export"
              )
                ? "offset-md-2"
                : "offset-md-3"
            }`}
          >
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
          {allowedPermissions?.some?.(
            (item) =>
              item?.permission?.moduleType === "SUBCATEGORIES" &&
              item?.permission?.permission === "export"
          ) && (
            <div className="col-md-1 mb-2">
              <CSVLink
                filename={"sub-categories.csv"}
                className="text-black ms-3"
                title="Export To CSV"
                data={exportData}
              >
                <BiSolidFileExport size={25} />
              </CSVLink>
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center mx-auto">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Thumbnail</th>
                    <th>Category</th>
                    <th>Position</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType === "SUBCATEGORIES" &&
                        (item?.permission?.permission === "edit" ||
                          item?.permission?.permission === "delete")
                    ) && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {subCategories?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + page * 10}</th>
                        <td>{item?.name_english}</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: item?.short_description_english?.slice(
                              0,
                              50
                            ),
                          }}
                        ></td>

                        <td>
                          <img
                            src={`https://consultancy-iid-new.s3.ap-south-1.amazonaws.com/${item?.avatar_english}`}
                            height={45}
                            width={45}
                          />
                        </td>

                        <td>{item?.["category-type"]?.type}</td>
                        <td>
                          <Input
                            type="text"
                            value={positions[item.id]}
                            onChange={({ target: { value } }) => handlePositionChange(item.id, value)}
                            onBlur={() => handlePosition(item.id)}
                          />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType ===
                                  "SUBCATEGORIES" &&
                                item?.permission?.permission === "edit"
                            ) && (
                              <Link to={`/update-subcategory/${item?.id}`}>
                                <AiOutlineEdit
                                  size={22}
                                  className="text-warning me-3"
                                />
                              </Link>
                            )}

                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType ===
                                  "SUBCATEGORIES" &&
                                item?.permission?.permission === "delete"
                            ) && (
                              <AiOutlineDelete
                                size={22}
                                className="text-danger"
                                onClick={() => handleDel(item.id)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <select
              id="paginate"
              onChange={(e) => setPaginate(e.target.value)}
              value={paginate}
              className="paging custom-select custom-select-page"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
            <div className="mx-3 py-2 text-black">
              Total Results : {subCategoryCount}
            </div>
          </div>
          <Pagination
            pageCount={subCategoryCount / paginate}
            onChange={(e) => setPage(e.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default Subcategory;
