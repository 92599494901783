import { configureStore } from "@reduxjs/toolkit";
import indexReducer from "./reducers/indexReducer";
import thunk from "redux-thunk";
import AuthReducer from "./reducers/AuthReducer";
import PermissionReducer from "./reducers/PermissionReducer";
import consultantReducer from "./reducers/ConsultantReducer";
import { meetingReducer } from "../redux/reducers/MeetingReducer";
import dashboardReducer from "./reducers/DashboardReducer";
import CourseReducer from "./reducers/CourseReducer";
import ProductReducer from "./reducers/ProductReducer";
import UserReducer from "./reducers/UserReducer";
import MemberShipReducer from "./reducers/MemberShipReducer";
const Store = configureStore({
  reducer: {
    indexReducer,
    UserReducer,
    AuthReducer,
    PermissionReducer,
    consultantReducer,
    meetingReducer,
    dashboardReducer,
    CourseReducer,
    ProductReducer,
    MemberShipReducer
  },
  middleware: [thunk],
});

export default Store;
