import axios from "axios";
export const getMemberShips = (token, limit, page, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MEMBERSHIPS_PENDING" });
      const response = await axios(
        `/api/admin/v1/membership-list?limit=${limit || 10}&page=${page || 0
        }&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MEMBERSHIPS_FULFILLED",
        payload: response?.data?.memberShips,
        memberShipsCount: response.data?.memberShipsCount,
      });
    } catch (error) {
      dispatch({ type: "GET_MEMBERSHIPS_FAILED", payload: error.response.data });
    }
  };

export const getMemberShipScope = (token, limit, page, search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MEMBERSHIP_SCOPE_PENDING" });
      const response = await axios(
        `/api/admin/v1/membership-scope-list?limit=${limit || 10}&page=${page || 0
        }&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: "GET_MEMBERSHIP_SCOPE_FULFILLED",
        payload: response?.data?.memberShips,
        memberShipsCount: response.data?.memberShipsCount,
      });
    } catch (error) {
      dispatch({ type: "GET_MEMBERSHIP_SCOPE_FAILED", payload: error.response.data });
    }
  };

export const getMemberShipReport = (limit, page, search = "", sort = "", categoryFilter = "", subCategoryFilter = "", memberShipFilter = "", paymentGateway = "", userTypeFilter = "", paymentStatus = "", startDate = "", endDate = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_MEMBERSHIP_REPORT_PENDING" });
      const response = await axios(
        `/api/admin/v1/get-membership-report?limit=${limit || 10}&page=${page || 0
        }&search=${search}&sort=${sort}&categoryFilter=${categoryFilter}&subCategoryFilter=${subCategoryFilter}&memberShipFilter=${memberShipFilter}&paymentGateway=${paymentGateway}&userTypeFilter=${userTypeFilter}&paymentStatus=${paymentStatus}&startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({
        type: "GET_MEMBERSHIP_REPORT_FULFILLED",
        payload: response?.data?.data,
        MemberShipRegistrationCount: response.data?.MemberShipRegistrationCount,
      });
    } catch (error) {
      dispatch({ type: "GET_MEMBERSHIP_REPORT_FAILED", payload: error.response.data });
    }
  };