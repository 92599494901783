export const phoneValid = (value) => {
    if (value.length < 11 && !isNaN(value)) {
        return true
    }
    else{
        return false
    }
}
 
export const removeDupes = (arr = [], base = "id") => {
    const exist = {}
    const diff = []
    for (let i = 0; i < arr.length; i++) {
        if (exist[arr[i][base]]) {
            exist[arr[i][base]] += 1
        }
        else {
            exist[arr[i][base]] = 1
            diff.push(arr[i])
        }
    }
    return diff
}
