import React from "react";
import { useState, useEffect } from "react";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MultiInput from "./MultiInput";
import { useForm } from "react-hook-form";

const BasicDetailUpdate = ({ data, setRefresh }) => {
  const { id } = useParams();
  const [error, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    pinCode: "",
    mobile: "",
    city: "",
    contactPerson: "",
    email: "",
    state: "",
    phone: "",
    source: "",
    website: "",
    designation: "",
    experience: "",
    remark: "",
    gender: "",
    audioFee: "",
    videoFee: "",
    mou: "",
    educationDetail: [{ college: "", degree: "", passingYear: "" }],
    ProfileSummary: "",
    certificateName: "",
    certificate: "",
    order: "",
    thumbnail: "",
    paymentType: 0,
    userType: 0
  });
  const {
    AuthReducer: { auth },
  } = useSelector((state) => state);
  const [consultantSector, setConsultantSector] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    console.log(formData)
    const data = new FormData();
    for (const key in formData) {
      if (key === "educationDetail") {
        data.append(key, JSON.stringify(formData[key]));
      }
      if (key === "thumbnail" && key !== "educationDetail") {
        data.append("thumbnail", formData[key]);
      }
      if (key !== "thumbnail" && key !== "educationDetail") {
        data.append(key, formData[key]);
      }
    }
    axios
      .put(`/api/admin/v1/update-consultant-basic-details/${id}`, data, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then(({ data }) => {
        toast.success(data.message);
        navigate(-1);
        setFormData({
          name: "",
          address: "",
          pinCode: "",
          mobile: "",
          city: "",
          contactPerson: "",
          email: "",
          state: "",
          phone: "",
          source: "",
          website: "",
          designation: "",
          experience: "",
          remark: "",
          gender: "",
          audioFee: "",
          videoFee: "",
          mou: "",
          educationDetail: [{ college: "", degree: "", passingYear: "" }],
          ProfileSummary: "",
          certificateName: "",
          certificate: "",
          order: "",
          paymentType: 0,
          userType: 0
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setErrors(err.response.data);
      });
  };

  useEffect(() => {
    console.log(data?.consultant)
    setFormData((prev) => {
      return {
        ...prev,
        name: data.consultant?.name || "",
        mobile: data.consultant?.mobile || "",
        city: data.consultant?.city || "",
        gender: data.consultant?.gender || "",
        audioFee: data?.consultant?.consultant_profile?.audioFee || "",
        videoFee: data?.consultant?.consultant_profile?.videoFee || "",
        state: data?.consultant?.state || "",
        address: data?.consultant?.consultant_profile?.address || "",
        email: data?.consultant?.email || "",
        pinCode: data?.consultant?.pincode || "",
        experience: data?.consultant?.consultant_profile?.experience || "",
        website: data?.consultant?.consultant_profile?.websiteUrl || "",
        contactPerson:
          data?.consultant?.consultant_profile?.contactPerson || "",
        source: data?.consultant?.consultant_profile?.source || "",
        remark: data?.consultant?.consultant_profile?.remark || "",
        designation: data?.consultant?.consultant_profile?.designation || "",
        phone: data?.consultant?.consultant_profile?.phoneNo || "",
        ProfileSummary:
          data?.consultant?.consultant_profile?.ProfileSummary || "",
        educationDetail: data?.consultant?.consultant_profile
          ?.educationDetailsJson
          ? JSON.parse(
              data?.consultant?.consultant_profile?.educationDetailsJson
            )
          : [{ college: "", degree: "", passingYear: "" }],
        certificateName:
          data?.consultant?.consultant_profile?.certificateName || "",
        order: data?.consultant?.orderId || "",
        paymentType: data?.consultant?.paymentType,
        userType: data?.consultant?.userType,
      };
    });
  }, [data]);

  return (
    <div className="bg-white px-3 py-4 rounded-2  my-4">
      <div className="row">
        <div className="row">
          <div className="col-md-8 offset-md-1">
            <h5 className="mb-5">Update Consultant Detail</h5>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Name<span className="text-danger"> *</span>
              </Label>
              <Input
                value={formData.name}
                onChange={handleChange}
                name="name"
                invalid={error?.name}
              />
            </FormGroup>
            <p className="text-danger">{error?.name}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Profile Summary</Label>
              <Input
                type="textarea"
                value={formData.ProfileSummary}
                onChange={handleChange}
                name="ProfileSummary"
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Certificate Name</Label>
              <Input
                value={formData.certificateName}
                onChange={handleChange}
                name="certificateName"
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Certificate </Label>
              <Input
                type="file"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    certificate: e.target.files[0],
                  }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Gender</Label>
              <Input
                value={formData.gender}
                onChange={handleChange}
                name="gender"
                type="select"
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Input>
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Thumbnail</Label>
              <Input
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    thumbnail: e.target.files[0],
                  }))
                }
                type="file"
              />
            </FormGroup>
          </div>

          <div className="col-md-9 offset-md-1">
            <div className="d-flex my-4 justify-content-between align-items-center">
              <h6>Education</h6>
              <Button
                color="primary"
                onClick={() =>
                  formData.educationDetail.every(
                    (item) => item.college && item.passingYear && item.degree
                  ) &&
                  setFormData((prev) => ({
                    ...prev,
                    educationDetail: [
                      ...prev.educationDetail,
                      { college: "", degree: "", passingYear: "" },
                    ],
                  }))
                }
              >
                {" "}
                + Add{" "}
              </Button>
            </div>
            {formData.educationDetail.map((item, index) => {
              return (
                <MultiInput
                  fields={item}
                  index={index}
                  formData={formData}
                  setFormData={setFormData}
                  key={index}
                />
              );
            })}
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label> Audio Fee </Label>
              <Input
                name="audioFee"
                value={formData.audioFee}
                onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label> Video Fee </Label>
              <Input
                name="videoFee"
                value={formData.videoFee}
                onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Address</Label>
              <Input
                placeholder="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                type="textarea"
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Pincode<span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="PinCode"
                name="pinCode"
                value={formData.pinCode}
                onChange={(e) =>
                  e.target.value.length < 11 &&
                  !isNaN(e.target.value) &&
                  handleChange(e)
                }
                invalid={error?.pinCode}
              />
            </FormGroup>
            <p className="text-danger">{error?.pinCode}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Mobile<span className="text-danger"> *</span>
              </Label>
              <Input
                placeholder="mobile"
                name="mobile"
                value={formData.mobile}
                invalid={error?.mobile}
                onChange={(e) =>
                  e.target.value.length < 11 &&
                  !isNaN(e.target.value) &&
                  handleChange(e)
                }
              />
            </FormGroup>
            <p className="text-danger">{error?.mobile}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Email<span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                invalid={error.email}
              />
            </FormGroup>
            <p className="text-danger">{error.email}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>City</Label>
              <Input
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>State</Label>
              <Input
                placeholder="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Contact Person</Label>
              <Input
                placeholder="Contact Person"
                name="contactPerson"
                value={formData.contactPerson}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Phone</Label>
              <Input
                placeholder="phone"
                name="phone"
                value={formData.phone}
                onChange={(e) =>
                  e.target.value.length < 11 &&
                  !isNaN(e.target.value) &&
                  handleChange(e)
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Experience</Label>
              <Input
                placeholder="Experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Website</Label>
              <Input
                placeholder="Website"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>
                Designation<span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="Designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                invalid={error?.designation}
              />
            </FormGroup>
            <p className="text-danger">{error?.designation}</p>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Order</Label>
              <Input
                placeholder="Order"
                type="number"
                name="order"
                value={formData.order}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Source</Label>
              <Input
                placeholder="Source"
                type="select"
                name="source"
                value={formData.source}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="">Select</option>
                <option value="IID Reference">IID Reference</option>
                <option value="Google">Google</option>
                <option value="Exhibition">Exhibition</option>
                <option value="Facebook">youbtube</option>
                <option value="walking">walking</option>
                <option value="Event">Event</option>
                <option value="other">other</option>
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
              <FormGroup>
                <Label>Payment Type</Label>
                <Input
                  placeholder="paymentType"
                  type="select"
                  name="paymentType"
                  value={formData.paymentType}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value={0}>Free</option>
                  <option value={1}>Paid</option>
                </Input>
              </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>User Type</Label>
              <Input
                placeholder="userType"
                type="select"
                name="userType"
                value={formData.userType}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value={1}>External</option>
                <option value={0}>Internal</option>
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Remarks</Label>
              <Input
                type="textarea"
                placeholder="Remarks"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-1">
            <FormGroup>
              <Label>Mou</Label>
              <Input
                type="file"
                placeholder="Mou"
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, mou: e.target.files[0] }))
                }
              />
            </FormGroup>
          </div>

          <div className="col-md-4 offset-md-4">
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="ms-3" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetailUpdate;
