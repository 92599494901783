import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FcDocument, FcGallery, FcOpenedFolder } from "react-icons/fc";
import { Input } from "reactstrap";
import { getLockerDetails, getServiceUserDetails } from "../redux/actions/indexActions";
import { FiLayers } from "react-icons/fi";
import axios from "axios";
import { Table } from "reactstrap";
import { FaDownload, FaRegBuilding, FaUser } from "react-icons/fa";

const ServiceDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        AuthReducer: { auth },
        indexReducer: { lockerDocs, loading, userServiceDetails },
        PermissionReducer: { allowedPermissions },
    } = useSelector((state) => state);
    // console.log(userServiceDetails, "Service Of Users")
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userType = queryParams.get('userType');
    useEffect(() => {
        dispatch(getLockerDetails(id, userType));
    }, [id, userType]);

    useEffect(() => {
        dispatch(getServiceUserDetails(id));
    }, [id, userType]);

    const handleServiceStatusChange = useCallback((val, servceId) => {
        if (window.confirm("Are You Sure Want To Update Service  Status ? ")) {
            console.log("service status => ", val)
            axios.put(
                `/api/admin/v1/update-user-service/${servceId}`,
                { service_status: val },
                { headers: { Authorization: `Bearer ${auth.token}`, } }
            ).then((res) => toast.success(res.data?.message),
                setTimeout(() => {
                    dispatch(getServiceUserDetails(id));
                }, 3000)).catch((err) => toast.error(err.response.data?.message));
        }
    }, [, auth])
    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 text-white">Service Details</h5>
                    </div>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4"
                style={{ boxShadow: "4px 4px 18px rgba(0,0,0,0.15)" }}>

                <div className="row">
                    <div className="col-md-3">
                        <span className="text-sm fw-semibold text-secondary">Name</span>
                    </div>
                    <div className="col-md-9"><span>{lockerDocs?.name}</span></div>

                    <div className="col-md-3">
                        <span className="text-sm fw-semibold text-secondary">Mobile</span>
                    </div>
                    <div className="col-md-9"><span>{lockerDocs?.mobile || "N/A"}</span></div>

                    <div className="col-md-3">
                        <span className="text-sm fw-semibold text-secondary">Email</span>
                    </div>
                    <div className="col-md-9">
                        <span>{lockerDocs?.email || "N/A"}</span>
                    </div>

                    <div className="col-md-3">
                        <span className="text-sm fw-semibold text-secondary">Gender</span>
                    </div>
                    <div className="col-md-9">
                        <span>{lockerDocs?.gender || "N/A"}</span>
                    </div>

                    <div className="col-md-3">
                        <span className="text-sm fw-semibold text-secondary">City</span>
                    </div>
                    <div className="col-md-9">
                        <span>{lockerDocs?.city || "N/A"}</span>
                    </div>

                    <div className="col-md-3">
                        <span className="text-sm fw-semibold text-secondary">State</span>
                    </div>
                    <div className="col-md-9">
                        <span>{lockerDocs?.state || "N/A"}</span>
                    </div>
                    <div className="col-md-12">
                        <span className="text-sm fw-bold text-secondary"><br />Services:</span>
                    </div>
                    <div className="col-md-12">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>SL. No.</th>
                                    <th>Service</th>
                                    <th>Purchase Date</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userServiceDetails?.map?.((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item?.serviceName}</td>
                                            <td>{item?.purchase_date}</td>
                                            <td>{item?.amount}</td>
                                            <td>
                                                <div className="c-card-top-block-item">
                                                    <Input type="select" value={item?.service_status}
                                                        onChange={({ target: { value } }) =>
                                                            handleServiceStatusChange(value, item.id)} >
                                                        <option value="">Select Segment</option>
                                                        <option value="enrolled">Enrolled</option>
                                                        <option value="in_progress">In Progress</option>
                                                        <option value="completed">Completed</option>
                                                    </Input>
                                                </div></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>


                </div>
            </div>

        </div>
    );
};

export default ServiceDetail;
