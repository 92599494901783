import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Input, Label, FormGroup, Button } from "reactstrap"
import { useDispatch } from 'react-redux'
import { getCategories } from "../../redux/actions/indexActions";
import { getCourseSectionModuleList, getCourseTypes, getCourseSectionModule } from '../../redux/actions/CourseAction';
import Multiselect from 'multiselect-react-dropdown'
const UpdateCourseSectionModule = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const [formData, setFormData] = useState({
        courseId: "",
        name: "",
        priority: "",
        pw_s1: "",
        pw_s2: "",
        pw_s3: "",
        short_description: "",
        status: 1
    })
    const navigate = useNavigate()
    const { AuthReducer: { auth } } = useSelector(state => state);
    const { CourseReducer: { coursetype, courseSectionModuleList, courseSecctionModule },
    } = useSelector((state) => state);
    const [errors, SetErrors] = useState({});
    useEffect(() => {
        dispatch(getCourseSectionModuleList(auth.token));
    }, [auth.token]);

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 })
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    useEffect(() => {
        if (id) {
            axios(`/api/admin/v1/course-module-section-edit/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth?.token}`
                }
            })
                .then((res) => {
                    setFormData((prev) => {
                        return {
                            ...prev
                            ,
                            name: res.data?.CourseModuleSections?.name,
                            courseId: res.data?.CourseModuleSections?.courseId,
                            priority: res.data?.CourseModuleSections?.priority,
                            pw_s1: res.data?.CourseModuleSections?.pw_s1,
                            pw_s2: res.data?.CourseModuleSections?.pw_s2,
                            pw_s3: res.data?.CourseModuleSections?.pw_s3,
                            short_description: res.data?.CourseModuleSections?.short_description,
                            status: res.data?.CourseModuleSections?.status
                        }
                    })
                })

                .catch((err) => {
                    toast.error(err.response.data);
                });
        }
    }, [id, auth])

    const handleSubmit = () => {
        const form = new FormData()
        for (const key in formData) {
            form.append([key], formData[key])
        }
        axios.put(`/api/admin/v1/course-module-section/${id}`, form, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token}`
            }
        })
            .then((res) => {
                toast.success(res.data?.message)
                navigate(-1)
            }
            ).catch((err) => {
                toast.error(err.response.data);
                SetErrors(err.response.data);
            })
    }

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h4 className="text-white">Edit Course Category</h4>
                </div>
            </div>
            <div className='bg-white px-3 py-4 rounded-2  my-4' >
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Course <span className="text-danger fs-4">*</span></Label>
                            <Input
                                value={formData?.courseId}
                                type="select"
                                name="courseId"
                                invalid={errors?.courseId}
                                onChange={inputHandler}
                            >
                                {courseSectionModuleList?.map?.((item) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item?.title}
                                        </option>
                                    );
                                })}
                                <span className="d-block text-danger">
                                    {" "}
                                    {errors?.courseId}{" "}
                                </span>
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label> Name <span className="text-danger fs-4">*</span></Label>
                            <Input name='name' invalid={errors?.name} value={formData?.name} onChange={inputHandler} />
                            <span className="d-block text-danger">
                                {" "}
                                {errors?.name}{" "}
                            </span>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Priority (must be integer)</Label>
                            <Input name='priority' type='integer' value={formData?.priority} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Pw_s1 (must be integer)</Label>
                            <Input name='pw_s1' type='integer' value={formData?.pw_s1} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Pw_s2 (must be integer)</Label>
                            <Input name='pw_s2' type='integer' value={formData?.pw_s2} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div> <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Pw_s3 (must be integer)</Label>
                            <Input name='pw_s3' type='integer' value={formData?.pw_s3} onChange={(e) => !isNaN(e.target.value) && inputHandler(e)} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Short Description</Label>
                            <Input name='short_description' value={formData?.short_description} type="textarea" onChange={inputHandler} />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                onChange={inputHandler}
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color='primary' onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className='ms-3' onClick={() => navigate(-1)}  >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateCourseSectionModule