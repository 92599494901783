import axios from "axios"

export const getPermissions = (token, limit=10, search="", page, sort="desc") => async (dispatch) => {
    try {
        dispatch({ type: "GET_PERMISSION_PENDING" })
        const response = await axios(`/api/admin/v1/get-permissions/?search=${search}&limit=${limit || 10}&page=${page || 0
        }&sort=${sort}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        dispatch({ type: "GET_PERMISSION_FULFILLED", payload: response.data?.permissions,permissionsCount: response.data?.permissionsCount })

    } catch (error) {
        dispatch({ type: "GET_PERMISSION_REJECTED", payload: error.response.data })

    }
}

export const getGroupedPermissions = (token) => async (dispatch) => {
    try {
        dispatch({ type: "GET_GROUPED_PERMISSION_PENDING" })
        const response = await axios('/api/admin/v1/get-grouped-permissions', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        dispatch({ type: "GET_GROUPED_PERMISSION_FULFILLED", payload: response.data?.groupedPermission })

    } catch (error) {
        dispatch({ type: "GET_GROUPED_PERMISSION_REJECTED", payload: error.response.data })

    }
}

export const getPermissionByRole = (id, token, user = false) => async (dispatch) => {
    try {
        dispatch({ type: "GET_ROLE_BY_ID_PENIDNG" })
        const response = await axios(`/api/admin/v1/get-permission-byrole/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        dispatch({ type: "GET_ROLE_BY_ID_FULFILLED", payload: response.data?.permissions, user })
    } catch (error) {
        dispatch({ type: "GET_ROLE_BY_ID_REJECTED", payload: error.response.data })
        console.log(error);
    }
}

