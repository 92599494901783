const inintialState = {
  dashboardCount: {},
  loading: false,
  error: {},
};

const dashboardReducer = (state = inintialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARDCOUNT_LIST":
      return { ...state, loading: true };

    case "GET_DASHBOARDCOUNT_LIST_FULFILLED":
      return {
        ...state,
        loading: false,
        dashboardCount: action.payload,
      };

    case "GET_DASHBOARDCOUNT_LIST_REJECTED":
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default dashboardReducer;
