import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/AuthActions";
import { AiOutlineUser } from "react-icons/ai";
import { getPermissionByRole } from "../../redux/actions/PermissionAction";
import { FiSearch } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdNotifications } from "react-icons/io";
import userImg from "../../images/profile.webp";
import userimg2 from "../../images/profile.webp";
import Logo from "../../images/consultancy.webp";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

const Layout = () => {
  const {
    AuthReducer: { isAuthenticated, auth },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const [search, setsearch] = useState(false);
  const [isMenue, setIsMenue] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleNotify = () => setNotify((prevState) => !prevState);
  const togglesearch = () => setsearch((prevState) => !prevState);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(getPermissionByRole(auth.roleId, auth.token, true));
  }, [auth]);
  const aside = useRef(null);

  useEffect(() => {
    if (aside.current) {
      setWidth(aside.current?.clientWidth);
    }
  }, [aside]);

  return (
    <Wrapper>
      <div className="header_wapper  pt-2 pb-2">
        <div className="container-fluid pe-3 ps-3">
          <div className="row justify-content-center">
            <div className="col-md-3 d-flex justify-content-end">
              <div className={isMenue ? "active menu" : "menu"}>
                <GiHamburgerMenu
                  onClick={() => setIsMenue(!isMenue)}
                  size="25px"
                  color="white"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className={isMenue ? "active profile" : "profile"}>
                <ul className="list_container align-items-center justify-content-end m-0">
                  <li className="list_item">
                    {/* <div className={isMenue ? 'active menu' : 'menu'}>
                  <GiHamburgerMenu onClick={()=> setIsMenue(!isMenue)} size="25px" color="white" />
                  </div> */}
                  </li>
                  <li className="list_item">
                    <div>
                      <Dropdown
                        isOpen={notify}
                        className="img-avatar"
                        toggle={toggleNotify}
                        direction={"down"}
                      >
                        <DropdownToggle>
                          <IoMdNotifications size="20px" color="#fff" />
                        </DropdownToggle>
                        <DropdownMenu className="p-0 pb-2">
                          <DropdownItem className="p-0">
                            <div className="p-3">
                              <div className="row align-items-center">
                                <div className="col">
                                  <h6 className="m-0"> Notifications </h6>
                                </div>
                                <div className="col-auto">
                                  <a href="#!" className="small">
                                    {" "}
                                    View All
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="p-3 profile_line">
                              <div className="row pb-3">
                                <div className="col-md-3">
                                  <img className="img2" src={userImg} alt="" />
                                </div>
                                <div className="col-md-9">
                                  <div className="media-body">
                                    <h6 className="profile_name mt-0 mb-1">
                                      {auth?.name}
                                    </h6>
                                    {/* <p className="font-size-13 mb-1">You have new follower Instagram</p>
                                    <p className="font-size-12 mb-0 text-muted"><i className="mdi mdi-clock-outline"></i> 2 min ago</p> */}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <img className="img2" src={userimg2} alt="" />
                                </div>
                                <div className="col-md-9">
                                  <div className="media-body">
                                    <h6 className="profile_name mt-0 mb-1">
                                      {auth?.name}
                                    </h6>
                                    {/* <p className="font-size-13 mb-1">You have new follower Instagram</p>
                                    <p className="font-size-12 mb-0 text-muted"><i className="mdi mdi-clock-outline"></i> 2 min ago</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </DropdownItem>
                          {/* <DropdownItem divider /> */}
                          {/* <DropdownItem role="button" className="text-center pt-3">Load More</DropdownItem> */}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </li>
                  <li className="list_item">
                    <div className="profile_img">
                      <Dropdown
                        isOpen={dropdownOpen}
                        className="img-avatar"
                        toggle={toggle}
                        direction={"down"}
                      >
                        <DropdownToggle>
                          <a href="#">
                            <img className="img" src={userImg} alt="" />
                          </a>
                        </DropdownToggle>
                        <DropdownMenu className="p-0 pb-2">
                          <DropdownItem className="p-0">
                            <div className="p-3 profile_line">
                              <div className="row">
                                <div className="col-md-3">
                                  <img className="img2" src={userImg} alt="" />
                                </div>
                                <div className="col-md-9">
                                  <div className="ps-2">
                                    <h4 className="profile_name m-0">
                                      {auth?.name}
                                    </h4>
                                    <p className="profile_title m-0">
                                      {auth?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </DropdownItem>
                          {/* <DropdownItem role="button" className="fs">Index</DropdownItem>
                          {<DropdownItem divider />}
                          <DropdownItem role="button" className="fs">Profile</DropdownItem>
                          {<DropdownItem divider />}
                          <DropdownItem role="button" className="fs" >Setting</DropdownItem>
                          {<DropdownItem divider />}
                          <DropdownItem role="button" className="fs" >Lock Account</DropdownItem> */}
                          {<DropdownItem divider />}
                          <DropdownItem
                            role="button"
                            onClick={() => dispatch(logout())}
                          >
                            Log Out
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={isMenue ? "active dashboard" : "dashboard"}>
            {" "}
            <Outlet />{" "}
          </div>
        </div>
      </div>
      <div className="d-flex dashboard-wrapper bg-primary-100">
        <div
          style={{ zIndex: 100 }}
          className={isMenue ? "active sidebar_menu" : "sidebar_menu"}
          ref={aside}
        >
          <Sidebar />
        </div>
        <main
          className="main flex-grow-1 mt-4 main_bg "
          style={{ marginLeft: `${width}px`, transition: "all 500ms" }}
        >
          {/* <div className="header d-flex align-items-center  bg-white py-2 px-3  w-100 justify-content-end position-relative">
            <div className="dropdown-holder">
              <div>
                <div className="d-flex align-items-center">
                  <AiOutlineUser />
                  <button className="btn ">{auth?.name}</button>
                </div>
                <div className="dropdown-list  bg-white py-3 mt-3">
                  <span role="button" onClick={() => dispatch(logout())} className="text-dark ps-4  text-decoration-none"
                  >
                    Log Out
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </main>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .header_wapper {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 56px;
    color: #fff;
    border-bottom: 1px solid #fff;
    background-color: #11cdef;
    height: 400px;
  }
  .sidebar_menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    transition: all 0.3s;
  }
  .sidebar_menu.active {
    overflow-x: hidden;
    width: 125px;
    transition: all 0.3s;
  }
  .dashboard.active {
    padding-left: 160px;
    background-color: #11cdef;
    height: 200px;
  }
  .dashboard {
    padding-left: 240px;
  }
  p.profile_title {
    font-size: 16px;
  }
  .box_shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .img2 {
    width: 90px;
  }
  .fs {
    font-size: 18px;
  }
  .img-avatar > button {
    border: none;
    background-color: transparent;
    border-radius: 50%;
    overflow: hidden;
    padding: 0 !important;
  }
  .img-avatar > button::focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .logodiv {
    display: flex;
    padding-left: 15px;
    padding-bottom: 10px;
    padding-top: 5px;
  }
  .profile_line {
    border-bottom: 1px solid #f2f2f2;
  }
  .logodiv a h5 {
    color: #fff;
  }
  .list_container {
    list-style: none;
    display: flex;
    align-items: center;
  }
  li.list_item {
    padding: 0px 20px;
  }
  .dashboard-wrapper {
    min-height: 100vh;
  }
  .main {
    padding-inline: 2.5rem;
    padding-top: 2rem;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #fff;
    border-radius: 5px;
    color: #000;
  }
  .img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .header {
    border-radius: 6px;
  }
  .profile_name {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .unlink {
    text-decoration: none;
  }
  .dropdown-list {
    position: absolute;
    transition: all 500ms;
    top: 100px;
    right: 0;
    width: 200px;
    border-radius: 6px;
    opacity: 0;
    z-index: -1;
  }
  .dropdown-holder {
    overflow: hidden;
  }
  .main_bg {
    background: #f8f8f8 !important;
  }
  .dropdown-holder:hover {
    overflow: visible;
  }
  .dropdown-menu .show {
    position: absolute;
    right: auto;
    bottom: auto;
    left: 0px;
    transform: translate3d(-112px, 40px, 0px);
    top: 10px !important;
  }
  .dropdown-menu.show {
    width: 350px !important;
    border: 0;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important;
    padding-bottom: 0px !important;
    margin-top: 0;
  }

  .dropdown-holder:hover .dropdown-list {
    top: 40px;
    opacity: 1;
    transition: all 500ms;
    z-index: 100;
  }
  .scroll::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: #fff !important;
  }

  /* Handle on hover */
  .scroll::-webkit-scrollbar-thumb:hover {
    background: #1572e8;
  }

  /* .dashboard{
  padding-left: 250px;
} */

  .menu {
    position: relative;
    margin-right: 75px;
    cursor: pointer;
  }

  .menu.active {
    position: relative;
    margin-right: 160px;
    cursor: pointer;
    background-color: #11cdef;
  }

  .sidebar_menu.active .sidetext {
    display: block;
    margin-left: -4px !important;
  }

  .sidebar_menu.active .nav-item {
    width: fit-content;
  }

  .profile.active {
    background-color: #11cdef;
  }
  .sidebar_menu.active .sideicon {
    margin-left: -15px;
    width: 110px;
    height: 30px;
    transition: all 0.3s;
  }

  .sidebar_menu.active .dropicon {
    display: block;
    width: 90px;
    height: 30px;
    transition: all 0.3s;
    margin-left: -5px;
  }

  .sidebar_menu .dropicon {
    display: none;
  }

  .sidebar_menu.active .dropicon:hover .sidetext {
    display: block;
    transition: all 0.3s;
  }

  .sidebar_menu.active .droptext {
    margin-left: 0px !important;
  }

  .sidebar_menu.active .img2 {
    text-align: center;
    width: 70px;
    margin-left: 7px;
    transition: all 0.3s;
  }
  @media screen and (max-width: 1255px) {
    .menu {
      position: relative;
      margin-right: 30px;
      cursor: pointer;
    }
    .menu.active {
      position: relative;
      margin-right: 100px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1155px) {
    .menu {
      position: relative;
      margin-right: -42px;
      cursor: pointer;
    }
    .menu.active {
      position: relative;
      margin-right: 70px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 775px) {
    .menu {
      position: relative;
      margin-right: -90px;
      cursor: pointer;
    }
    .menu.active {
      position: relative;
      margin-right: -7px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1050px) {
    .sidebar_menu.active .sideicon {
      margin-left: -15px;
      width: 110px;
      height: 20px;
      transition: all 0.3s;
    }
  }

  @media screen and (max-width: 655px) {
    .menu {
      position: relative;
      margin-right: 360px;
      cursor: pointer;
    }
    .menu.active {
      position: relative;
      margin-right: 440px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 575px) {
    .menu {
      position: relative;
      margin-right: 270px;
      cursor: pointer;
    }
    .menu.active {
      position: relative;
      margin-right: 370px;
      cursor: pointer;
      top: 14px;
    }
  }
  @media screen and (max-width: 565px) {
    .dashboard.active {
      padding-left: 95px;
      background-color: #11cdef;
      height: 200px;
      position: relative;
      right: -40px;
      top: 9px;
    }
    .dashboard {
      padding-left: 240px;
    }
  }
  @media screen and (max-width: 565px) {
    .profile.active {
      position: relative;
      top: -30px;
      background-color: #11cdef;
    }
  }
`;

export default Layout;
