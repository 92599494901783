import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GoCopy } from "react-icons/go";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import axios from "axios";
import { useState } from "react";
import { Input } from "reactstrap";
import Pagination from "../../Components/Paginate";
import moment from "moment/moment";
const MeetingLis = () => {
  const [meetings, setMeeting] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("DESC");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [paginate, setPaginate] = useState(10);
  const [count, setCount] = useState(0);
  const {
    AuthReducer: { auth },
    indexReducer: { expertVideos, expertCount, categories },
    PermissionReducer: { allowedPermissions },
  } = useSelector((state) => state);
  useEffect(() => {
    axios(
      `/api/admin/v1/get-meetings-new?search=${search}&sort=${sort}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then(({ data }) => {
        setLoading(false);
        setMeeting(data.meetings);
        setCount(data?.count?.count);
      })
      .catch((err) => console.error(err));
  }, [search, sort, page, limit, paginate]);

  const handleDel = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Meeting Link")) {
      axios
        .delete(`/api/admin/v1/meeting-delete/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          window.location.reload("/meeting-list");
        })
        .catch((err) => toast.error("Somthing Went Wrong"));
    }
  };

  const copyLink = (link) => {
    if (
      typeof window.navigator !== "undefined" &&
      typeof window.navigator.clipboard !== "undefined" &&
      window.navigator.permissions !== "undefined"
    ) {
      window.navigator.clipboard
        ?.writeText?.(link)
        .then(() => toast.success(`Copied`))
        .catch((err) => console.log(err));
    }
  };

  const handleCompleted = (e, item) => {
   console.log(item)
    if (
      window.confirm(
        `Are You Sure You Want To Credit ${item.consultant.name} consultant account`
      )
    ) {
      const data = {
        consultantId: item.consultantId,
        userId: item.userId,
        amount: item.fee,
        bookId: item.id,
      };
      if (e.target.checked) {
        axios
          .post("/api/admin/v1/consultant-amount-credit", data, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((res) => {
            toast.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((err) => console.error(err));
      }
    }
  };

  return (
    <div>
      <div className="row my-3">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 text-white">Meeting Links</h5>
            {allowedPermissions?.some?.(
              (item) =>
                item?.permission?.moduleType === "MEETINGLIST" &&
                item?.permission?.permission === "create"
            ) && (
              <Link to="/create-meeting">
                <Button className="bg-white text-black" color="primary">
                  Create meeting
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white px-3 py-3 rounded-2  my-4">
        <div className="row justify-content-between">
          <div className="col-md-3 mb-2">
            <Input
              size={"sm"}
              value={search}
              placeholder="Search By Name"
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>

          <div className="col-md-2 ">
            <Input
              type="select"
              size={"sm"}
              value={sort}
              onChange={({ target }) => setSort(target.value)}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Input>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center mx-auto">
            <Spinner color="primary" />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>SL. No.</th>
                    <th>Created By</th>
                    <th>uid</th>
                    <th>Subject</th>
                    <th>Consultant</th>
                    <th>User</th>
                    <th>link</th>
                    <th>Created Date</th>
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType === "MEETINGLIST" &&
                        item?.permission?.permission === "complete-action"
                    ) && <th>Completed</th>}
                    {allowedPermissions?.some?.(
                      (item) =>
                        item?.permission?.moduleType === "MEETINGLIST" &&
                        item?.permission?.permission === "delete"
                    ) && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {(meetings ? meetings : []).map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">
                          {page == 1 ? index + 1 : (page - 1) * 10 + index + 1}
                        </th>
                        <td>{item?.admin?.name}</td>

                        <td>{item?.uid}</td>
                        <td>{item?.subject}</td>
                        <td>
                          {item.consultant?.name ?? item?.consultantName}{" "}
                        </td>
                        <td>{item.user?.name}</td>
                        <td>
                          {item?.link}
                          {item.link ? (
                            <Button
                              size="sm"
                              onClick={() => copyLink(item?.link)}
                              className="ms-2"
                            >
                              <GoCopy />
                            </Button>
                          ) : null}
                        </td>
                        <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                        {allowedPermissions?.some?.(
                          (item) =>
                            item?.permission?.moduleType === "MEETINGLIST" &&
                            item?.permission?.permission === "complete-action"
                        ) && (
                          <td>
                            <input
                              type="checkbox"
                              onChange={(e) => handleCompleted(e, item)}
                              checked={item.accountCredited}
                            />
                          </td>
                        )}
                        <td>
                          <div className="d-flex align-items-center">
                            {allowedPermissions?.some?.(
                              (item) =>
                                item?.permission?.moduleType ===
                                  "MEETINGLIST" &&
                                item?.permission?.permission === "delete"
                            ) && (
                              <AiOutlineDelete
                                size={22}
                                className="text-danger"
                                onClick={() => handleDel(item.id)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        {/* <Pagination
          pageCount={count / paginate}
          onChange={(e) => setPage(e.selected + 1)}
        /> */}
        <div className="">
          <Pagination
            pageCount={count / paginate}
            onChange={(e) => setPage(e.selected + 1)}
          />
        </div>
        <div className="mx-3 py-2 text-black">Total Results : {count}</div>
      </div>
    </div>
  );
};

export default MeetingLis;
