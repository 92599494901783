import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label, FormGroup, Button } from "reactstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

const Edit = () => {
    const { id } = useParams();
    const [feature, setFeature] = useState([]);
    const [term_condition, setTerm_condition] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        memberShipType: "",
        base_price: "",
        sgst: 0,
        cgst: 0,
        igst: 0,
        discount: "",
        discount_type: "",
        wallet_transfer_amounts: "",
        amount: "",
        days: "",
        description: "",
        term_condition: "",
        feature: "",
        status: 1,
    });
    const navigate = useNavigate();
    const {
        AuthReducer: { auth },
    } = useSelector((state) => state);

    const [errors, setErrors] = useState({});
    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "status") {
            setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    useEffect(() => {
        if (id) {
            axios(`/api/admin/v1/membership-update/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
                .then((res) => {
                    setTerm_condition(
                        res.data?.memberShip?.term_condition ? JSON.parse(res.data?.memberShip.term_condition) : []
                    );
                    setFeature(
                        res.data?.memberShip?.feature ? JSON.parse(res.data?.memberShip.feature) : []
                    );
                    setFormData((prev) => {
                        return {
                            ...prev,
                            name: res.data?.memberShip?.name,
                            memberShipType: res.data?.memberShip?.memberShipType,
                            base_price: res.data?.memberShip?.base_price,
                            sgst: res.data?.memberShip?.sgst,
                            cgst: res.data?.memberShip?.cgst,
                            igst: res.data?.memberShip?.igst,
                            discount: res.data?.memberShip?.discount,
                            discount_type: res.data?.memberShip?.discount_type,
                            wallet_transfer_amounts: res.data?.memberShip?.wallet_transfer_amounts,
                            amount: res.data?.memberShip?.amount,
                            days: res.data?.memberShip?.days,
                            description: res.data?.memberShip?.description,
                            status: res.data?.memberShip?.status,
                        };
                    });
                })

                .catch((err) => console.log(err));
        }
    }, [id, auth]);

    const handleTermCondition = e => {
        e.preventDefault();
        setTerm_condition(prev => [...prev, formData.term_condition]);
        setFormData(
            {
                ...formData, term_condition: ''
            }
        )
        document.getElementById("term_condition").value = "";
    };

    const handleRemoveTermCondition = (e, i) => {
        e.preventDefault();
        setTerm_condition(term_condition.filter((arr, row) => row !== i));
    };

    const handleFeature = e => {
        e.preventDefault();
        setFeature(prev => [...prev, formData.feature]);
        setFormData(
            {
                ...formData, feature: ''
            }
        )
        document.getElementById("feature").value = "";
    };

    const handleRemoveFeature = (e, i) => {
        e.preventDefault();
        setFeature(feature.filter((arr, row) => row !== i));
    };

    const handleSubmit = () => {
        const form = new FormData();
        form.append("name", formData.name);
        form.append("memberShipType", formData.memberShipType);
        form.append("base_price", formData.base_price);
        form.append("sgst", formData.sgst);
        form.append("cgst", formData.cgst);
        form.append("igst", formData.igst);
        form.append("discount", formData.discount);
        form.append("discount_type", formData.discount_type);
        form.append("wallet_transfer_amounts", formData.wallet_transfer_amounts);
        form.append("amount", formData.amount);
        form.append("days", formData.days);
        form.append("description", formData.description);
        form.append("term_condition", JSON.stringify(term_condition));
        form.append("feature", JSON.stringify(feature));
        form.append("status", formData.status);
        axios
            .put(`/api/admin/v1/membership/${id}`, form, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((res) => {
                toast.success(res.data?.message);
                navigate(-1);
            })
            .catch((err) => {
                toast.error(err.response.data);
                setErrors(err.response.data);
            });
    };

    return (
        <div>
            <div className="row my-3">
                <div className="col-md-12">
                    <h4 className="text-white">Edit MemberShip</h4>
                </div>
            </div>
            <div className="bg-white px-3 py-4 rounded-2  my-4">
                <div className="row">
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>
                                Name<span className="text-danger"> *</span>
                            </Label>
                            <Input
                                value={formData?.name}
                                name="name"
                                invalid={errors?.name}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.name}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>
                                MemberShip Type<span className="text-danger"> *</span>
                            </Label>
                            <Input
                                type="select"
                                value={formData?.memberShipType}
                                name="memberShipType"
                                invalid={errors?.memberShipType}
                                onChange={inputHandler}
                            >
                                <option>Select</option>
                                <option value={"users"}>USERS</option>
                                <option value={"suppliers"}>SUPPLIERS</option>
                                <option value={"experts"}>PROFESSIONALS/EXPERTS</option>
                            </Input>
                            <p className="text-danger">{errors?.memberShipType}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Base Price<span className="text-danger">*</span></Label>
                            <Input
                                value={formData?.base_price}
                                name="base_price"
                                invalid={errors?.base_price}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.base_price}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>SGST</Label>
                            <Input
                                value={formData?.sgst}
                                name="sgst"
                                invalid={errors?.sgst}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.sgst}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>CGST</Label>
                            <Input
                                value={formData?.cgst}
                                name="cgst"
                                invalid={errors?.cgst}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.cgst}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>IGST</Label>
                            <Input
                                value={formData?.igst}
                                name="igst"
                                invalid={errors?.igst}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.igst}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Discount Type<span className="text-danger">*</span></Label>
                            <Input
                                type="select"
                                value={formData?.discount_type}
                                name="discount_type"
                                invalid={errors?.discount_type}
                                onChange={inputHandler}
                            >
                                <option>Select Discount Type</option>
                                <option value={"amount"}>Amount</option>
                                <option value={"percentage"}>Percentage</option>
                            </Input>
                            <p className="text-danger">{errors?.discount_type}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Wallet Transfer Amount</Label>
                            <Input
                                value={formData?.wallet_transfer_amounts}
                                name="wallet_transfer_amounts"
                                invalid={errors?.wallet_transfer_amounts}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.wallet_transfer_amounts}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Discount</Label>
                            <Input
                                value={formData?.discount}
                                name="discount"
                                invalid={errors?.discount}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.discount}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Total Amount<span className="text-danger">*</span></Label>
                            <Input
                                value={formData?.amount}
                                name="amount"
                                invalid={errors?.amount}
                                onChange={inputHandler}
                            />
                            <p className="text-danger">{errors?.amount}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>
                                Days <span className="text-danger">*</span>
                            </Label>
                            <Input
                                name="days"
                                value={formData?.days}
                                type="text"
                                onChange={inputHandler}
                                invalid={errors?.days}
                            />
                            <p className="text-danger">{errors?.days}</p>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>
                                Term & Conditions
                            </Label>
                            <div className="d-flex">
                                <Input
                                    id="term_condition"
                                    type="text"
                                    name="term_condition"
                                    className="me-3"
                                    invalid={errors?.term_condition}
                                    value={formData.term_condition}
                                    onChange={e =>
                                        setFormData(
                                            {
                                                ...formData, term_condition: e.target.value
                                            }
                                        )
                                    }
                                />
                                <a
                                    size="sm"
                                    className="btn btn-secondary cursor-pointer text-white text-center"
                                    onClick={
                                        handleTermCondition
                                    }
                                >
                                    <MdAdd />
                                </a>
                            </div>

                        </FormGroup>
                        {term_condition.length ? (
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Sno.
                                                </th>
                                                <th>
                                                    Term Condition
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {term_condition.map(
                                                (
                                                    trm,
                                                    i
                                                ) => (
                                                    <tr
                                                        key={Math.random()}
                                                    >
                                                        <td>
                                                            {i +
                                                                1}
                                                        </td>
                                                        <td>
                                                            {
                                                                trm
                                                            }
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cursor-pointer"
                                                                onClick={e =>
                                                                    handleRemoveTermCondition(
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                <AiOutlineDelete />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>
                                Feature
                            </Label>
                            <div className="d-flex">
                                <Input
                                    id="feature"
                                    type="text"
                                    name="feature"
                                    className="me-3"
                                    invalid={errors?.feature}
                                    value={formData.feature}
                                    onChange={e =>
                                        setFormData(
                                            {
                                                ...formData, feature: e.target.value
                                            }
                                        )
                                    }
                                />
                                <a
                                    size="sm"
                                    className="btn btn-secondary cursor-pointer text-white text-center"
                                    onClick={
                                        handleFeature
                                    }
                                >
                                    <MdAdd />
                                </a>
                            </div>
                        </FormGroup>
                        {feature.length ? (
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Sno.
                                                </th>
                                                <th>
                                                    Course Tag
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feature.map(
                                                (
                                                    trm,
                                                    i
                                                ) => (
                                                    <tr
                                                        key={Math.random()}
                                                    >
                                                        <td>
                                                            {i +
                                                                1}
                                                        </td>
                                                        <td>
                                                            {
                                                                trm
                                                            }
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cursor-pointer"
                                                                onClick={e =>
                                                                    handleRemoveFeature(
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                <AiOutlineDelete />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                value={formData?.description}
                                name="description"
                                type="textarea"
                                onChange={inputHandler}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <FormGroup switch>
                            <Input
                                type="switch"
                                checked={formData?.status}
                                onChange={inputHandler}
                                name="status"
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <Button color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button className="ms-3" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
