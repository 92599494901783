import React from "react";
import "../Meeting.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { io, Socket } from "socket.io-client";
import { useSocketContext } from "../../../Context/SocketContext";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { AiOutlineSend } from "react-icons/ai";
import { useParams, useSearchParams } from "react-router-dom";

function Chat() {
  const [param] = useSearchParams();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState("");
  const { socket } = useSocketContext();
  const [senderMessage, setSenderMessage] = useState([]);
  const { currentUser } = useSelector((state) => state.meetingReducer);
  console.log(currentUser);

  const currentUserData = currentUser ? Object.values(currentUser)[0] : null;
  const sender_id = currentUser ? Object.keys(currentUser)[0] : null;

  console.log(currentUserData, sender_id, "sendid");

  useEffect(() => {
    socket.emit("subscribe", { room: param.get("id") });
    socket.on("adminchat", (data) => {
      if (param.get("id") === data.room) {
        setMessages((messages) => [
          ...messages,
          { ...data, date: new Date(), type: "reciever" },
        ]);
      }
    });

    return () => {
      socket.off("adminchat");
    };
  }, []);

  console.log(messages, "message");

  const sendMessage = () => {
    let data = {
      room: param.get("id"),
      msg: message,
      sender: currentUserData.name,
      senderId: sender_id,
    };
    socket.emit("adminchat", data);
    setSenderMessage((prev) => [
      ...prev,
      {
        msg: message,
        date: new Date(),
        senderId: sender_id,
        type: "sender",
      },
    ]);
    setMessage("");
  };

  return (
    <div className="chat">
      <div>
        <div className="chat-container">
          {[...messages, ...senderMessage]
            .sort((a, b) => a.date.getTime() - b.date.getTime())
            ?.map((item, index) => {
              return (
                <div key={index}>
                  <span
                    className={`d-flex align-items-center ${
                      item.type === "sender"
                        ? " justify-content-start"
                        : " justify-content-end"
                    }`}
                  >
                    <p className="chat-user d-flex">
                      {" "}
                      {item.sender ? item.sender.charAt(0) : "M"}
                    </p>
                    <p
                      className={`ms-2 ${
                        item.type === "sender" ? "msg-send " : "msg-recieve "
                      }`}
                    >
                      {item?.msg}
                    </p>
                  </span>
                </div>
              );
            })}
        </div>

        <div className="sendmessage">
          <input
            type="text"
            placeholder="Type your message here ......"
            onKeyUp={(e) => e.key.toLowerCase() === "enter" && sendMessage()}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <AiOutlineSend size={18} className=" rotate-90 " />
        </div>
      </div>
    </div>
  );
}

export default Chat;
